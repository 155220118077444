import { createBrowserHistory } from 'history'
import html2canvas from 'html2canvas'
import $ from 'jquery'
import React, { Component } from 'react'
import Sidebar from './sidebar'
import { withRouter } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend-old'
import { TouchBackend } from 'react-dnd-touch-backend'
import Modal from 'react-modal'
import { DragDropContext } from 'react-dnd-old'
import WorkSpace from './workspace'
import { connect } from 'react-redux'
import '../../css/assembly.css'
import '../../css/pure-grids.min.css'
import Sizes from '../../helpers/Sizes'
import { webSerialConnect } from '../../redux/actions/index'
import renderPrgImage from '../../source/programImg'
import unicodeToChar from '../../utils/unicodeToChar'
import AssemblyPrgm from '../ReusableComponents/PrgmSlider/AssemblyPrgm/AssemblyPrgm'
import { activeCheckBox } from './CheckboxData'
import CustomDragLayer from './CustomDragLayer'
import * as atatus from 'atatus-spa'
import ProgramHeader from '../ReusableComponents/Header/ProgramHeader'
import {
    continousPABytesReadToggle_v1,
    sendBytes,
    readBytesPa,
    continousPABytesReadToggle,
    humanoidPABytesToSensorData,
    HUMANOID_PA_SEND_BYTES,
} from '../ReusableComponents/ByteTransfer'
import {
    playComputerPABytesToSensorData,
    robokiPABytesToSensorData,
    hexapodPABytesToSensorData,
    klawPABytesToSensorData,
} from '../ReusableComponents/ByteTransfer/utils'

import renderImage from '../../source/importImg'
import removeSensorProgramData from '../../utils/removeSensorProgramData'
import removeHumanoidLocalProgramData from '../../utils/removeHumanoidLocalProgramData'
import {
    HEXAPOD_PA_SEND_BYTES,
    HUMANOID_V1_PA_SEND_BYTES,
    KLAW_PA_SEND_BYTES,
} from '../ReusableComponents/ByteTransfer/byteCodes'
import { PORTDATA, generateDeviceDataKey } from '../Humanoid/defaultData'

var zooming
var oldDeltaX, oldDeltaY, panning
const history = createBrowserHistory()

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        height: ' 40%',
        width: ' 50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        zIndex: 1000000,
        transform: 'translate(-50%, -50%)',
    },
}
var nextVisbilityButton
var reader
let goBack = false
const connectedDevice = sessionStorage.getItem('connectedDevice')
class Assembly extends Component {
    constructor(props) {
        super(props)
        // var props1 = props;
        var selectionType = localStorage.getItem('programMode')
        if (selectionType === 'learn') {
            nextVisbilityButton = 'hidden'
        } else {
            nextVisbilityButton = 'visible'
        }
        Modal.setAppElement('body')
        // this.continuesPAstop = this.continuesPAstop.bind(this)
        this.state = {
            slideDirection: sessionStorage.getItem('slideDirection'),
            isReading: false,
            huBattery: 0,
            huUltrasonic: 0,
            huAccelerometerX: 0,
            huAccelerometerY: 0,
            huAccelerometerZ: 0,
            huGyroX: 0,
            huGyroY: 0,
            huGyroZ: 0,

            huSred1: 0,
            huSred2: 0,
            huSred3: 0,
            huSred4: 0,
            huSred5: 0,
            huSred6: 0,
            huSred7: 0,
            huSred8: 0,
            huSred9: 0,
            huSred10: 0,
            huSred11: 0,
            huSred12: 0,
            huSred13: 0,
            huSred14: 0,
            huSred15: 0,
            huSred16: 0,
            huSred17: 0,
            huSred18: 0,

            height: 0,
            width: 0,
            readbytes: false,
            modalIsOpen: false,
            props1: props,
            detected: false,
            usbOpen: false,
            isusb: false,
            isHelp: false,
            visible: nextVisbilityButton,
            isClickFourInOneSensor: false,
            isClickTempratureSensor: false,
            isPcActive: false,
            p1: {
                selected: false,
                port: {},
            },
            refresh: false,
            reConnecting: false,
            flag: false,
            flagUpdated: false,
            k: false,
            keepReading: true,
            responceTp0: '',
            responceTp1: '',
            responceTp2: '',
            touch_pad: '',
            touch_pad2: '',
            rangeA1: '',
            rangeA2: '',
            rangeB1: '',
            rangeB2: '',
            rangeC1: '',
            rangeC2: '',
            rangeD1: '',
            rangeD2: '',
            rangeE1: '',
            rangeE2: '',
            rangeF1: '',
            rangeF2: '',
            tactswitch: '',
            mic: '',
            temprature: '',
            accelerometer_X: '',
            accelerometer_Y: '',
            accelerometer_Z: '',
            gyro_X: '',
            gyro_Y: '',
            gyro_Z: '',
            battery: '',
            temp: '',
            gas: '',
            zero: '',
            one: '',
            two: '',
            red: '',
            green: '',
            blue: '',
            light: '',
            gesture: '',
            distance: '',
            switch1: '',
            switch2: '',
            shield: false,
            armShield: false,
            peeCeeATR: false,
            setIntervalId: '',
            humanoidPortData: JSON.parse(
                sessionStorage.getItem('humanoidPortData')
            ),

            //klaw datas
            // joystick1_X: '',
            // joystick1_Y: '',
            // joystick2_X: '',
            // joystick2_Y: '',
            // joystick3_X: '',
            // joystick3_Y: '',
            // switch1: '',
            // switch2: '',
            // switch3: '',
            pot1: '',
            pot2: '',
            pot3: '',
            pot4: '',
            pot5: '',
            pot6: '',
            pwm1: '',
            pwm2: '',
            pwm3: '',
            pwm4: '',
            pwm5: '',
            pwm6: '',
        }

        this.messageHandler = this.messageHandler.bind(this)

        window.addEventListener('load', async (e) => {
            const height = document.body.clientHeight

            const width = document.body.clientWidth
            Sizes._update(width, height)
            this.setState({
                height: height,
                width: width,
            })
        })
        Sizes._update(document.body.clientWidth, document.body.clientHeight)
    }
    // componentDidCatch(error, info) {
    //     console.error(error, info)
    // }
    // async writePort(data) {
    //     sendBytes(data, this.props.webSerial.port)
    // }

    //set PA bytes for Klaw
    setPABytes_Klaw() {
        let humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        let sendBytes = Array(15).fill('O')
        sendBytes[0] = 'P'
        sendBytes[1] = 'A'
        sendBytes[13] = 'E'
        sendBytes[14] = 'R'
        let internalAccessories = this.props.concept.internalaccessories
        let portConnections = this.props.assembly.PortConnections
        Object.keys(portConnections).map((port) => {
            if (
                port !== 'A' &&
                port !== 'B' &&
                port !== 'C' &&
                // port !== 'D' &&
                portConnections[port] !== null
            ) {
                const getIndex = (port) => {
                    switch (port) {
                        case 'A1':
                            return 2
                        case 'A2':
                            return 3
                        case 'B1':
                            return 4
                        case 'B2':
                            return 5
                        // case 'C1':
                        //     return 6
                        // case 'C2':
                        //     return 7
                        // case 'D1':
                        //     return 8
                        // case 'D2':
                        //     return 9
                        // case 'E1':
                        //     return 10
                        // case 'E2':
                        //     return 11
                        // case 'F1':
                        //     return 12
                        // case 'F2':
                        //     return 13
                    }
                }
                let arrIndex = getIndex(port)
                let Type = portConnections[port].type
                if (Type == 'ultrasonic_sensor') {
                    sendBytes[arrIndex] = 'U'
                } else if (
                    Type == 'tact_switch' ||
                    Type == 'dual_switch' ||
                    Type == 'metal_detector' ||
                    Type == 'touch_sensor' ||
                    Type == 'dip_switch' ||
                    Type == 'tact_switch_2c'
                ) {
                    sendBytes[arrIndex] = 'I'
                    sendBytes[arrIndex] = 'I'
                } else if (
                    Type == 'distance_sensor' ||
                    Type == 'temperature_sensor' ||
                    Type == 'gas' ||
                    Type == 'light_sensor' ||
                    Type == 'linear_pot' ||
                    Type == 'pot' ||
                    Type == 'rain_sensor' ||
                    Type == 'humidity'
                ) {
                    sendBytes[arrIndex] = 'A'
                } else if (
                    Type == 'rotatory' ||
                    Type == 'joystick' ||
                    Type == 'extender'
                ) {
                    sendBytes[arrIndex] = 'A'
                    sendBytes[arrIndex] = 'A'
                }
            }
        })

        //joystick1
        sendBytes[6] = humanoidPortData['0'].isPortSelected ? 'A' : 'O'
        sendBytes[7] = humanoidPortData['1'].isPortSelected ? 'A' : 'O'
        //joystick1
        sendBytes[8] = humanoidPortData['2'].isPortSelected ? 'A' : 'O'
        sendBytes[9] = humanoidPortData['3'].isPortSelected ? 'A' : 'O'
        //joystick1
        sendBytes[10] = humanoidPortData['4'].isPortSelected ? 'A' : 'O'
        sendBytes[11] = humanoidPortData['5'].isPortSelected ? 'A' : 'O'
        sendBytes[12] = 'O'
        //switch
        // sendBytes[12] = humanoidPortData['3'].isPortSelected ? 'I' : 'O'
        // sendBytes[13] = humanoidPortData['4'].isPortSelected ? 'I' : 'O'
        // sendBytes[14] = humanoidPortData['5'].isPortSelected ? 'I' : 'O'

        sendBytes = sendBytes.toString().replaceAll(',', '')
        return sendBytes
    }

    //set PA bytes for roboki
    setPABytes_Roboki() {
        let sendBytes = Array(10).fill('O')

        sendBytes[0] = 'P'
        sendBytes[1] = 'A'
        sendBytes[8] = 'E'
        sendBytes[9] = 'R'
        let internalAccessories = this.props.concept.internalaccessories
        let portConnections = this.props.assembly.PortConnections
        Object.keys(portConnections).map((port) => {
            if (
                port !== 'A' &&
                port !== 'B' &&
                portConnections[port] !== null
            ) {
                const getIndex = (port) => {
                    switch (port) {
                        case 'A1':
                            return 2
                        case 'A2':
                            return 3
                        case 'B1':
                            return 4
                        case 'B2':
                            return 5
                    }
                }

                let arrIndex = getIndex(port)
                let Type = portConnections[port].type
                if (Type == 'ultrasonic_sensor') {
                    sendBytes[arrIndex] = 'U'
                } else if (
                    Type == 'tact_switch' ||
                    Type == 'dual_switch' ||
                    Type == 'metal_detector' ||
                    Type == 'touch_sensor' ||
                    Type == 'dip_switch' ||
                    Type == 'tact_switch_2c'
                ) {
                    sendBytes[arrIndex] = 'I'
                    sendBytes[arrIndex] = 'I'
                } else if (
                    Type == 'distance_sensor' ||
                    Type == 'temperature_sensor' ||
                    Type == 'gas' ||
                    Type == 'light_sensor' ||
                    Type == 'linear_pot' ||
                    Type == 'pot' ||
                    Type == 'rain_sensor' ||
                    Type == 'humidity'
                ) {
                    sendBytes[arrIndex] = 'A'
                } else if (
                    Type == 'rotatory' ||
                    Type == 'joystick' ||
                    Type == 'extender'
                ) {
                    sendBytes[arrIndex] = 'A'
                    sendBytes[arrIndex] = 'A'
                }
            }
        })
        if (internalAccessories.isSwitch1) {
            sendBytes[6] = 'I'
        }
        if (internalAccessories.isSwitch2) {
            sendBytes[7] = 'I'
        }
        sendBytes = sendBytes.toString().replaceAll(',', '')
        console.log('JJJJJJJJJJJ, sendBytes', sendBytes)
        return sendBytes
    }

    setPABytes_V1() {
        let sendBytes = []
        if (connectedDevice == 'Toki') {
            sendBytes = Array(10).fill('0')

            sendBytes[0] = 'P'
            sendBytes[1] = 'A'
            sendBytes[8] = 'E'
            sendBytes[9] = 'R'
        } else {
            sendBytes = Array(25).fill('0')

            sendBytes[0] = 'P'
            sendBytes[1] = 'A'
            sendBytes[23] = 'E'
            sendBytes[24] = 'R'
        }
        let internalAccessories = this.props.concept.internalaccessories
        let portConnections = this.props.assembly.PortConnections
        Object.keys(portConnections).map((port) => {
            if (
                port !== 'A' &&
                port !== 'B' &&
                port !== 'C' &&
                port !== 'D' &&
                portConnections[port] !== null
            ) {
                const getIndex = (port) => {
                    switch (port) {
                        case 'A1':
                            return 2
                        case 'A2':
                            return 3
                        case 'B1':
                            return 4
                        case 'B2':
                            return 5
                        case 'C1':
                            return 6
                        case 'C2':
                            return 7
                        case 'D1':
                            return 8
                        case 'D2':
                            return 9
                        case 'E1':
                            return 10
                        case 'E2':
                            return 11
                        case 'F1':
                            return 12
                        case 'F2':
                            return 13
                    }
                }

                const tokiIndex = (port) => {
                    switch (port) {
                        case 'servo_motor1':
                            return 5
                        case 'servo_motor2':
                            return 6
                    }
                }

                let arrIndex = getIndex(port)
                if (
                    connectedDevice == 'Toki' &&
                    ['servo_motor1', 'servo_motor2'].includes(port)
                ) {
                    arrIndex = tokiIndex(port)
                }

                let Type = portConnections[port].type
                if (Type == 'ultrasonic_sensor') {
                    sendBytes[arrIndex] = 'U'
                } else if (
                    [
                        'tact_switch',
                        'dual_switch',
                        'metal_detector',
                        'touch_sensor',
                        'dip_switch',
                        'tact_switch_2c',
                        'rain_sensor_module_digital',
                        'capacitative_touch_sensor',
                        'PIR_sensor',
                    ].includes(Type)
                ) {
                    sendBytes[arrIndex] = 'I'
                    sendBytes[arrIndex] = 'I'
                } else if (
                    [
                        'distance_sensor',
                        'temperature_sensor',
                        'gas',
                        'light_sensor',
                        'linear_pot',
                        'pot',
                        'rain_sensor',
                        'rain_sensor_module_analog',
                        'humidity',
                        'LDR_sensor',
                        'sound_sensor',
                        'DHT11',
                        'IR_proximity',
                        'Photo diode',
                    ].includes(Type)
                ) {
                    sendBytes[arrIndex] = 'A'
                } else if (
                    ['rotatory', 'joystick', 'extender'].includes(Type)
                ) {
                    sendBytes[arrIndex] = 'A'
                    sendBytes[arrIndex] = 'A'
                }
            }
        })

        if (connectedDevice == 'Ace') {
            if (internalAccessories.isMic) {
                sendBytes[14] = 'M'
            }
            if (internalAccessories.isTemperature) {
                sendBytes[15] = 'T'
            }
            if (internalAccessories.isTouchZero) {
                sendBytes[16] = 'T'
            }
            if (internalAccessories.isTouchOne) {
                sendBytes[17] = 'T'
            }
            if (internalAccessories.isTouchTwo) {
                sendBytes[18] = 'T'
            }
            if (internalAccessories.isTouchThree) {
                sendBytes[19] = 'T'
            }
            if (internalAccessories.Four_in_one_sensor.isDistanceSensors) {
                sendBytes[20] = 'D'
            }
            if (internalAccessories.Four_in_one_sensor.isGestureSensor) {
                sendBytes[20] = 'G'
            }
            if (internalAccessories.Four_in_one_sensor.isColorSensor) {
                sendBytes[20] = 'C'
            }
            if (internalAccessories.Four_in_one_sensor.isLightSensor) {
                sendBytes[20] = 'L'
            }
            if (internalAccessories.isAccelerometer) {
                sendBytes[21] = 'A'
            }
            if (internalAccessories.isBattery) {
                sendBytes[22] = 'B'
            }
        }
        sendBytes = sendBytes.toString().replaceAll(',', '')
        return sendBytes
    }

    setPABytes_V2() {
        let humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        let sendBytes = Array(13).fill('O')

        sendBytes[0] = 'P'
        sendBytes[1] = 'A'
        sendBytes[11] = 'E'
        sendBytes[12] = 'R'
        let internalAccessories = this.props.concept.internalaccessories
        let portConnections = this.props.assembly.PortConnections
        Object.keys(portConnections).map((port) => {
            if (
                port !== 'A' &&
                port !== 'B' &&
                port !== 'C' &&
                // port !== 'D' &&
                portConnections[port] !== null
            ) {
                const getIndex = (port) => {
                    switch (port) {
                        case 'A1':
                            return 2
                        case 'A2':
                            return 3
                        case 'B1':
                            return 4
                        case 'B2':
                            return 5
                        case 'C1':
                            return 6
                        case 'C2':
                            return 7
                    }
                }

                let arrIndex = getIndex(port)
                let Type = portConnections[port].type
                if (Type == 'ultrasonic_sensor') {
                    sendBytes[arrIndex] = 'U'
                } else if (
                    Type == 'tact_switch' ||
                    Type == 'dual_switch' ||
                    Type == 'metal_detector' ||
                    Type == 'touch_sensor' ||
                    Type == 'dip_switch' ||
                    Type == 'tact_switch_2c'
                ) {
                    sendBytes[arrIndex] = 'I'
                    sendBytes[arrIndex] = 'I'
                } else if (
                    Type == 'distance_sensor' ||
                    Type == 'temperature_sensor' ||
                    Type == 'gas' ||
                    Type == 'light_sensor' ||
                    Type == 'linear_pot' ||
                    Type == 'pot' ||
                    Type == 'rain_sensor' ||
                    Type == 'humidity'
                ) {
                    sendBytes[arrIndex] = 'A'
                } else if (
                    Type == 'rotatory' ||
                    Type == 'joystick' ||
                    Type == 'extender'
                ) {
                    sendBytes[arrIndex] = 'A'
                    sendBytes[arrIndex] = 'A'
                }
            }
        })
        if (connectedDevice == 'Hexapod') {
            sendBytes[8] = humanoidPortData['24'].isPortSelected ? 'U' : 'O'
            let isAMPUEnable = false
            for (let i = 0; i < 6; i++) {
                if (humanoidPortData[25 + i].isPortSelected) {
                    isAMPUEnable = true
                    break
                }
            }
            sendBytes[9] = isAMPUEnable ? 'A' : 'O'
            sendBytes[10] = humanoidPortData['23'].isPortSelected ? 'B' : 'O'
        } else {
            sendBytes[8] = humanoidPortData['22'].isPortSelected ? 'U' : 'O'
            // sendBytes[9] = humanoidPortData['4b'].isPortSelected
            //     ? 'S'.charCodeAt(0)
            //     : 'O'.charCodeAt(0)
            let isAMPUEnable = false
            for (let i = 0; i < 6; i++) {
                if (humanoidPortData[23 + i].isPortSelected) {
                    isAMPUEnable = true
                    break
                }
            }
            sendBytes[9] = isAMPUEnable ? 'A' : 'O'
            // sendBytes[9] = 'O'.charCodeAt(0)
            sendBytes[10] = humanoidPortData['21'].isPortSelected ? 'B' : 'O'
        }

        sendBytes = sendBytes.toString().replaceAll(',', '')

        return sendBytes
    }

    setPABytes_PCv0() {
        let bytesData = Array(11).fill('O'.charCodeAt())

        bytesData.unshift('A'.charCodeAt())
        bytesData.unshift('P'.charCodeAt())

        let sessionData = JSON.parse(sessionStorage.getItem('concept'))

        let Tp0 = sessionData.internalaccessories.isTouchOne

        let portdata = JSON.parse(sessionStorage.getItem('assembly'))

        Object.keys(portdata.PortConnections).map((port) => {
            if (portdata.PortConnections[port] != null) {
                if (port === 'A' || port === 'A1' || port === 'A2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        bytesData[2] = 'U'.charCodeAt()
                        // bytesData[3] = "I".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'A') {
                            bytesData[2] = 'I'.charCodeAt()
                            bytesData[3] = 'I'.charCodeAt()
                        }
                        if (port == 'A1') bytesData[2] = 'I'.charCodeAt()
                        if (port == 'A2') bytesData[3] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'A') {
                            bytesData[2] = 'A'.charCodeAt()
                            bytesData[3] = 'A'.charCodeAt()
                        }
                        if (port == 'A1') bytesData[2] = 'A'.charCodeAt()
                        if (port == 'A2') bytesData[3] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[2] = 'A'.charCodeAt()
                        bytesData[3] = 'A'.charCodeAt()
                    }
                } else if (port === 'B' || port === 'B1' || port === 'B2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[4] = 'A'.charCodeAt()
                        bytesData[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'B') {
                            bytesData[4] = 'A'.charCodeAt()
                            bytesData[5] = 'A'.charCodeAt()
                        }
                        if (port == 'B1') bytesData[4] = 'A'.charCodeAt()
                        if (port == 'B2') bytesData[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'B') {
                            bytesData[4] = 'I'.charCodeAt()
                            bytesData[5] = 'I'.charCodeAt()
                        }
                        if (port == 'B1') bytesData[4] = 'I'.charCodeAt()
                        if (port == 'B2') bytesData[5] = 'I'.charCodeAt()
                    }
                } else if (port === 'C' || port === 'C1' || port === 'C2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        bytesData[6] = 'U'.charCodeAt()
                        // bytesData[7] = "A".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'C') {
                            bytesData[6] = 'I'.charCodeAt()
                            bytesData[7] = 'I'.charCodeAt()
                        }
                        if (port == 'C1') bytesData[6] = 'I'.charCodeAt()
                        if (port == 'C2') bytesData[7] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'C') {
                            bytesData[6] = 'A'.charCodeAt()
                            bytesData[7] = 'A'.charCodeAt()
                        }
                        if (port == 'C1') bytesData[6] = 'A'.charCodeAt()
                        if (port == 'C2') bytesData[7] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'metal_detector' ||
                        Type == 'extender'
                    ) {
                        bytesData[6] = 'A'.charCodeAt()
                        bytesData[7] = 'A'.charCodeAt()
                    }
                } else if (port === 'F' || port === 'F1' || port === 'F2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'F1') bytesData[8] = 'I'.charCodeAt()
                        if (port == 'F2') bytesData[9] = 'I'.charCodeAt()
                    }
                }
            }
        })

        if (sessionData.internalaccessories.isMic) {
            bytesData[11] = 'M'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTemperature) {
            bytesData[12] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchZero) {
            bytesData[2] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchOne) {
            bytesData[4] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchTwo) {
            bytesData[6] = 'T'.charCodeAt()
        }

        if (
            sessionData.internalaccessories.Four_in_one_sensor.isDistanceSensors
        ) {
            bytesData[10] = 'D'.charCodeAt()
        }
        if (
            sessionData.internalaccessories.Four_in_one_sensor.isGestureSensor
        ) {
            bytesData[10] = 'G'.charCodeAt()
        }
        if (sessionData.internalaccessories.Four_in_one_sensor.isColorSensor) {
            bytesData[10] = 'C'.charCodeAt()
        }
        if (sessionData.internalaccessories.Four_in_one_sensor.isLightSensor) {
            bytesData[10] = 'L'.charCodeAt()
        }
        return bytesData
    }

    setPaBytes_ZingV1V2 = () => {
        // let humanoidPortData = JSON.parse(
        //     sessionStorage.getItem('humanoidPortData')
        // )

        let sendBytes = [...HUMANOID_V1_PA_SEND_BYTES]
        console.log(this.state.humanoidPortData)
        if (!this.props.webSerial.isConnected) {
            window.location.reload()
            return
        }

        sessionStorage.setItem('isReadingZingPA', this.state.isReading)
        // this.setState({
        //     isReading: !this.state.isReading,
        // })
        let portdata = JSON.parse(sessionStorage.getItem('assembly'))

        Object.keys(portdata.PortConnections).map((port) => {
            if (portdata.PortConnections[port] != null) {
                if (port === 'A' || port === 'A1' || port === 'A2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        HUMANOID_PA_SEND_BYTES[2] = 'U'.charCodeAt()
                        HUMANOID_PA_SEND_BYTES[3] = 'U'.charCodeAt()

                        sendBytes[2] = 'U'.charCodeAt()
                        sendBytes[3] = 'U'.charCodeAt()
                        // bytesData[3] = "I".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'metal_detector' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'A') {
                            HUMANOID_PA_SEND_BYTES[2] = 'I'.charCodeAt()
                            HUMANOID_PA_SEND_BYTES[3] = 'I'.charCodeAt()

                            sendBytes[2] = 'I'.charCodeAt()
                            sendBytes[3] = 'I'.charCodeAt()
                        }
                        if (port == 'A1') {
                            HUMANOID_PA_SEND_BYTES[2] = 'I'.charCodeAt()
                            sendBytes[2] = 'I'.charCodeAt()
                        }
                        if (port == 'A2') {
                            HUMANOID_PA_SEND_BYTES[3] = 'I'.charCodeAt()
                            sendBytes[3] = 'I'.charCodeAt()
                        }
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'A') {
                            HUMANOID_PA_SEND_BYTES[2] = 'A'.charCodeAt()
                            HUMANOID_PA_SEND_BYTES[3] = 'A'.charCodeAt()

                            sendBytes[2] = 'A'.charCodeAt()
                            sendBytes[3] = 'A'.charCodeAt()
                        }
                        if (port == 'A1') {
                            HUMANOID_PA_SEND_BYTES[2] = 'A'.charCodeAt()
                            sendBytes[2] = 'A'.charCodeAt()
                        }
                        if (port == 'A2') {
                            HUMANOID_PA_SEND_BYTES[3] = 'A'.charCodeAt()
                            sendBytes[3] = 'A'.charCodeAt()
                        }
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        HUMANOID_PA_SEND_BYTES[2] = 'A'.charCodeAt()
                        HUMANOID_PA_SEND_BYTES[3] = 'A'.charCodeAt()

                        sendBytes[2] = 'A'.charCodeAt()
                        sendBytes[3] = 'A'.charCodeAt()
                    }
                } else if (port === 'B' || port === 'B1' || port === 'B2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        HUMANOID_PA_SEND_BYTES[4] = 'A'.charCodeAt()
                        HUMANOID_PA_SEND_BYTES[5] = 'A'.charCodeAt()

                        sendBytes[4] = 'A'.charCodeAt()
                        sendBytes[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'B') {
                            HUMANOID_PA_SEND_BYTES[4] = 'A'.charCodeAt()
                            HUMANOID_PA_SEND_BYTES[5] = 'A'.charCodeAt()

                            sendBytes[4] = 'A'.charCodeAt()
                            sendBytes[5] = 'A'.charCodeAt()
                        }
                        if (port == 'B1') {
                            HUMANOID_PA_SEND_BYTES[4] = 'A'.charCodeAt()
                            sendBytes[4] = 'A'.charCodeAt()
                        }
                        if (port == 'B2') {
                            HUMANOID_PA_SEND_BYTES[5] = 'A'.charCodeAt()
                            sendBytes[5] = 'A'.charCodeAt()
                        }
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'B') {
                            HUMANOID_PA_SEND_BYTES[4] = 'I'.charCodeAt()
                            HUMANOID_PA_SEND_BYTES[5] = 'I'.charCodeAt()

                            sendBytes[4] = 'I'.charCodeAt()
                            sendBytes[5] = 'I'.charCodeAt()
                        }
                        if (port == 'B1') {
                            HUMANOID_PA_SEND_BYTES[4] = 'I'.charCodeAt()
                            sendBytes[4] = 'I'.charCodeAt()
                        }
                        if (port == 'B2') {
                            HUMANOID_PA_SEND_BYTES[5] = 'I'.charCodeAt()
                            sendBytes[5] = 'I'.charCodeAt()
                        }
                    }
                } else if (port === 'C' || port === 'C1' || port === 'C2') {
                    let Type = portdata.PortConnections[port].type
                    let connectedDevice =
                        sessionStorage.getItem('deviceConnected')
                    if (Type == 'ultrasonic_sensor') {
                        if (
                            connectedDevice == 'Humanoid' &&
                            sessionStorage.getItem('zincVersion')[0] == '0'
                        )
                            return
                        sendBytes[6] = 'U'.charCodeAt()
                        sendBytes[7] = 'U'.charCodeAt()
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'C') {
                            HUMANOID_PA_SEND_BYTES[6] = 'I'.charCodeAt()
                            HUMANOID_PA_SEND_BYTES[7] = 'I'.charCodeAt()

                            sendBytes[6] = 'I'.charCodeAt()
                            sendBytes[7] = 'I'.charCodeAt()
                        }
                        if (port == 'C1') {
                            HUMANOID_PA_SEND_BYTES[6] = 'I'.charCodeAt()
                            sendBytes[6] = 'I'.charCodeAt()
                        }
                        if (port == 'C2') {
                            HUMANOID_PA_SEND_BYTES[7] = 'I'.charCodeAt()
                            sendBytes[7] = 'I'.charCodeAt()
                        }
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'C') {
                            HUMANOID_PA_SEND_BYTES[6] = 'A'.charCodeAt()
                            HUMANOID_PA_SEND_BYTES[7] = 'A'.charCodeAt()

                            sendBytes[6] = 'A'.charCodeAt()
                            sendBytes[7] = 'A'.charCodeAt()
                        }
                        if (port == 'C1') {
                            sendBytes[6] = 'A'.charCodeAt()
                            HUMANOID_PA_SEND_BYTES[6] = 'A'.charCodeAt()
                        }
                        if (port == 'C2') {
                            HUMANOID_PA_SEND_BYTES[7] = 'A'.charCodeAt()
                            sendBytes[7] = 'A'.charCodeAt()
                        }
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        HUMANOID_PA_SEND_BYTES[6] = 'A'.charCodeAt()
                        HUMANOID_PA_SEND_BYTES[7] = 'A'.charCodeAt()

                        sendBytes[6] = 'A'.charCodeAt()
                        sendBytes[7] = 'A'.charCodeAt()
                    }
                } else if (port === 'D' || port === 'D1' || port === 'D2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        HUMANOID_PA_SEND_BYTES[8] = 'U'.charCodeAt()
                        HUMANOID_PA_SEND_BYTES[9] = 'U'.charCodeAt()
                        // bytesData[7] = "A".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'D') {
                            HUMANOID_PA_SEND_BYTES[8] = 'I'.charCodeAt()
                            HUMANOID_PA_SEND_BYTES[9] = 'I'.charCodeAt()
                        }
                        if (port == 'D1')
                            HUMANOID_PA_SEND_BYTES[8] = 'I'.charCodeAt()
                        if (port == 'C2')
                            HUMANOID_PA_SEND_BYTES[9] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'D') {
                            HUMANOID_PA_SEND_BYTES[8] = 'A'.charCodeAt()
                            HUMANOID_PA_SEND_BYTES[9] = 'A'.charCodeAt()
                        }
                        if (port == 'D1')
                            HUMANOID_PA_SEND_BYTES[8] = 'A'.charCodeAt()
                        if (port == 'D2')
                            HUMANOID_PA_SEND_BYTES[9] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        HUMANOID_PA_SEND_BYTES[8] = 'A'.charCodeAt()
                        HUMANOID_PA_SEND_BYTES[9] = 'A'.charCodeAt()
                    }
                }
            }
        })

        HUMANOID_PA_SEND_BYTES[10] = 'B'.charCodeAt(0)
        HUMANOID_PA_SEND_BYTES[11] = 'A'.charCodeAt(0)
        HUMANOID_PA_SEND_BYTES[12] = 'G'.charCodeAt(0)

        sendBytes[8] = this.state.humanoidPortData['22'].isPortSelected
            ? 'U'.charCodeAt(0)
            : 'O'.charCodeAt(0)
        // sendBytes[9] = humanoidPortData['4b'].isPortSelected
        //     ? 'S'.charCodeAt(0)
        //     : 'O'.charCodeAt(0)

        //boards without acc and gyro
        let isAMPUEnable = false
        for (let i = 0; i < 6; i++) {
            if (this.state.humanoidPortData[23 + i].isPortSelected) {
                isAMPUEnable = true
                break
            }
        }
        sendBytes[9] = isAMPUEnable ? 'A'.charCodeAt(0) : 'O'.charCodeAt(0)

        // sendBytes[9] = 'O'.charCodeAt(0)
        sendBytes[10] = this.state.humanoidPortData['21'].isPortSelected
            ? 'B'.charCodeAt(0)
            : 'O'.charCodeAt(0)
        console.log('IIIIIIIIIIIIIIIII', HUMANOID_PA_SEND_BYTES)
        // let zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
        let deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
        let connectedDevice = sessionStorage.getItem('connectedDevice') || ''
        console.log('OOOOOOOOOOOOO', sendBytes.join(' '))

        return deviceVersion[0] === '1' || deviceVersion[0] === '2'
            ? sendBytes
            : HUMANOID_PA_SEND_BYTES
        // if (connectedDevice == 'Hexapod' && deviceVersion.includes('2.0')) {
        //     this.handleReadBytes_Hexapod(sendBytes)

        // continousPABytesReadToggle(
        //     // zingVersion[0] === '1' ||
        //     ['Hexapod', 'Humanoid'].includes(connectedDevice) &&
        //         deviceVersion[0] === '1'
        //         ? sendBytes
        //         : HUMANOID_PA_SEND_BYTES,
        //     54,
        //     this.props.webSerial.port,
        //     (response) => {
        //         if (!this.state.isReading) {
        //             this.setState({
        //                 huBattery: '',

        //                 huUltrasonic: '',
        //                 huSred1: '',
        //                 huSred2: '',
        //                 huSred3: '',
        //                 huSred4: '',
        //                 huSred5: '',
        //                 huSred6: '',
        //                 huSred7: '',
        //                 huSred8: '',
        //                 huSred9: '',
        //                 huSred10: '',
        //                 huSred11: '',
        //                 huSred12: '',
        //                 huSred13: '',
        //                 huSred14: '',
        //                 huSred15: '',
        //                 huSred16: '',

        //                 huAccelerometerX: '',
        //                 huAccelerometerY: '',
        //                 huAccelerometerZ: '',
        //                 huGyroX: '',
        //                 huGyroY: '',
        //                 huGyroZ: '',
        //                 rangeA1: '',
        //                 rangeA2: '',
        //                 rangeB1: '',
        //                 rangeB2: '',
        //                 rangeC1: '',
        //                 rangeC2: '',
        //                 rangeD1: '',
        //                 rangeD2: '',
        //             })
        //             return
        //         }
        //         if (connectedDevice == 'Humanoid') {
        //             this.setState({
        // huBattery: humanoidPABytesToSensorData(response, 21),
        // huAccelerometerX: humanoidPABytesToSensorData(
        //     response,
        //     23
        // ),
        // huAccelerometerY: humanoidPABytesToSensorData(
        //     response,
        //     24
        // ),
        // huAccelerometerZ: humanoidPABytesToSensorData(
        //     response,
        //     25
        // ),
        // huGyroX: humanoidPABytesToSensorData(response, 26),
        // huGyroY: humanoidPABytesToSensorData(response, 27),
        // huGyroZ: humanoidPABytesToSensorData(response, 28),
        // rangeA1: humanoidPABytesToSensorData(response, 31),
        // rangeA2: humanoidPABytesToSensorData(response, 32),
        // rangeB1: humanoidPABytesToSensorData(response, 33),
        // rangeB2: humanoidPABytesToSensorData(response, 34),
        // rangeC1: humanoidPABytesToSensorData(response, 35),
        // rangeC2: humanoidPABytesToSensorData(response, 36),
        // rangeD1: humanoidPABytesToSensorData(response, 37),
        // rangeD2: humanoidPABytesToSensorData(response, 38),

        // huUltrasonic: humanoidPABytesToSensorData(response, 22),
        //                 huSred1: humanoidPABytesToSensorData(response, 5),
        //                 huSred2: humanoidPABytesToSensorData(response, 6),
        //                 huSred3: humanoidPABytesToSensorData(response, 7),
        //                 huSred4: humanoidPABytesToSensorData(response, 8),
        //                 huSred5: humanoidPABytesToSensorData(response, 9),
        //                 huSred6: humanoidPABytesToSensorData(response, 10),
        //                 huSred7: humanoidPABytesToSensorData(response, 11),
        //                 huSred8: humanoidPABytesToSensorData(response, 12),
        //                 huSred9: humanoidPABytesToSensorData(response, 13),
        //                 huSred10: humanoidPABytesToSensorData(response, 14),
        //                 huSred11: humanoidPABytesToSensorData(response, 15),
        //                 huSred12: humanoidPABytesToSensorData(response, 16),
        //                 huSred13: humanoidPABytesToSensorData(response, 17),
        //                 huSred14: humanoidPABytesToSensorData(response, 18),
        //                 huSred15: humanoidPABytesToSensorData(response, 19),
        //                 huSred16: humanoidPABytesToSensorData(response, 20),
        //             })
        //         } else if (connectedDevice == 'Hexapod') {
        //             this.setState({
        //                 huBattery: hexapodPABytesToSensorData(response, 23),
        //                 huAccelerometerX: hexapodPABytesToSensorData(
        //                     response,
        //                     25
        //                 ),
        //                 huAccelerometerY: hexapodPABytesToSensorData(
        //                     response,
        //                     26
        //                 ),
        //                 huAccelerometerZ: hexapodPABytesToSensorData(
        //                     response,
        //                     27
        //                 ),
        //                 huGyroX: hexapodPABytesToSensorData(response, 28),
        //                 huGyroY: hexapodPABytesToSensorData(response, 29),
        //                 huGyroZ: hexapodPABytesToSensorData(response, 30),
        //                 rangeA1: hexapodPABytesToSensorData(response, 31),
        //                 rangeA2: hexapodPABytesToSensorData(response, 32),
        //                 rangeB1: hexapodPABytesToSensorData(response, 33),
        //                 rangeB2: hexapodPABytesToSensorData(response, 34),
        //                 rangeC1: hexapodPABytesToSensorData(response, 35),
        //                 rangeC2: hexapodPABytesToSensorData(response, 36),
        //                 rangeD1: hexapodPABytesToSensorData(response, 37),
        //                 rangeD2: hexapodPABytesToSensorData(response, 38),

        //                 huUltrasonic: hexapodPABytesToSensorData(response, 24),
        //                 huSred1: hexapodPABytesToSensorData(response, 5),
        //                 huSred2: hexapodPABytesToSensorData(response, 6),
        //                 huSred3: hexapodPABytesToSensorData(response, 7),
        //                 huSred4: hexapodPABytesToSensorData(response, 8),
        //                 huSred5: hexapodPABytesToSensorData(response, 9),
        //                 huSred6: hexapodPABytesToSensorData(response, 10),
        //                 huSred7: hexapodPABytesToSensorData(response, 11),
        //                 huSred8: hexapodPABytesToSensorData(response, 12),
        //                 huSred9: hexapodPABytesToSensorData(response, 13),
        //                 huSred10: hexapodPABytesToSensorData(response, 14),
        //                 huSred11: hexapodPABytesToSensorData(response, 15),
        //                 huSred12: hexapodPABytesToSensorData(response, 16),
        //                 huSred13: hexapodPABytesToSensorData(response, 17),
        //                 huSred14: hexapodPABytesToSensorData(response, 18),
        //                 huSred15: hexapodPABytesToSensorData(response, 19),
        //                 huSred16: hexapodPABytesToSensorData(response, 20),
        //                 huSred17: hexapodPABytesToSensorData(response, 21),
        //                 huSred18: hexapodPABytesToSensorData(response, 22),
        //             })
        //         }
        //     }
        // )
    }

    setPaBytes_Hexapod = () => {
        // let humanoidPortData = JSON.parse(
        //     sessionStorage.getItem('humanoidPortData')
        // )
        let sendBytes = [...HEXAPOD_PA_SEND_BYTES]

        if (!this.props.webSerial.isConnected) {
            window.location.reload()
            return
        }

        sessionStorage.setItem('isReadingZingPA', this.state.isReading)
        // this.setState({
        //     isReading: !this.state.isReading,
        // })
        let portdata = JSON.parse(sessionStorage.getItem('assembly'))

        Object.keys(portdata.PortConnections).map((port) => {
            if (portdata.PortConnections[port] != null) {
                if (port === 'A' || port === 'A1' || port === 'A2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        sendBytes[2] = 'U'.charCodeAt()
                        sendBytes[3] = 'U'.charCodeAt()
                        // bytesData[3] = "I".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'metal_detector' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'A') {
                            sendBytes[2] = 'I'.charCodeAt()
                            sendBytes[3] = 'I'.charCodeAt()
                        }
                        if (port == 'A1') {
                            sendBytes[2] = 'I'.charCodeAt()
                        }
                        if (port == 'A2') {
                            sendBytes[3] = 'I'.charCodeAt()
                        }
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'A') {
                            sendBytes[2] = 'A'.charCodeAt()
                            sendBytes[3] = 'A'.charCodeAt()
                        }
                        if (port == 'A1') {
                            sendBytes[2] = 'A'.charCodeAt()
                        }
                        if (port == 'A2') {
                            sendBytes[3] = 'A'.charCodeAt()
                        }
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        sendBytes[2] = 'A'.charCodeAt()
                        sendBytes[3] = 'A'.charCodeAt()
                    }
                } else if (port === 'B' || port === 'B1' || port === 'B2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        sendBytes[4] = 'A'.charCodeAt()
                        sendBytes[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'B') {
                            sendBytes[4] = 'A'.charCodeAt()
                            sendBytes[5] = 'A'.charCodeAt()
                        }
                        if (port == 'B1') {
                            sendBytes[4] = 'A'.charCodeAt()
                        }
                        if (port == 'B2') {
                            sendBytes[5] = 'A'.charCodeAt()
                        }
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'B') {
                            sendBytes[4] = 'I'.charCodeAt()
                            sendBytes[5] = 'I'.charCodeAt()
                        }
                        if (port == 'B1') {
                            sendBytes[4] = 'I'.charCodeAt()
                        }
                        if (port == 'B2') {
                            sendBytes[5] = 'I'.charCodeAt()
                        }
                    }
                } else if (port === 'C' || port === 'C1' || port === 'C2') {
                    let Type = portdata.PortConnections[port].type
                    let connectedDevice =
                        sessionStorage.getItem('deviceConnected')
                    if (Type == 'ultrasonic_sensor') {
                        sendBytes[6] = 'U'.charCodeAt()
                        sendBytes[7] = 'U'.charCodeAt()
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'C') {
                            sendBytes[6] = 'I'.charCodeAt()
                            sendBytes[7] = 'I'.charCodeAt()
                        }
                        if (port == 'C1') {
                            sendBytes[6] = 'I'.charCodeAt()
                        }
                        if (port == 'C2') {
                            sendBytes[7] = 'I'.charCodeAt()
                        }
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'C') {
                            sendBytes[6] = 'A'.charCodeAt()
                            sendBytes[7] = 'A'.charCodeAt()
                        }
                        if (port == 'C1') {
                            sendBytes[6] = 'A'.charCodeAt()
                        }
                        if (port == 'C2') {
                            sendBytes[7] = 'A'.charCodeAt()
                        }
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        sendBytes[6] = 'A'.charCodeAt()
                        sendBytes[7] = 'A'.charCodeAt()
                    }
                } else if (port === 'D' || port === 'D1' || port === 'D2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') return
                    // bytesData[7] = "A".charCodeAt();
                    // } else if (
                    //     Type == 'tact_switch' ||
                    //     Type == 'touch_sensor' ||
                    //     Type == 'metal_detector' ||
                    //     Type == 'dual_switch' ||
                    //     Type == 'dip_switch'
                    // ) {
                    //     if (port == 'D') {
                    //         HUMANOID_PA_SEND_BYTES[8] = 'I'.charCodeAt()
                    //         HUMANOID_PA_SEND_BYTES[9] = 'I'.charCodeAt()
                    //     }
                    //     if (port == 'D1')
                    //         HUMANOID_PA_SEND_BYTES[8] = 'I'.charCodeAt()
                    //     if (port == 'C2')
                    //         HUMANOID_PA_SEND_BYTES[9] = 'I'.charCodeAt()
                    // } else if (
                    //     Type == 'distance_sensor' ||
                    //     Type == 'temperature_sensor' ||
                    //     Type == 'gas' ||
                    //     Type == 'light_sensor' ||
                    //     Type == 'linear_pot' ||
                    //     Type == 'pot' ||
                    //     Type == 'rain_sensor' ||
                    //     Type == 'humidity'
                    // ) {
                    //     if (port == 'D') {

                    //     }
                    //     if (port == 'D1')

                    //     if (port == 'D2')

                    // } else if (
                    //     Type == 'rotatory' ||
                    //     Type == 'joystick' ||
                    //     Type == 'extender'
                    // ) {

                    // }
                }
            }
        })

        sendBytes[8] = this.state.humanoidPortData['24'].isPortSelected
            ? 'U'.charCodeAt(0)
            : 'O'.charCodeAt(0)
        // sendBytes[9] = humanoidPortData['4b'].isPortSelected
        //     ? 'S'.charCodeAt(0)
        //     : 'O'.charCodeAt(0)
        let isAMPUEnable = false
        for (let i = 0; i < 6; i++) {
            if (this.state.humanoidPortData[25 + i].isPortSelected) {
                isAMPUEnable = true
                break
            }
        }
        sendBytes[9] = isAMPUEnable ? 'A'.charCodeAt(0) : 'O'.charCodeAt(0)
        // sendBytes[9] = 'O'.charCodeAt(0)
        sendBytes[10] = this.state.humanoidPortData['23'].isPortSelected
            ? 'B'.charCodeAt(0)
            : 'O'.charCodeAt(0)

        let deviceVersion = sessionStorage.getItem('deviceVersion')
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        console.log('OOOOOOOOOOOOO', sendBytes)

        // if (connectedDevice == 'Hexapod' && deviceVersion.includes('2.0')) {
        //     this.handleReadBytes_Hexapod(sendBytes)

        return sendBytes
        // continousPABytesReadToggle(
        //     sendBytes,
        //     54,
        //     this.props.webSerial.port,
        //     (response) => {
        //         console.log('assembly screen', response)
        //         if (!this.state.isReading) {
        //             this.setState({
        //                 huBattery: '',

        //                 huUltrasonic: '',
        //                 huSred1: '',
        //                 huSred2: '',
        //                 huSred3: '',
        //                 huSred4: '',
        //                 huSred5: '',
        //                 huSred6: '',
        //                 huSred7: '',
        //                 huSred8: '',
        //                 huSred9: '',
        //                 huSred10: '',
        //                 huSred11: '',
        //                 huSred12: '',
        //                 huSred13: '',
        //                 huSred14: '',
        //                 huSred15: '',
        //                 huSred16: '',
        //                 huSred17: '',
        //                 huSred18: '',

        //                 huAccelerometerX: '',
        //                 huAccelerometerY: '',
        //                 huAccelerometerZ: '',
        //                 huGyroX: '',
        //                 huGyroY: '',
        //                 huGyroZ: '',
        //                 rangeA1: '',
        //                 rangeA2: '',
        //                 rangeB1: '',
        //                 rangeB2: '',
        //                 rangeC1: '',
        //                 rangeC2: '',
        //                 rangeD1: '',
        //                 rangeD2: '',
        //             })
        //             return
        //         }

        //         this.setState({
        //             huBattery: hexapodPABytesToSensorData(response, 23),
        //             huAccelerometerX: hexapodPABytesToSensorData(response, 25),
        //             huAccelerometerY: hexapodPABytesToSensorData(response, 26),
        //             huAccelerometerZ: hexapodPABytesToSensorData(response, 27),
        //             huGyroX: hexapodPABytesToSensorData(response, 28),
        //             huGyroY: hexapodPABytesToSensorData(response, 29),
        //             huGyroZ: hexapodPABytesToSensorData(response, 30),
        //             rangeA1: hexapodPABytesToSensorData(response, 31),
        //             rangeA2: hexapodPABytesToSensorData(response, 32),
        //             rangeB1: hexapodPABytesToSensorData(response, 33),
        //             rangeB2: hexapodPABytesToSensorData(response, 34),
        //             rangeC1: hexapodPABytesToSensorData(response, 35),
        //             rangeC2: hexapodPABytesToSensorData(response, 36),
        //             rangeD1: hexapodPABytesToSensorData(response, 37),
        //             rangeD2: hexapodPABytesToSensorData(response, 38),

        //             huUltrasonic: hexapodPABytesToSensorData(response, 24),
        //             huSred1: hexapodPABytesToSensorData(response, 5),
        //             huSred2: hexapodPABytesToSensorData(response, 6),
        //             huSred3: hexapodPABytesToSensorData(response, 7),
        //             huSred4: hexapodPABytesToSensorData(response, 8),
        //             huSred5: hexapodPABytesToSensorData(response, 9),
        //             huSred6: hexapodPABytesToSensorData(response, 10),
        //             huSred7: hexapodPABytesToSensorData(response, 11),
        //             huSred8: hexapodPABytesToSensorData(response, 12),
        //             huSred9: hexapodPABytesToSensorData(response, 13),
        //             huSred10: hexapodPABytesToSensorData(response, 14),
        //             huSred11: hexapodPABytesToSensorData(response, 15),
        //             huSred12: hexapodPABytesToSensorData(response, 16),
        //             huSred13: hexapodPABytesToSensorData(response, 17),
        //             huSred14: hexapodPABytesToSensorData(response, 18),
        //             huSred15: hexapodPABytesToSensorData(response, 19),
        //             huSred16: hexapodPABytesToSensorData(response, 20),
        //             huSred17: hexapodPABytesToSensorData(response, 21),
        //             huSred18: hexapodPABytesToSensorData(response, 22),
        //         })
        //     }
        // )
    }
    handleUsb = (e) => {
        if (this.state.isusb) {
            this.setState({
                isusb: false,
            })
        } else {
            this.setState({
                isusb: true,
            })
        }
    }
    helpBtn = (e) => {
        this.setState({ isHelp: !this.state.isHelp })
    }

    componentDidMount() {
        sessionStorage.setItem('isReadingZingPA', false)
        sessionStorage.removeItem('readSetIntervalId')
        if (sessionStorage.getItem('shield') === 'true') {
            this.props.updateInternalAccessories({
                isTouchZero: false,
                isTouchOne: false,
                isTouchTwo: false,
                isTouchThree: false,
                isTouchZeroOutput: false,
                isTouchOneOutput: false,
                isTouchTwoOutput: false,
                isTouchThreeOutput: false,
            })
        }
        if (
            sessionStorage.getItem('peeCeeATR') === 'true' &&
            sessionStorage.getItem('deviceVersion')?.startsWith('1')
        ) {
            this.props.updateInternalAccessories({
                isTouchZero: false,
                isTouchOne: false,
                isTouchTwo: false,
                isTouchThree: false,
                isTouchZeroOutput: false,
                isTouchOneOutput: false,
                isTouchTwoOutput: false,
                isTouchThreeOutput: false,
            })
        }
        if (sessionStorage.getItem('armShield') === 'true') {
            this.props.updateInternalAccessories({
                isSmileOne: false,
                isSmileTwo: false,
                isSmileThree: false,
                isSmileFour: false,
            })
        }

        atatus.beginTransaction('Assembly Screen')
        let sessionDataCheckbox = JSON.parse(
            sessionStorage.getItem('assemblyCheckbox')
        )

        if (sessionStorage.getItem('Ace')) {
            Object.keys(sessionDataCheckbox).map((val, indx) => {
                document.getElementById(`${val}`).checked =
                    sessionDataCheckbox[val]
            })
        }
        // sessionStorage.setItem("shield", "false");

        let data = JSON.parse(sessionStorage.getItem('user'))

        if (data === 1) {
            this.setState({
                isusb: true,
            })
        }
        if (data === 0) {
            this.setState({
                isusb: false,
            })
        }
        Sizes._update(document.body.clientWidth, document.body.clientHeight)
        window.addEventListener('resize', (e) => {
            const height = document.body.clientHeight
            const width = document.body.clientWidth
            Sizes._update(width, height)
            this.setState({
                height: height,
                width: width,
            })
        })

        Sizes._update(document.body.clientWidth, document.body.clientHeight)
        // Reset panning and pinching variables
        this.panEnd()
        this.pinchEnd()
        let hhh = JSON.parse(sessionStorage.getItem('SavedGDriveData'))
        let name = sessionStorage.getItem('name')
        console.log(name, 'kkklmo')
        if (name != 'null' && name != undefined && name != null) {
            for (let i = 0; i < hhh.length; i++) {
                if (name == hhh[i].name) {
                    this.props.assemblyComponent(hhh[i].assembly.workspace)
                    this.props.PortConnections(hhh[i].assembly.PortConnections)
                }
            }
        }

        /////////////////// bugFix/PLAYC-2 ////////////////////////////////
        try {
            var { workspace, PortConnections } = this.props.assembly

            const isArmShield =
                sessionStorage.getItem('armShield') == 'true' || false

            Object.entries(workspace.components).map((device) => {
                device[1].map((instance, index) => {
                    if (
                        [
                            'dc_motor',
                            'mini_geared_motor',
                            'geared_motor',
                            'dynamex_motor',
                        ].includes(device[0])
                    ) {
                        if (isArmShield) {
                            if (instance.connectedTo == 'M1') {
                                PortConnections[`M1`] = null
                            }
                        }
                    }
                })
            })

            this.props.PortConnections(PortConnections)
        } catch (e) {
            console.log('error', e)
        }
        ///////////////////////////////////////////////////////////////////

        //add the  worker onMessage event
        this.props.worker.addEventListener('message', this.messageHandler)
    }

    //read data from serial worker
    messageHandler = (e) => {
        if (
            ['RobokiPAread', 'PAreadZH', 'PAread', 'PAreadKlaw'].includes(
                e.data.type
            )
        ) {
            this.recievedBytes(e.data.value)
        } else if (e.data.type == 'PAreadPCv0') {
            this.recievedBytes_Pcv01(e.data.value, false)
        }
    }

    /**
     * Remove a component from workspace
     * @param  {WorkspaceComponent} item
     */
    ParseNodeList = (node, port, type) => {
        for (var nodeKey in node) {
            var obj = node[nodeKey].state
            if (obj['source'] == port) {
                delete node[nodeKey].state['source']
                delete node[nodeKey].state['value']
                delete node[nodeKey].state['value2']
                delete node[nodeKey].state['condition']
                delete node[nodeKey].state['hour']
                delete node[nodeKey].state['hour2']
                delete node[nodeKey].state['minute']
                delete node[nodeKey].state['minute2']
            }
            if (
                node[nodeKey].type == 'variable_output' ||
                node[nodeKey].type == 'hardware'
            ) {
                //  for tern+
                try {
                    var l = port.length
                } catch (e) {}
                if (l == 1) {
                    if (obj['assign' + port + '1']) {
                        delete node[nodeKey].state['assign' + port + '1']
                        delete node[nodeKey].state['assign' + port + '2']
                    }
                    if (obj['value' + port + '1']) {
                        delete node[nodeKey].state['value' + port + '1']
                        delete node[nodeKey].state['value' + port + '2']
                    }
                    if (type == 'dot_matrix') {
                        for (var key in obj) {
                            if (key.startsWith('dot_matrix')) {
                                delete node[nodeKey].state[key]
                            }
                        }
                    }
                    if (type == '7segment_display') {
                        for (var key in obj) {
                            if (
                                key.includes('valueB') ||
                                key.includes('valueC')
                            ) {
                                delete node[nodeKey].state[key]
                            }
                        }
                    }
                    if (type == 'OLED') {
                        for (var key in obj) {
                            if (key.includes('OLED')) {
                                delete node[nodeKey].state[key]
                            }
                        }
                    }
                    if (type == 'RGB') {
                        for (var key in obj) {
                            if (key.includes('RGBComp')) {
                                delete node[nodeKey].state[key]
                            }
                        }
                    }
                } else {
                    if (obj['assign' + port]) {
                        delete node[nodeKey].state['assign' + port]
                    }
                    if (obj['value' + port]) {
                        delete node[nodeKey].state['value' + port]
                    }
                    if (type == 'dot_matrix') {
                        for (var key in obj) {
                            if (key.startsWith('dot_matrix')) {
                                delete node[nodeKey].state[key]
                            }
                        }
                    }
                    if (type == '7segment_display') {
                        for (var key in obj) {
                            if (
                                key.includes('valueB') ||
                                key.includes('valueC')
                            ) {
                                delete node[nodeKey].state[key]
                            }
                        }
                    }
                    if (
                        [
                            'dc_motor',
                            'mini_geared_motor',
                            'geared_motor',
                            'dynamex_motor',
                        ].includes(type)
                    ) {
                        try {
                            delete node[nodeKey].state['assign' + port + '1']
                            delete node[nodeKey].state['assign' + port + '2']
                            delete node[nodeKey].state['value' + port + '1']
                            delete node[nodeKey].state['value' + port + '2']
                        } catch (e) {}
                    }
                }
            }
            if (node[nodeKey].subprogram) {
                this.ParseNodeList(node[nodeKey].subprogram, port, type)
            }
        }
        return node
    }

    setShield = (e) => {
        this.setState({ shield: e })
        if (e === false) {
            window.location.reload()
        }
    }
    setArmShield = (e) => {
        this.setState({ armShield: e })
        if (e === false) {
            window.location.reload()
        }
    }
    setPeeCeeATR = (e) => {
        this.setState({ peeCeeATR: e })
        if (e === false) {
            window.location.reload()
        }
    }

    removePlayShieldChildren = (item, { workspace, PortConnections }) => {
        // console.log('Edwin', item)
        // console.log('Edwin', workspace)
        // console.log('Edwin', PortConnections)

        if (item.type !== 'play_shield' && item.type !== 'play_shield_v1')
            return

        Object.entries(workspace.components).map((sensor) => {
            workspace.components[sensor[0]] = sensor[1].filter((child) => {
                if (!(child && child.connectedTo)) return true

                if (
                    PortConnections['A'] === null &&
                    (child.connectedTo === 'A1' || child.connectedTo === 'A2')
                ) {
                    PortConnections['A1'] = null
                    PortConnections['A2'] = null
                    return false
                } else if (
                    PortConnections['B'] === null &&
                    (child.connectedTo === 'B1' || child.connectedTo === 'B2')
                ) {
                    PortConnections['B1'] = null
                    PortConnections['B2'] = null
                    return false
                } else if (
                    PortConnections['C'] === null &&
                    (child.connectedTo === 'C1' || child.connectedTo === 'C2')
                ) {
                    PortConnections['C1'] = null
                    PortConnections['C2'] = null
                    return false
                } else if (
                    PortConnections['D'] === null &&
                    (child.connectedTo === 'D1' || child.connectedTo === 'D2')
                ) {
                    PortConnections['D1'] = null
                    PortConnections['D2'] = null
                    return false
                } else if (PortConnections['E'] && child.connectedTo === 'E') {
                    PortConnections['E'] = null
                    PortConnections['E1'] = null
                    return false
                } else if (PortConnections['F'] && child.connectedTo === 'F') {
                    PortConnections['F'] = null
                    PortConnections['F1'] = null
                    return false
                } else if (
                    child.connectedTo === 'M1' ||
                    child.connectedTo === 'M3'
                ) {
                    PortConnections['M1'] = null
                    PortConnections['M2'] = null

                    PortConnections['M3'] = null
                    PortConnections['M4'] = null
                    return false
                }
                return true
            })
        })

        // return { workspace, PortConnections }
    }

    // removeFromWorkspace11 = (item) => {
    //     this.setState({ readbytes: false })
    //     if (
    //         sessionStorage.getItem('deviceVersion')?.startsWith('1') ||
    //         ['Roboki'].includes(sessionStorage.getItem('connectedDevice'))
    //     ) {
    //         this.setState({ readbytes: false })
    //         this.setState({ isPcActive: false })
    //     }
    //     this.removePlayShieldChildren(item, this.props.assembly)

    //     var prev_data = this.props
    //     var port = item.port
    //     var updated_prog
    //     if (item.port !== undefined || item.connectedTo !== undefined) {
    //         updated_prog = removeSensorProgramData(
    //             prev_data.logic.program,
    //             item.port || item.connectedTo,
    //             item.type
    //         )
    //     }
    //     var { components } = this.props.assembly.workspace
    //     // var updated_flow_prog1 = this.ParseNodeList(prev_data.logicNew.cardConnections, port, item.type);
    //     // var updated_flow_prog2 = this.ParseNodeList(prev_data.logicNew.cards, port, item.type);
    //     if (item.port !== undefined || item.connectedTo !== undefined)
    //         prev_data.logic.program = updated_prog
    //     // prev_data.logicNew.cardConnections = updated_flow_prog1;
    //     // prev_data.logicNew.cards = updated_flow_prog2;
    //     //////////////fix-PLAYC-685/////////////////////////////

    //     if (this.state.isReading) {
    //         this.setState({ isReading: false })
    //         this.continuesPAstop()
    //     }
    //     // sessionStorage.setItem('isReadingZingPA', false)
    //     // if (
    //     //     ['Humanoid', 'Hexapod', 'Klaw'].includes(
    //     //         sessionStorage.getItem('connectedDevice')
    //     //     ) &&
    //     //     !sessionStorage.getItem('deviceVersion')?.startsWith('2')
    //     // )
    //     //     if (this.props.webSerial.isConnected) {
    //     //         this.setState({ isReading: false })
    //     //         this.setState({
    //     //             isReading: false,
    //     //             huBattery: '',
    //     //             huAccelerometerX: '',
    //     //             huAccelerometerY: '',
    //     //             huAccelerometerZ: '',
    //     //             huGyroX: '',
    //     //             huGyroY: '',
    //     //             huGyroZ: '',
    //     //             rangeA1: '',
    //     //             rangeA2: '',
    //     //             rangeB1: '',
    //     //             rangeB2: '',
    //     //             rangeC1: '',
    //     //             rangeC2: '',
    //     //             rangeD1: '',
    //     //             rangeD2: '',
    //     //         })
    //     //         continousPABytesReadToggle(
    //     //             null,
    //     //             null,
    //     //             this.props.webSerial.port,
    //     //             null,
    //     //             true
    //     //         )
    //     //     }
    // }

    removeFromWorkspace = (item) => {
        console.log('fsdfdsfdfdfdf', item)
        // this.setState({ readbytes: false })
        // if (
        //     sessionStorage.getItem('deviceVersion')?.startsWith('1') ||
        //     ['Roboki'].includes(sessionStorage.getItem('connectedDevice'))
        // ) {
        //     this.setState({ readbytes: false })
        //     this.setState({ isPcActive: false })
        // }

        this.removePlayShieldChildren(item, this.props.assembly)

        var prev_data = this.props
        var port = item.port
        var updated_prog
        if (item.port !== undefined || item.connectedTo !== undefined) {
            updated_prog = removeSensorProgramData(
                prev_data.logic.program,
                item.port || item.connectedTo,
                item.type
            )
        }
        var { components } = this.props.assembly.workspace
        // var updated_flow_prog1 = this.ParseNodeList(prev_data.logicNew.cardConnections, port, item.type);
        // var updated_flow_prog2 = this.ParseNodeList(prev_data.logicNew.cards, port, item.type);
        if (item.port !== undefined || item.connectedTo !== undefined)
            prev_data.logic.program = updated_prog
        // prev_data.logicNew.cardConnections = updated_flow_prog1;
        // prev_data.logicNew.cards = updated_flow_prog2;

        // if (this.state.isReading) {
        //     this.setState({ isReading: false })
        //     // this.continuesPAstop()
        // }

        if (
            ['Humanoid', 'Ace', 'Roboki', 'Hexapod', 'Klaw', 'Toki'].includes(
                sessionStorage.getItem('connectedDevice')
            )
        ) {
            // when we connect driver motor components, then add led to any ports when we remove the led from workspace
            // then driver motor show only one connected port(B) not both(BD) prots so add this checking

            let sdad = JSON.parse(sessionStorage.getItem('assembly')).workspace
                .components

            if (item.type == 'pc_motor_driver') {
                if (item.connectedTo == 'A' || item.connectedTo == 'C') {
                    prev_data.assembly.PortConnections['C'] = null
                    prev_data.assembly.PortConnections['A'] = null
                }

                if (item.connectedTo == 'B' || item.connectedTo == 'D') {
                    prev_data.assembly.PortConnections['B'] = null
                    prev_data.assembly.PortConnections['D'] = null
                }
            } else if (item.type == 'stepper_motor') {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.B !== null
                ) {
                    if (
                        JSON.parse(sessionStorage.getItem('assembly'))
                            .PortConnections.B.type == 'pc_motor_driver'
                    ) {
                        if (item.connectedTo == 'STPM') {
                            prev_data.assembly.PortConnections['B1'] = null
                            prev_data.assembly.PortConnections['B2'] = null

                            prev_data.assembly.PortConnections['D1'] = null
                            prev_data.assembly.PortConnections['D2'] = null
                        }
                    }
                } else if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.A !== null
                ) {
                    if (
                        JSON.parse(sessionStorage.getItem('assembly'))
                            .PortConnections.A.type == 'pc_motor_driver'
                    ) {
                        if (item.connectedTo == 'STPM') {
                            prev_data.assembly.PortConnections['A1'] = null
                            prev_data.assembly.PortConnections['A2'] = null

                            prev_data.assembly.PortConnections['C1'] = null
                            prev_data.assembly.PortConnections['C2'] = null
                        }
                    }
                }
            } else if (item.type == 'arm_shield') {
                prev_data.assembly.PortConnections['SS'] = null
                prev_data.assembly.PortConnections['MOTOR1'] = null
                prev_data.assembly.PortConnections['P0'] = null
                prev_data.assembly.PortConnections['P1'] = null
                prev_data.assembly.PortConnections['P2'] = null
                prev_data.assembly.PortConnections['SPK'] = null
            } else if (item.type == 'play_shield') {
                for (const [key, value] of Object.entries(
                    prev_data.assembly.PortConnections
                ))
                    prev_data.assembly.PortConnections[`${key}`] = null
                for (const [key, value] of Object.entries(components)) {
                    for (const [innerKey, innerValue] of Object.entries(
                        value
                    )) {
                        if (key != 'play_shield') {
                            let itemrecurse = {
                                type: key,
                                port: innerKey.connectedTo,
                            }
                            this.removeFromWorkspace(itemrecurse)
                        }
                    }
                }
            } else if (item.type == 'peeCee_ATR') {
                prev_data.assembly.PortConnections['S1'] = null
                prev_data.assembly.PortConnections['S2'] = null

                for (const [key, value] of Object.entries(components)) {
                    for (const [innerKey, innerValue] of Object.entries(
                        value
                    )) {
                        if (
                            innerValue.connectedTo == 'S1' ||
                            innerValue.connectedTo == 'S2'
                        ) {
                            let itemrecurse = {
                                type: key,
                                port: innerKey.connectedTo,
                            }
                            this.removeFromWorkspace(itemrecurse)
                        }
                    }
                }

                if (prev_data.assembly.PortConnections['M'] == null) {
                    prev_data.assembly.PortConnections['M1'] = null
                    prev_data.assembly.PortConnections['M2'] = null
                    prev_data.assembly.PortConnections['M3'] = null
                    prev_data.assembly.PortConnections['M4'] = null
                    for (const [key, value] of Object.entries(components)) {
                        for (const [innerKey, innerValue] of Object.entries(
                            value
                        )) {
                            if (
                                innerValue.connectedTo == 'M1' ||
                                innerValue.connectedTo == 'M2' ||
                                innerValue.connectedTo == 'M4' ||
                                innerValue.connectedTo == 'M3'
                            ) {
                                let itemrecurse = {
                                    type: key,
                                    port: innerKey.connectedTo,
                                }
                                this.removeFromWorkspace(itemrecurse)
                            }
                        }
                    }
                }
            } else if (
                [
                    'dc_motor',
                    'mini_geared_motor',
                    'geared_motor',
                    'dynamex_motor',
                ].includes(item.type)
            ) {
                try {
                    if (
                        sessionStorage.getItem('peeCeeATR') ||
                        ['Roboki'].includes(
                            sessionStorage.getItem('connectedDevice')
                        )
                    ) {
                        if (item.port == 'M1') {
                            prev_data.assembly.PortConnections['M1'] = null
                            prev_data.assembly.PortConnections['M2'] = null
                        } else if (item.port == 'M3') {
                            prev_data.assembly.PortConnections['M3'] = null
                            prev_data.assembly.PortConnections['M4'] = null
                        } else {
                            prev_data.assembly.PortConnections[
                                item.port[0] + '1'
                            ] = null
                            prev_data.assembly.PortConnections[
                                item.port[0] + '2'
                            ] = null
                        }
                    } else if (
                        (sessionStorage.getItem('connectedDevice') === 'Klaw' &&
                            ['F1', 'F2'].includes(item.port)) ||
                        (['Toki'].includes(
                            sessionStorage.getItem('connectedDevice')
                        ) &&
                            ['C1', 'C2', 'D1'].includes(item.port))
                    ) {
                        prev_data.assembly.PortConnections[item.port] = null
                    } else {
                        let portNum = 1
                        try {
                            portNum = parseInt(item.port[1])
                        } catch (e) {
                            portNum = 1
                            console.log('ERROR', e)
                        }
                        prev_data.assembly.PortConnections[
                            item.port[0] + portNum
                        ] = null
                        prev_data.assembly.PortConnections[
                            item.port[0] + (portNum + 1)
                        ] = null
                        // }
                    }
                } catch (e) {}
            } else {
                if (port == 'A') {
                    prev_data.assembly.PortConnections['A1'] = null
                    prev_data.assembly.PortConnections['A2'] = null
                } else if (port == 'B') {
                    prev_data.assembly.PortConnections['B1'] = null
                    prev_data.assembly.PortConnections['B2'] = null
                } else if (port == 'C') {
                    prev_data.assembly.PortConnections['C1'] = null
                    prev_data.assembly.PortConnections['C2'] = null
                } else if (port == 'D') {
                    prev_data.assembly.PortConnections['D1'] = null
                    prev_data.assembly.PortConnections['D2'] = null
                } else if (port == 'E') {
                    prev_data.assembly.PortConnections['E1'] = null
                    prev_data.assembly.PortConnections['E2'] = null
                } else if (port == 'F') {
                    prev_data.assembly.PortConnections['F'] = null
                    prev_data.assembly.PortConnections['F1'] = null
                    prev_data.assembly.PortConnections['F2'] = null
                } else {
                    prev_data.assembly.PortConnections[port] = null
                }
            }
        } else {
            prev_data.assembly.PortConnections[item.port] = null
        }
        // sessionStorage.setItem("AppDetails-new", JSON.stringify(prev_data));
        // AppState.PortConnections = prev_data.assembly.PortConnections;
        // AppState.logic = prev_data.logic;
        // AppState.logicNew = prev_data.logicNew;
        var { logic } = prev_data
        this.props.logicComponent(logic)
        var { workspace, PortConnections } = this.props.assembly
        workspace.components[item.type].splice(item.index, 1)
        this.props.assemblyComponent(workspace)

        console.log(item)

        ////////// HOTFIX: workspace data not updated while removing dual_splitter  //////////////////
        if (item.type == 'dual_splitter') {
            const newWorkspace = JSON.parse(JSON.stringify(workspace))
            const oldDualSplitter = JSON.parse(
                sessionStorage.getItem('dualsplitter')
            ).dual_splitter.map((x) => x.connectedTo)
            const updateDualSplitter = JSON.parse(
                JSON.stringify(workspace)
            ).components.dual_splitter.map((x) => x.connectedTo)
            let removedDualSplitter = oldDualSplitter.filter(
                (x) => !updateDualSplitter.includes(x)
            )
            sessionStorage.setItem(
                'dualsplitter',
                JSON.stringify(workspace.components)
            )

            Object.entries(newWorkspace.components).map((parent) => {
                const component = parent[0]

                const children = parent[1].map((child) => {
                    if (
                        child.connectedTo &&
                        (child.connectedTo == `${removedDualSplitter + '1'}` ||
                            child.connectedTo ==
                                `${removedDualSplitter + '2'}`) &&
                        PortConnections[removedDualSplitter] == null
                    ) {
                        prev_data.assembly.PortConnections[child.connectedTo] =
                            null
                        delete child.connectedTo
                    }

                    return child
                })
                newWorkspace.components[component] = children
            })
            this.props.assemblyComponent(newWorkspace)
        }

        /////////////////////////////////////////////////////////////////////////////////////////////
    }
    /**
     * Pan event handler with throttling
     */
    pan = (e) => {
        if (panning) return
        panning = true
        var { workspace } = this.props.assembly
        workspace.offset.left += (e.deltaX - oldDeltaX) / workspace.scale
        workspace.offset.top += (e.deltaY - oldDeltaY) / workspace.scale
        oldDeltaX = e.deltaX
        oldDeltaY = e.deltaY
        this.props.assemblyComponent(workspace, () => {
            panning = false
        })
    }
    /**
     * Reset pan variables
     */
    panEnd = () => {
        panning = false
        oldDeltaX = 0
        oldDeltaY = 0
    }
    /**
     * Zoom function with throttling
     * @param  {number} scale   The new scale
     * @param  {number} clientX The clientX (center of pinch or cursor position on wheel)
     * @param  {number} clientY The clientY (center of pinch or cursor position on wheel)
     */
    zoom = (scale, clientX, clientY) => {
        if (zooming) return
        var { workspace, height, width } = this.props.assembly
        if (
            (workspace.scale < 0.25 && scale < 1) ||
            (workspace.scale > 2 && scale > 1)
        )
            return
        zooming = true
        clientX -= width * 0.2
        clientY -= document.body.clientHeight - height
        // client = (offset + x/y) * scale
        // client of oldx/y = client of newx/y (current mouse position)
        // oldx/y = client / scale - offset
        // (newoffset + oldx/y) * newscale = (oldOffset + oldx/y) * oldscale
        const oldX = clientX / workspace.scale - workspace.offset.left
        const oldY = clientY / workspace.scale - workspace.offset.top
        workspace.offset.left = (workspace.offset.left + oldX) / scale - oldX
        workspace.offset.top = (workspace.offset.top + oldY) / scale - oldY
        workspace.scale *= scale
        this.props.assemblyComponent(workspace, () => {
            zooming = false
        })
    }
    /**
     * Pinch Out event handler
     */
    pinchOut = (e) => {
        this.zoom(12 / 11, e.center.x, e.center.y)
        e.preventDefault()
        return false
    }
    /**
     * Pinch In event handler
     */
    pinchIn = (e) => {
        this.zoom(11 / 12, e.center.x, e.center.y)
        e.preventDefault()
        return false
    }
    /**
     * Reset pinch variables
     */
    pinchEnd = () => {
        zooming = false
    }
    /**
     * Wheel event handler
     */
    wheel = (e) => {
        const { clientX, clientY, deltaY } = e
        // if (deltaY > 0) this.zoom(5 / 6, clientX, clientY);
        // else this.zoom(6 / 5, clientX, clientY);
        //e.preventDefault();
        return false
    }
    close = () => {
        var c = document.getElementById('assemblyConnections')
        var d = document.getElementById('biboxClass')
        var e = document.getElementById('connectPort')

        c.style.zIndex = 2
        d.style.zIndex = 2
        e.style.zIndex = 2
        this.setState({ modalIsOpen: false })
    }
    saveToLocalStorage = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion

        let updatelocalStorage = JSON.parse(
            localStorage.getItem('recoveryData')
        )
        if (programMode in updatelocalStorage) {
            if (connectedDevice in updatelocalStorage[programMode]) {
                updatelocalStorage[programMode][connectedDevice][version] =
                    sessionStorage
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            } else {
                updatelocalStorage[programMode][connectedDevice] = {
                    [version]: sessionStorage,
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        } else {
            updatelocalStorage[programMode] = {
                [connectedDevice]: {
                    [version]: sessionStorage,
                },
            }
            localStorage.setItem(
                'recoveryData',
                JSON.stringify(updatelocalStorage)
            )
        }
    }
    check = async () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (
            programMode in JSON.parse(localStorage.getItem('recoveryData')) &&
            connectedDevice in
                JSON.parse(localStorage.getItem('recoveryData'))[programMode]
        ) {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            updatelocalStorage[programMode][connectedDevice][version] =
                sessionStorage
            localStorage.setItem(
                'recoveryData',
                JSON.stringify(updatelocalStorage)
            )
        }

        if (this.state.isReading && this.props.webSerial.isConnected) {
            connectedDevice == 'Hexapod'
                ? this.setPaBytes_Hexapod()
                : this.setPaBytes_ZingV1V2()
            await new Promise((resolve) => setTimeout(resolve, 200))
        }

        this.setState({ readbytes: false })
        if (
            sessionStorage.getItem('deviceVersion')?.startsWith('1') ||
            ['Roboki', 'Toki'].includes(connectedDevice)
        ) {
            this.setState({ readbytes: false })
            this.setState({ isPcActive: false })
        }
        const timeout = setTimeout(() => {
            this.props.history.push('/logic')
            this.setState({ slideDirection: false })
            sessionStorage.setItem('slideDirection', 'false')
            clearTimeout(timeout)
            //window.location.reload()
        }, 500)
    }

    slideDirection = () => {
        this.setState({ slideDirection: true })
        sessionStorage.setItem('slideDirection', 'true')
    }

    nextVisbility = (value) => {
        this.setState({ visible: value })
    }

    componentWillUnmount() {
        this.props.worker.removeEventListener('message', this.messageHandler)
        atatus.endTransaction('Assembly Screen')
        this.screenshotInitiate()
    }
    screenshotInitiate = () => {
        try {
            var URL, BLOB
            ;(function (exports) {
                function urlsToAbsolute(nodeList) {
                    if (!nodeList.length) {
                        return []
                    }
                    var attrName = 'href'
                    if (
                        nodeList[0].__proto__ === HTMLImageElement.prototype ||
                        nodeList[0].__proto__ === HTMLScriptElement.prototype
                    ) {
                        attrName = 'src'
                    }
                    nodeList = [].map.call(nodeList, function (el, i) {
                        var attr = el.getAttribute(attrName)
                        if (!attr) {
                            return
                        }
                        var absURL = /^(https?|data):/i.test(attr)
                        if (absURL) {
                            return el
                        } else {
                            return el
                        }
                    })
                    return nodeList
                }

                function screenshotPage() {
                    if (JSON.parse(sessionStorage.getItem('Index'))) {
                        urlsToAbsolute(document.images)
                        urlsToAbsolute(
                            document.querySelectorAll("link[rel='stylesheet']")
                        )
                        var screenshot = document
                            .getElementById('assemblyscreenid')
                            .cloneNode(true)
                        try {
                            screenshot.getElementsByClassName(
                                'assemblySidebar'
                            )[0].innerHTML = ''
                        } catch (e) {}
                        $(screenshot)
                            .find('.assemblySidebar')
                            .css('background-color', 'transparent')
                        $(screenshot)
                            .find('.assemblySidebar')
                            .css('border', 'none')
                        $(screenshot)
                            .find('.assemblySidebar')
                            .css('box-shadow', 'none')

                        $(screenshot).find('.nextButton').css('display', 'none')
                        $(screenshot)
                            .find('.navbarContainer_assem')
                            .css('display', 'none')
                        $(screenshot)
                            .find('.navbarContainer_assembly')
                            .css('display', 'none')

                        //screenshot.getElementsByClassName("assemblyScreen")[0].style.backgroundImage='';
                        $(screenshot)
                            .find('.assemblyScreen')
                            .css('background-color', 'black')
                        $(screenshot)
                            .find('.assemblyScreen')
                            .css('background-image', 'none')

                        // var b = document.createElement('base');
                        // b.href = document.location.protocol + '//' + location.host;
                        var head = screenshot.querySelector('head')
                        // head.insertBefore(b, head.firstChild);
                        screenshot.style.pointerEvents = 'none'
                        screenshot.style.overflow = 'hidden'
                        screenshot.style.webkitUserSelect = 'none'
                        screenshot.style.mozUserSelect = 'none'
                        screenshot.style.msUserSelect = 'none'
                        screenshot.style.oUserSelect = 'none'
                        screenshot.style.userSelect = 'none'
                        screenshot.dataset.scrollX = window.scrollX
                        screenshot.dataset.scrollY = window.scrollY
                        var script = document.createElement('script')
                        script.textContent =
                            '(' + addOnPageLoad_.toString() + ')();'
                        // screenshot.querySelector('body').appendChild(script);

                        var blob = new Blob([screenshot.outerHTML], {
                            type: 'text/html',
                        })
                        //screenshot.getElementsByClassName("pure-u-1-5")[0].style.display='none';
                        URL = screenshot.outerHTML
                        return blob
                    }
                }
                function addOnPageLoad_() {
                    window.addEventListener('DOMContentLoaded', function (e) {
                        var scrollX =
                            document.documentElement.dataset.scrollX || 0
                        var scrollY =
                            document.documentElement.dataset.scrollY || 0
                        window.scrollTo(scrollX, scrollY)
                    })
                }

                ;(function () {
                    window.URL = window.URL || window.webkitURL
                    BLOB = screenshotPage()
                    // window.open(window.URL.createObjectURL(screenshotPage()));
                })()

                exports.screenshotPage = screenshotPage
                // exports.generate = generate;
            })(window)
            // generate();
            var div = document.createElement('div')
            // you need to create an empty div element with some id and use that id here.
            var div = document.getElementById('assemblyShot')
            div.innerHTML = URL
            sessionStorage.setItem('assempblyImageHTML', URL)
            html2canvas(div, {
                onrendered: function (canvas) {
                    div.innerHTML = ''
                    var img = canvas.toDataURL('image/png')

                    sessionStorage.setItem('assempblyImageURI', img)
                },
            })

            html2canvas(div).then(function (canvas) {
                div.innerHTML = ''
                var img = canvas.toDataURL('image/png')
                sessionStorage.setItem('assempblyImageURI', img)
            })
        } catch (e) {}
    }

    handleAssemblyCheckbox = (e) => {
        let isCheckedValue = e.target.value

        let isChecked = e.target.checked

        // if (isCheckedValue == "FOUR_in_ONE_Sensor" && isChecked == true) {
        //
        //   this.props.assembly.PortConnections[isCheckedValue] = {
        //     type: "4_in_1_sensor",
        //     index: 0,
        //   };
        // } else if (isCheckedValue == "FOUR_in_ONE_Sensor" && isChecked == false) {
        //
        //   this.props.assembly.PortConnections[isCheckedValue] = null;
        // }

        activeCheckBox[isCheckedValue] = isChecked

        sessionStorage.setItem(
            'assemblyCheckbox',
            JSON.stringify(activeCheckBox)
        )
    }

    handleFourInOneSensor = (e) => {
        if (this.state.isClickFourInOneSensor) {
            this.setState({
                isClickFourInOneSensor: false,
            })
        } else {
            this.setState({
                isClickFourInOneSensor: true,
            })
        }
    }
    handleTempratureSensor = (e) => {
        if (this.state.isClickTempratureSensor) {
            this.setState({
                isClickTempratureSensor: false,
            })
        } else {
            this.setState({
                isClickTempratureSensor: true,
            })
        }
    }

    MSBLSBToDecimal = (MSBInBinary, LSBInBinary) => {
        return parseInt(
            parseInt(MSBInBinary).toString(2).padStart(8, 0) +
                parseInt(LSBInBinary).toString(2).padStart(8, 0),
            2
        )
    }
    handleReadBytes = async () => {
        await this.setState({ readbytes: false })
    }

    setToDefault = () => {
        this.setState({
            rangeA1: '',
            rangeA2: '',
            rangeB1: '',
            rangeB2: '',
            rangeC1: '',
            rangeC2: '',
            rangeD1: '',
            rangeD2: '',
            rangeE1: '',
            rangeE2: '',
            rangeF1: '',
            rangeF2: '',
            mic: '',
            temprature: '',
            zero: '',
            one: '',
            two: '',
            temp: '',
            light: '',
            distance: '',
            gesture: '',
            red: '',
            green: '',
            blue: '',
            accelerometer_X: '',
            accelerometer_Y: '',
            accelerometer_Z: '',
            gyro_X: '',
            gyro_Y: '',
            gyro_Z: '',
            battery: '',
            switch1: '',
            switch2: '',
        })
    }

    recievedBytes_Pcv01 = async (data, blank) => {
        if (data === undefined) return
        if (blank) {
            this.setState({
                zero: '',
                one: '',
                two: '',
                rangeA1: '',
                rangeA2: '',
                rangeB1: '',
                rangeB2: '',
                rangeC1: '',
                rangeC2: '',
                red: '',
                green: '',
                blue: '',
                light: '',
                distance: '',
                gesture: '',
                mic: '',
                temprature: '',
                rangeF1: '',
                rangeF2: '',
            })
            return
        }
        console.log(data, 'gsk read')
        data = data.filter((e) => e != 80 && e != 65)
        this.setState({
            zero: this.MSBLSBToDecimal(data[1], data[0]),
            one: this.MSBLSBToDecimal(data[5], data[4]),
            two: this.MSBLSBToDecimal(data[9], data[8]),
            rangeA1: this.MSBLSBToDecimal(data[1], data[0]),
            rangeA2: this.MSBLSBToDecimal(data[3], data[2]),
            rangeB1: this.MSBLSBToDecimal(data[5], data[4]),
            rangeB2: this.MSBLSBToDecimal(data[7], data[6]),
            rangeC1: this.MSBLSBToDecimal(data[9], data[8]),
            rangeC2: this.MSBLSBToDecimal(data[11], data[10]),
            rangeF1: data[12],
            rangeF2: data[13],
            red: data[14],
            green: data[15],
            blue: data[16],
            light: this.MSBLSBToDecimal(data[15], data[14]),
            distance: data[15],
            gesture: data[16],
            mic: this.MSBLSBToDecimal(data[18], data[17]),
            temprature: this.MSBLSBToDecimal(data[20], data[19]),
        })
    }

    // recievedBytes_v1 = (bytesRecieved) => {
    //     console.log(bytesRecieved, 'recievedData')
    //     if (!this.state.isPcActive) return
    //     this.setState({
    //         rangeA1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 2),
    //     })

    //     this.setState({
    //         rangeA2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 3),
    //     })

    //     this.setState({
    //         rangeB1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 4),
    //     })

    //     this.setState({
    //         rangeB2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 5),
    //     })

    //     this.setState({
    //         rangeC1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 6),
    //     })

    //     this.setState({
    //         rangeC2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 7),
    //     })

    //     this.setState({
    //         rangeD1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 8),
    //     })

    //     this.setState({
    //         rangeD2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 9),
    //     })

    //     this.setState({
    //         rangeE1: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             10
    //         ),
    //     })

    //     this.setState({
    //         rangeE2: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             11
    //         ),
    //     })

    //     this.setState({
    //         rangeF1: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             12
    //         ),
    //     })

    //     this.setState({
    //         rangeF2: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             13
    //         ),
    //     })

    //     this.setState({
    //         mic: playComputerPABytesToSensorData(bytesRecieved.slice(2), 14),
    //     })

    //     this.setState({
    //         temprature: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             15
    //         ),
    //     })

    //     this.setState({
    //         zero: playComputerPABytesToSensorData(bytesRecieved.slice(2), 16),
    //     })

    //     this.setState({
    //         one: playComputerPABytesToSensorData(bytesRecieved.slice(2), 17),
    //     })

    //     this.setState({
    //         temp: playComputerPABytesToSensorData(bytesRecieved.slice(2), 18),
    //     })

    //     this.setState({
    //         two: playComputerPABytesToSensorData(bytesRecieved.slice(2), 19),
    //     })

    //     this.setState({
    //         light: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             '20L'
    //         ),
    //     })

    //     this.setState({
    //         distance: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             '20D'
    //         ),
    //     })

    //     this.setState({
    //         gesture: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             '20G'
    //         ),
    //     })

    //     this.setState({
    //         red: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             '20C'
    //         )[0],
    //     })

    //     this.setState({
    //         green: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             '20C'
    //         )[1],
    //     })

    //     this.setState({
    //         blue: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             '20C'
    //         )[2],
    //     })

    //     this.setState({
    //         accelerometer_X:
    //             Number(
    //                 playComputerPABytesToSensorData(
    //                     bytesRecieved.slice(2),
    //                     '21X'
    //                 )
    //             ) - 32768,
    //     })

    //     this.setState({
    //         accelerometer_Y:
    //             Number(
    //                 playComputerPABytesToSensorData(
    //                     bytesRecieved.slice(2),
    //                     '21Y'
    //                 )
    //             ) - 32768,
    //     })

    //     this.setState({
    //         accelerometer_Z:
    //             Number(
    //                 playComputerPABytesToSensorData(
    //                     bytesRecieved.slice(2),
    //                     '21Z'
    //                 )
    //             ) - 32768,
    //     })

    //     this.setState({
    //         gyro_X:
    //             Number(
    //                 playComputerPABytesToSensorData(
    //                     bytesRecieved.slice(2),
    //                     '21GX'
    //                 )
    //             ) - 2500,
    //     })

    //     this.setState({
    //         gyro_Y:
    //             Number(
    //                 playComputerPABytesToSensorData(
    //                     bytesRecieved.slice(2),
    //                     '21GY'
    //                 )
    //             ) - 2500,
    //     })

    //     this.setState({
    //         gyro_Z:
    //             Number(
    //                 playComputerPABytesToSensorData(
    //                     bytesRecieved.slice(2),
    //                     '21GZ'
    //                 )
    //             ) - 2500,
    //     })

    //     this.setState({
    //         battery: playComputerPABytesToSensorData(
    //             bytesRecieved.slice(2),
    //             22
    //         ),
    //     })
    // }

    recievedBytes = (bytesRecieved) => {
        let deviceVersion = sessionStorage.getItem('deviceVersion')
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        console.log(bytesRecieved, 'recievedData')
        if (connectedDevice == 'Ace') {
            if (deviceVersion[0] == 0) {
            } else {
                if (!this.state.isPcActive) return
                this.setState({
                    rangeA1: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        2
                    ),
                    rangeA2: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        3
                    ),
                    rangeB1: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        4
                    ),
                    rangeB2: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        5
                    ),
                    rangeC1: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        6
                    ),
                    rangeC2: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        7
                    ),
                    rangeD1: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        8
                    ),
                    rangeD2: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        9
                    ),
                    rangeE1: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        10
                    ),
                    rangeE2: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        11
                    ),
                    rangeF1: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        12
                    ),
                    rangeF2: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        13
                    ),
                    mic: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        14
                    ),
                    temprature: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        15
                    ),
                    zero: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        16
                    ),
                    one: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        17
                    ),
                    temp: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        18
                    ),
                    two: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        19
                    ),
                    light: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '20L'
                    ),
                    distance: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '20D'
                    ),
                    gesture: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '20G'
                    ),
                    red: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '20C'
                    )[0],
                    green: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '20C'
                    )[1],
                    blue: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '20C'
                    )[2],
                    accelerometer_X:
                        Number(
                            playComputerPABytesToSensorData(
                                bytesRecieved.slice(2),
                                '21X'
                            )
                        ) - 32768,
                    accelerometer_Y:
                        Number(
                            playComputerPABytesToSensorData(
                                bytesRecieved.slice(2),
                                '21Y'
                            )
                        ) - 32768,
                    accelerometer_Z:
                        Number(
                            playComputerPABytesToSensorData(
                                bytesRecieved.slice(2),
                                '21Z'
                            )
                        ) - 32768,
                    gyro_X:
                        Number(
                            playComputerPABytesToSensorData(
                                bytesRecieved.slice(2),
                                '21GX'
                            )
                        ) - 2500,
                    gyro_Y:
                        Number(
                            playComputerPABytesToSensorData(
                                bytesRecieved.slice(2),
                                '21GY'
                            )
                        ) - 2500,
                    gyro_Z:
                        Number(
                            playComputerPABytesToSensorData(
                                bytesRecieved.slice(2),
                                '21GZ'
                            )
                        ) - 2500,
                    battery: playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        22
                    ),
                })

                // this.setState({
                //     rangeA2: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         3
                //     ),
                // })

                // this.setState({
                //     rangeB1: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         4
                //     ),
                // })

                // this.setState({
                //     rangeB2: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         5
                //     ),
                // })

                // this.setState({
                //     rangeC1: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         6
                //     ),
                // })

                // this.setState({
                //     rangeC2: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         7
                //     ),
                // })

                // this.setState({
                //     rangeD1: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         8
                //     ),
                // })

                // this.setState({
                //     rangeD2: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         9
                //     ),
                // })

                // this.setState({
                //     rangeE1: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         10
                //     ),
                // })

                // this.setState({
                //     rangeE2: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         11
                //     ),
                // })

                // this.setState({
                //     rangeF1: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         12
                //     ),
                // })

                // this.setState({
                //     rangeF2: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         13
                //     ),
                // })

                // this.setState({
                //     mic: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         14
                //     ),
                // })

                // this.setState({
                //     temprature: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         15
                //     ),
                // })

                // this.setState({
                //     zero: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         16
                //     ),
                // })

                // this.setState({
                //     one: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         17
                //     ),
                // })

                // this.setState({
                //     temp: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         18
                //     ),
                // })

                // this.setState({
                //     two: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         19
                //     ),
                // })

                // this.setState({
                //     light: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         '20L'
                //     ),
                // })

                // this.setState({
                //     distance: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         '20D'
                //     ),
                // })

                // this.setState({
                //     gesture: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         '20G'
                //     ),
                // })

                // this.setState({
                //     red: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         '20C'
                //     )[0],
                // })

                // this.setState({
                //     green: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         '20C'
                //     )[1],
                // })

                // this.setState({
                //     blue: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         '20C'
                //     )[2],
                // })

                // this.setState({
                //     accelerometer_X:
                //         Number(
                //             playComputerPABytesToSensorData(
                //                 bytesRecieved.slice(2),
                //                 '21X'
                //             )
                //         ) - 32768,
                // })

                // this.setState({
                //     accelerometer_Y:
                //         Number(
                //             playComputerPABytesToSensorData(
                //                 bytesRecieved.slice(2),
                //                 '21Y'
                //             )
                //         ) - 32768,
                // })

                // this.setState({
                //     accelerometer_Z:
                //         Number(
                //             playComputerPABytesToSensorData(
                //                 bytesRecieved.slice(2),
                //                 '21Z'
                //             )
                //         ) - 32768,
                // })

                // this.setState({
                //     gyro_X:
                //         Number(
                //             playComputerPABytesToSensorData(
                //                 bytesRecieved.slice(2),
                //                 '21GX'
                //             )
                //         ) - 2500,
                // })

                // this.setState({
                //     gyro_Y:
                //         Number(
                //             playComputerPABytesToSensorData(
                //                 bytesRecieved.slice(2),
                //                 '21GY'
                //             )
                //         ) - 2500,
                // })

                // this.setState({
                //     gyro_Z:
                //         Number(
                //             playComputerPABytesToSensorData(
                //                 bytesRecieved.slice(2),
                //                 '21GZ'
                //             )
                //         ) - 2500,
                // })

                // this.setState({
                //     battery: playComputerPABytesToSensorData(
                //         bytesRecieved.slice(2),
                //         22
                //     ),
                // })
            }
        } else if (connectedDevice == 'Hexapod') {
            if (!this.state.isReading) return
            this.setState({
                rangeA1: hexapodPABytesToSensorData(bytesRecieved, 31),
                rangeA2: hexapodPABytesToSensorData(bytesRecieved, 32),
                rangeB1: hexapodPABytesToSensorData(bytesRecieved, 33),
                rangeB2: hexapodPABytesToSensorData(bytesRecieved, 34),
                rangeC1: hexapodPABytesToSensorData(bytesRecieved, 35),
                rangeC2: hexapodPABytesToSensorData(bytesRecieved, 36),
                huAccelerometerX: Number(
                    hexapodPABytesToSensorData(bytesRecieved, '25')
                ),
                huAccelerometerY: Number(
                    hexapodPABytesToSensorData(bytesRecieved, '26')
                ),
                huAccelerometerZ: Number(
                    hexapodPABytesToSensorData(bytesRecieved, '27')
                ),
                huGyroX: Number(
                    hexapodPABytesToSensorData(bytesRecieved, '28')
                ),
                huGyroY: Number(
                    hexapodPABytesToSensorData(bytesRecieved, '29')
                ),
                huGyroZ: Number(
                    hexapodPABytesToSensorData(bytesRecieved, '30')
                ),
                huBattery: hexapodPABytesToSensorData(bytesRecieved, 23),
                huUltrasonic: hexapodPABytesToSensorData(bytesRecieved, 24),
            })

            // this.setState({
            //     rangeA2: hexapodPABytesToSensorData(bytesRecieved, 32),
            // })

            // this.setState({
            //     rangeB1: hexapodPABytesToSensorData(bytesRecieved, 33),
            // })

            // this.setState({
            //     rangeB2: hexapodPABytesToSensorData(bytesRecieved, 34),
            // })

            // this.setState({
            //     rangeC1: hexapodPABytesToSensorData(bytesRecieved, 35),
            // })

            // this.setState({
            //     rangeC2: hexapodPABytesToSensorData(bytesRecieved, 36),
            // })
            // this.setState({
            //     huAccelerometerX: Number(
            //         hexapodPABytesToSensorData(bytesRecieved, '25')
            //     ),
            // })

            // this.setState({
            //     huAccelerometerY: Number(
            //         hexapodPABytesToSensorData(bytesRecieved, '26')
            //     ),
            // })

            // this.setState({
            //     huAccelerometerZ: Number(
            //         hexapodPABytesToSensorData(bytesRecieved, '27')
            //     ),
            // })

            // this.setState({
            //     huGyroX: Number(
            //         hexapodPABytesToSensorData(bytesRecieved, '28')
            //     ),
            // })

            // this.setState({
            //     huGyroY: Number(
            //         hexapodPABytesToSensorData(bytesRecieved, '29')
            //     ),
            // })

            // this.setState({
            //     huGyroZ: Number(
            //         hexapodPABytesToSensorData(bytesRecieved, '30')
            //     ),
            // })

            // this.setState({
            //     huBattery: hexapodPABytesToSensorData(bytesRecieved, 23),
            // })

            // this.setState({
            //     huUltrasonic: hexapodPABytesToSensorData(bytesRecieved, 24),
            // })
        } else if (connectedDevice == 'Humanoid') {
            if (!this.state.isReading) return
            if (deviceVersion[0] == 2) {
                this.setState({
                    rangeA1: humanoidPABytesToSensorData(bytesRecieved, 31),
                    rangeA2: humanoidPABytesToSensorData(bytesRecieved, 32),
                    rangeB1: humanoidPABytesToSensorData(bytesRecieved, 33),
                    rangeB2: humanoidPABytesToSensorData(bytesRecieved, 34),
                    rangeC1: humanoidPABytesToSensorData(bytesRecieved, 35),
                    rangeC2: humanoidPABytesToSensorData(bytesRecieved, 36),
                    huAccelerometerX: Number(
                        humanoidPABytesToSensorData(bytesRecieved, '23')
                    ),
                    huAccelerometerY: Number(
                        humanoidPABytesToSensorData(bytesRecieved, '24')
                    ),
                    huAccelerometerZ: Number(
                        humanoidPABytesToSensorData(bytesRecieved, '25')
                    ),
                    huGyroX: Number(
                        humanoidPABytesToSensorData(bytesRecieved, '26')
                    ),
                    huGyroY: Number(
                        humanoidPABytesToSensorData(bytesRecieved, '27')
                    ),
                    huGyroZ: Number(
                        humanoidPABytesToSensorData(bytesRecieved, '28')
                    ),
                    huBattery: humanoidPABytesToSensorData(bytesRecieved, 21),
                    huUltrasonic: humanoidPABytesToSensorData(
                        bytesRecieved,
                        22
                    ),
                })

                // this.setState({
                //     rangeA2: humanoidPABytesToSensorData(bytesRecieved, 32),
                // })

                // this.setState({
                //     rangeB1: humanoidPABytesToSensorData(bytesRecieved, 33),
                // })

                // this.setState({
                //     rangeB2: humanoidPABytesToSensorData(bytesRecieved, 34),
                // })

                // this.setState({
                //     rangeC1: humanoidPABytesToSensorData(bytesRecieved, 35),
                // })

                // this.setState({
                //     rangeC2: humanoidPABytesToSensorData(bytesRecieved, 36),
                // })
                // this.setState({
                //     huAccelerometerX: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '23')
                //     ),
                // })

                // this.setState({
                //     huAccelerometerY: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '24')
                //     ),
                // })

                // this.setState({
                //     huAccelerometerZ: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '25')
                //     ),
                // })

                // this.setState({
                //     huGyroX: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '26')
                //     ),
                // })

                // this.setState({
                //     huGyroY: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '27')
                //     ),
                // })

                // this.setState({
                //     huGyroZ: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '28')
                //     ),
                // })

                // this.setState({
                //     huBattery: humanoidPABytesToSensorData(bytesRecieved, 21),
                // })

                // this.setState({
                //     huUltrasonic: humanoidPABytesToSensorData(
                //         bytesRecieved,
                //         22
                //     ),
                // })
            } else {
                this.setState({
                    huBattery: humanoidPABytesToSensorData(bytesRecieved, 21),
                    huAccelerometerX: humanoidPABytesToSensorData(
                        bytesRecieved,
                        23
                    ),
                    huAccelerometerY: humanoidPABytesToSensorData(
                        bytesRecieved,
                        24
                    ),
                    huAccelerometerZ: humanoidPABytesToSensorData(
                        bytesRecieved,
                        25
                    ),
                    huGyroX: humanoidPABytesToSensorData(bytesRecieved, 26),
                    huGyroY: humanoidPABytesToSensorData(bytesRecieved, 27),
                    huGyroZ: humanoidPABytesToSensorData(bytesRecieved, 28),
                    rangeA1: humanoidPABytesToSensorData(bytesRecieved, 31),
                    rangeA2: humanoidPABytesToSensorData(bytesRecieved, 32),
                    rangeB1: humanoidPABytesToSensorData(bytesRecieved, 33),
                    rangeB2: humanoidPABytesToSensorData(bytesRecieved, 34),
                    rangeC1: humanoidPABytesToSensorData(bytesRecieved, 35),
                    rangeC2: humanoidPABytesToSensorData(bytesRecieved, 36),
                    rangeD1: humanoidPABytesToSensorData(bytesRecieved, 37),
                    rangeD2: humanoidPABytesToSensorData(bytesRecieved, 38),

                    huUltrasonic: humanoidPABytesToSensorData(
                        bytesRecieved,
                        22
                    ),
                    // huSred1: humanoidPABytesToSensorData(bytesRecieved, 5),
                    // huSred2: humanoidPABytesToSensorData(bytesRecieved, 6),
                    // huSred3: humanoidPABytesToSensorData(bytesRecieved, 7),
                    // huSred4: humanoidPABytesToSensorData(response, 8),
                    // huSred5: humanoidPABytesToSensorData(response, 9),
                    // huSred6: humanoidPABytesToSensorData(response, 10),
                    // huSred7: humanoidPABytesToSensorData(response, 11),
                    // huSred8: humanoidPABytesToSensorData(response, 12),
                    // huSred9: humanoidPABytesToSensorData(response, 13),
                    // huSred10: humanoidPABytesToSensorData(response, 14),
                    // huSred11: humanoidPABytesToSensorData(response, 15),
                    // huSred12: humanoidPABytesToSensorData(response, 16),
                    // huSred13: humanoidPABytesToSensorData(response, 17),
                    // huSred14: humanoidPABytesToSensorData(response, 18),
                    // huSred15: humanoidPABytesToSensorData(response, 19),
                    // huSred16: humanoidPABytesToSensorData(response, 20),
                })

                // this.setState({
                //     rangeA2: humanoidPABytesToSensorData(bytesRecieved, 32),
                // })

                // this.setState({
                //     rangeB1: humanoidPABytesToSensorData(bytesRecieved, 33),
                // })

                // this.setState({
                //     rangeB2: humanoidPABytesToSensorData(bytesRecieved, 34),
                // })

                // this.setState({
                //     rangeC1: humanoidPABytesToSensorData(bytesRecieved, 35),
                // })

                // this.setState({
                //     rangeC2: humanoidPABytesToSensorData(bytesRecieved, 36),
                // })
                // this.setState({
                //     rangeD1: humanoidPABytesToSensorData(bytesRecieved, 37),
                // })

                // this.setState({
                //     rangeD2: humanoidPABytesToSensorData(bytesRecieved, 38),
                // })
                // this.setState({
                //     huAccelerometerX: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '23')
                //     ),
                // })

                // this.setState({
                //     huAccelerometerY: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '24')
                //     ),
                // })

                // this.setState({
                //     huAccelerometerZ: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '25')
                //     ),
                // })

                // this.setState({
                //     huGyroX: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '26')
                //     ),
                // })

                // this.setState({
                //     huGyroY: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '27')
                //     ),
                // })

                // this.setState({
                //     huGyroZ: Number(
                //         humanoidPABytesToSensorData(bytesRecieved, '28')
                //     ),
                // })

                // this.setState({
                //     huBattery: humanoidPABytesToSensorData(bytesRecieved, 21),
                // })

                // this.setState({
                //     huUltrasonic: humanoidPABytesToSensorData(
                //         bytesRecieved,
                //         22
                //     ),
                // })
                // this.setState({
                //     huSred1: humanoidPABytesToSensorData(bytesRecieved, 5),
                // })
                // this.setState({
                //     huSred2: humanoidPABytesToSensorData(bytesRecieved, 6),
                // })
                // this.setState({
                //     huSred3: humanoidPABytesToSensorData(bytesRecieved, 7),
                // })
                // this.setState({
                //     huSred4: humanoidPABytesToSensorData(bytesRecieved, 8),
                // })
                // this.setState({
                //     huSred5: humanoidPABytesToSensorData(bytesRecieved, 9),
                // })
                // this.setState({
                //     huSred6: humanoidPABytesToSensorData(bytesRecieved, 10),
                // })
                // this.setState({
                //     huSred7: humanoidPABytesToSensorData(bytesRecieved, 11),
                // })
                // this.setState({
                //     huSred8: humanoidPABytesToSensorData(bytesRecieved, 12),
                // })
                // this.setState({
                //     huSred9: humanoidPABytesToSensorData(bytesRecieved, 13),
                // })
                // this.setState({
                //     huSred10: humanoidPABytesToSensorData(bytesRecieved, 14),
                // })
                // this.setState({
                //     huSred11: humanoidPABytesToSensorData(bytesRecieved, 15),
                // })
                // this.setState({
                //     huSred12: humanoidPABytesToSensorData(bytesRecieved, 16),
                // })
                // this.setState({
                //     huSred13: humanoidPABytesToSensorData(bytesRecieved, 17),
                // })
                // this.setState({
                //     huSred14: humanoidPABytesToSensorData(bytesRecieved, 18),
                // })
                // this.setState({
                //     huSred15: humanoidPABytesToSensorData(bytesRecieved, 19),
                // })
                // this.setState({
                //     huSred16: humanoidPABytesToSensorData(bytesRecieved, 20),
                // })
            }
        } else if (connectedDevice == 'Klaw') {
            this.setState({
                rangeA1: klawPABytesToSensorData(bytesRecieved, 31),
                rangeA2: klawPABytesToSensorData(bytesRecieved, 32),
                rangeB1: klawPABytesToSensorData(bytesRecieved, 33),
                rangeB2: klawPABytesToSensorData(bytesRecieved, 34),
                // joystick1_X: klawPABytesToSensorData(bytesRecieved, 0.1),
                // joystick1_Y: klawPABytesToSensorData(bytesRecieved, 0.2),
                // joystick2_X: klawPABytesToSensorData(bytesRecieved, 1.1),
                // joystick2_Y: klawPABytesToSensorData(bytesRecieved, 1.2),
                // joystick3_X: klawPABytesToSensorData(bytesRecieved, 2.1),
                // joystick3_Y: klawPABytesToSensorData(bytesRecieved, 2.2),
                // switch1: klawPABytesToSensorData(bytesRecieved, 3),
                // switch2: klawPABytesToSensorData(bytesRecieved, 4),
                // switch3: klawPABytesToSensorData(bytesRecieved, 5),
                pot1: klawPABytesToSensorData(bytesRecieved, 0),
                pot2: klawPABytesToSensorData(bytesRecieved, 1),
                pot3: klawPABytesToSensorData(bytesRecieved, 2),
                pot4: klawPABytesToSensorData(bytesRecieved, 3),
                pot5: klawPABytesToSensorData(bytesRecieved, 4),
                pot6: klawPABytesToSensorData(bytesRecieved, 5),
            })
        } else {
            if (!this.state.isPcActive) return
            this.setState({
                rangeA1: robokiPABytesToSensorData(bytesRecieved.slice(2), 2),
                rangeA2: robokiPABytesToSensorData(bytesRecieved.slice(2), 3),
                rangeB1: robokiPABytesToSensorData(bytesRecieved.slice(2), 6),
                rangeB2: robokiPABytesToSensorData(bytesRecieved.slice(2), 7),
                switch1: robokiPABytesToSensorData(bytesRecieved.slice(2), 23),
                switch2: robokiPABytesToSensorData(bytesRecieved.slice(2), 24),
            })
        }

        console.log('my updated state', this.state)
    }

    componentDidUpdate() {
        this.saveToLocalStorage()
        if (!this.props.webSerial.isConnected) {
            this.handleReadByte_v1()
        }
    }
    // handleReadBytes_Hexapod = async (bytesTosend) => {
    //     this.props.worker.onmessage = (e) => {
    //         if (e.data.type == 'PAread') this.recievedBytes_v1(e.data.value)
    //     }
    //     while (true) {
    //         if (!this.props.webSerial.isConnected) {
    //             try {
    //                 this.props.worker.postMessage({
    //                     type: 'write',
    //                     value: 'M8',
    //                 })
    //                 this.setToDefault()
    //             } catch (e) {}
    //             return
    //         } else {
    //             this.props.worker.postMessage({
    //                 type: 'write',
    //                 value: bytesTosend,
    //             })
    //             await new Promise((resolve) => setTimeout(resolve, 0))
    //         }
    //     }
    // }

    handleReadByte = async () => {
        // this.props.worker.onmessage = (e) => {
        //     if (e.data.type == 'PAreadPCv0')
        //         this.recievedBytes_Pcv01(e.data.value, false)
        // }

        // console.log('gsk sending', this.setPABytes_PCv0())
        if (!this.props.webSerial.isConnected) {
            await this.setState({ readbytes: false })
        }
        await this.setState({ readbytes: !this.state.readbytes })
        let flag = true
        while (true) {
            if (!this.state.readbytes && this.props.webSerial.isConnected) {
                setTimeout(() => {
                    this.props.worker.postMessage({
                        type: 'write',
                        value: 'M8',
                    })
                    this.setToDefault()
                }, 500)
                return
            }
            if (
                !this.state.readbytes ||
                goBack ||
                !this.props.webSerial.isConnected
            ) {
                setTimeout(() => this.recievedBytes_Pcv01([], true), 350)

                return
            }

            if (flag) {
                // await sendBytes(
                //     ['M'.charCodeAt(0), '8'.charCodeAt(0)],
                //     this.props.webSerial.port
                // )
                flag = false
                await new Promise((resolve) => setTimeout(resolve, 200))
            } else {
                //temp fix for page haulting problem
                // if (
                //     !(await sendBytes(
                //         this.setPABytes_PCv0(),
                //         this.props.webSerial.port
                //     ))
                // ) {
                //     window.location.reload()
                //     return
                // }
                // let read = await readBytesPa(
                //     this.props.webSerial.port,
                //     !this.state.readbytes
                // )

                // console.log('gsk read 2', this.setPABytes_PCv0(), 25)
                // setTimeout(() => this.setPABytes_v01(read, false), 320)
                this.props.worker.postMessage({
                    type: 'writeArray',
                    value: this.setPABytes_PCv0(),
                })
                await new Promise((resolve) => setTimeout(resolve, 100))
            }
        }
    }

    handleReadByte_v1 = async () => {
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        // this.props.worker.onmessage = (e) => {
        //     // if (e.data.type == 'PAread') this.recievedBytes_v1(e.data.value)
        //     if (
        //         ['RobokiPAread', 'PAreadZH', 'PAread', 'PAreadKlaw'].includes(
        //             e.data.type
        //         )
        //     ) {
        //         this.recievedBytes(e.data.value)
        //     }
        // }

        while (true) {
            if (
                (!this.state.isPcActive && !this.state.isReading) ||
                !this.props.webSerial.isConnected
            ) {
                try {
                    this.props.worker.postMessage({
                        type: 'write',
                        value: 'M8',
                    })
                    this.setToDefault()
                } catch (e) {}
                return
            } else {
                let sendPA_Byte
                if (['Humanoid', 'Hexapod'].includes(connectedDevice)) {
                    if (
                        sessionStorage.getItem('deviceVersion')?.includes('2.0')
                    ) {
                        sendPA_Byte = this.setPABytes_V2()
                        this.props.worker.postMessage({
                            type: 'write',
                            value: sendPA_Byte,
                        })
                    } else {
                        sendPA_Byte =
                            connectedDevice == 'Hexapod'
                                ? this.setPaBytes_Hexapod()
                                : this.setPaBytes_ZingV1V2()
                        this.props.worker.postMessage({
                            type: 'writeArray',
                            value: sendPA_Byte,
                        })
                    }
                }
                //  else if (connectedDevice == 'Roboki') {
                //     sendPA_Byte = this.setPABytes_Roboki()

                //     this.props.worker.postMessage({
                //         type: 'write',
                //         value: sendPA_Byte,
                //     })
                // }
                else {
                    if (connectedDevice == 'Roboki') {
                        sendPA_Byte = this.setPABytes_Roboki()
                    } else if (connectedDevice == 'Klaw') {
                        sendPA_Byte = this.setPABytes_Klaw()
                    } else {
                        sendPA_Byte = this.setPABytes_V1()
                    }

                    this.props.worker.postMessage({
                        type: 'write',
                        value: sendPA_Byte,
                    })
                }

                await new Promise((resolve) => setTimeout(resolve, 100))
            }
        }
    }

    renderImg = (e) => {
        let fourInOneSensorName = null
        let isFourInOneSensor = Object.keys(
            JSON.parse(sessionStorage.getItem('concept')).internalaccessories
                .Four_in_one_sensor
        ).some((val, inx) => {
            if (
                JSON.parse(sessionStorage.getItem('concept'))
                    .internalaccessories.Four_in_one_sensor[val] == true
            ) {
                fourInOneSensorName = val
                return true
            } else {
                return false
            }
        })

        switch (fourInOneSensorName) {
            case 'isGestureSensor': {
                return renderPrgImage('gesturesensorActive')
            }
            case 'isDistanceSensors': {
                return renderPrgImage('distancesensorsActive')
            }
            case 'isLightSensor': {
                return renderPrgImage('lightsensorActive')
            }
        }
    }

    handleInternalAccessories = () => {
        let imagelist = []
        let statelist = []

        if (
            JSON.parse(sessionStorage.getItem('concept')).internalaccessories
                .isBattery
        ) {
            imagelist.push('batteryActive')
            statelist.push('battery')
        }
        if (
            JSON.parse(sessionStorage.getItem('concept')).internalaccessories
                .isTemperature
        ) {
            imagelist.push('tempActive')
            statelist.push('temprature')
        }

        if (
            JSON.parse(sessionStorage.getItem('concept')).internalaccessories
                .isAccelerometer
        ) {
            imagelist.push('accelerometerActive')
            statelist.push('accelerometer')
        }

        if (
            JSON.parse(sessionStorage.getItem('concept')).internalaccessories
                .isFour_in_one_sensor
        ) {
            let Four_in_one_sensor = JSON.parse(
                sessionStorage.getItem('concept')
            ).internalaccessories.Four_in_one_sensor
            Object.keys(Four_in_one_sensor).map((val, inx) => {
                if (Four_in_one_sensor[val] === true) {
                    switch (val) {
                        case 'isGestureSensor': {
                            imagelist.push('gesturesensorActive')
                            statelist.push('gesture')
                            return
                        }
                        case 'isDistanceSensors': {
                            imagelist.push('distancesensorsActive')
                            statelist.push('distance')
                            return
                        }
                        case 'isLightSensor': {
                            imagelist.push('lightsensorActive')
                            statelist.push('light')
                            return
                        }
                        case 'isColorSensor': {
                            imagelist.push('colorsensorActive')
                            return
                        }
                    }
                }
            })
        }

        return (
            <div className="propertyPanel">
                {imagelist.length >= 1 ? (
                    <>
                        {imagelist.length === 1 ? (
                            <>
                                {imagelist[0] !== 'colorsensorActive' &&
                                imagelist[0] !== 'accelerometerActive' ? (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '7%',
                                            transform: 'translateY(-50%)',
                                            borderRadius: '46px',
                                            background: '#f4f4f4',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: '12px',
                                                color: '#707070',
                                                margin: '10px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    imagelist[0]
                                                )}
                                                style={{
                                                    height: '45px',
                                                    width: '45px',
                                                }}
                                            />
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                }}
                                            >
                                                {this.state[statelist[0]]}{' '}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="propertyPanel-Details-colorSensor-Temp"
                                        style={{ height: '165px' }}
                                    >
                                        <div className="colorSensor-container">
                                            <div>
                                                <img
                                                    src={renderPrgImage(
                                                        imagelist[0]
                                                    )}
                                                    style={{
                                                        height: '45px',
                                                        width: '45px',
                                                        marginTop: '5px',
                                                    }}
                                                />
                                                <p
                                                    style={{
                                                        color: '#707070',
                                                        fontSize: '12px',
                                                        marginTop: '5px',
                                                        transform:
                                                            'translateX(20%)',
                                                    }}
                                                ></p>
                                            </div>
                                            {imagelist[0] ===
                                            'colorsensorActive' ? (
                                                <div>
                                                    <p
                                                        style={{
                                                            color: '#F16178',
                                                        }}
                                                    >
                                                        R : {this.state.red}
                                                    </p>
                                                    <p
                                                        style={{
                                                            color: '#3BB273',
                                                        }}
                                                    >
                                                        G : {this.state.green}
                                                    </p>
                                                    <p
                                                        style={{
                                                            color: '#30A8CE',
                                                        }}
                                                    >
                                                        B : {this.state.blue}
                                                    </p>
                                                </div>
                                            ) : (
                                                <>
                                                    <div>
                                                        <p>
                                                            {' '}
                                                            X :{' '}
                                                            {
                                                                this.state
                                                                    .accelerometer_X
                                                            }
                                                        </p>
                                                        <p>
                                                            {' '}
                                                            Y :{' '}
                                                            {
                                                                this.state
                                                                    .accelerometer_Y
                                                            }
                                                        </p>
                                                        <p>
                                                            {' '}
                                                            Z :{' '}
                                                            {
                                                                this.state
                                                                    .accelerometer_Z
                                                            }
                                                        </p>
                                                        <p>
                                                            {' '}
                                                            X :{' '}
                                                            {this.state.gyro_X}
                                                        </p>
                                                        <p>
                                                            {' '}
                                                            Y :{' '}
                                                            {this.state.gyro_Y}
                                                        </p>
                                                        <p>
                                                            {' '}
                                                            Z :{' '}
                                                            {this.state.gyro_Z}
                                                        </p>
                                                    </div>
                                                    {/* <div>
                                                   
                                                    </div> */}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div
                                className="propertyPanel-Details-colorSensor"
                                style={{ height: 'auto' }}
                            >
                                {imagelist.map((value, index) => {
                                    return (
                                        <div>
                                            {value !== 'colorsensorActive' &&
                                            value !== 'accelerometerActive' ? (
                                                <div
                                                    style={{
                                                        fontSize: '12px',
                                                        color: '#707070',
                                                        margin: '10px',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <img
                                                        src={renderPrgImage(
                                                            value
                                                        )}
                                                        style={{
                                                            height: '45px',
                                                            width: '45px',
                                                        }}
                                                    />
                                                    <p
                                                        style={{
                                                            marginTop: '12px',
                                                            textAlign: 'center',
                                                            paddingLeft:
                                                                '2.5vh',
                                                        }}
                                                    >
                                                        {
                                                            this.state[
                                                                statelist[index]
                                                            ]
                                                        }
                                                    </p>
                                                </div>
                                            ) : (
                                                <div>
                                                    {value ===
                                                    'colorsensorActive' ? (
                                                        <>
                                                            <div
                                                                className="colorSensor-container"
                                                                style={{
                                                                    margin: ' 8% 0% 8% 8%',
                                                                }}
                                                            >
                                                                <div>
                                                                    <img
                                                                        src={renderPrgImage(
                                                                            value
                                                                        )}
                                                                        style={{
                                                                            height: '45px',
                                                                            width: '45px',
                                                                            marginTop:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        style={{
                                                                            color: '#F16178',
                                                                        }}
                                                                    >
                                                                        R :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .red
                                                                        }
                                                                    </p>
                                                                    <p
                                                                        style={{
                                                                            color: '#3BB273',
                                                                        }}
                                                                    >
                                                                        G :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .green
                                                                        }
                                                                    </p>
                                                                    <p
                                                                        style={{
                                                                            color: '#30A8CE',
                                                                        }}
                                                                    >
                                                                        B :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .blue
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className="colorSensor-container"
                                                                style={{
                                                                    margin: ' 8% 10% 60% 8%',
                                                                }}
                                                            >
                                                                <div>
                                                                    <img
                                                                        src={renderPrgImage(
                                                                            value
                                                                        )}
                                                                        style={{
                                                                            height: '45px',
                                                                            width: '45px',
                                                                            marginTop:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        height: '148px',
                                                                    }}
                                                                >
                                                                    <p>
                                                                        X :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .accelerometer_X
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        Y :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .accelerometer_Y
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        Z :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .accelerometer_Z
                                                                        }
                                                                    </p>

                                                                    <p>
                                                                        {' '}
                                                                        X :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .gyro_X
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {' '}
                                                                        Y :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .gyro_Y
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {' '}
                                                                        Z :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .gyro_Z
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </>
                ) : null}
            </div>
        )
    }

    buttonClicked = async () => {
        if (
            ['Humanoid', 'Hexapod', 'Klaw'].includes(
                sessionStorage.getItem('connectedDevice')
            )
        ) {
            await this.setState({ isReading: true })
            this.setState({ isPopup: true })
            this.handleReadByte_v1()
        } else {
            await this.setState({ isPcActive: true })
            this.setState({ isPopup: true })
            this.handleReadByte_v1()
        }
    }
    onBack = async () => {
        goBack = true
    }
    stopPaOnBack = () => {
        if (this.state.isPcActive) {
            this.setState({ readbytes: false })
            this.setState({ isPcActive: false })
        }
        if (this.state.isReading) {
            this.setState({ isReading: false })
            sessionStorage.setItem('isReadingZingPA', false)
            // this.continuesPAstop()
        }
    }

    render() {
        goBack = false
        // let vv = JSON.parse(localStorage.getItem("SavedData"));
        // console.log(vv[4].assembly, "DATA OF PORTS");
        var selectionType = localStorage.getItem('programMode')

        if (selectionType == 'program') {
            this.state.visible = 'visible'
        }

        const propertyPanelStyle = {
            // backgroundImage: `url("${propertypanel}")`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "100% 100%",
            // backgroundPosition: "center",
        }

        let fourInOneSensorName = null
        const isFourInOneSensor = Object.keys(
            JSON.parse(sessionStorage.getItem('concept')).internalaccessories
                .Four_in_one_sensor
        ).some((val, inx) => {
            if (
                JSON.parse(sessionStorage.getItem('concept'))
                    .internalaccessories.Four_in_one_sensor[val] == true
            ) {
                fourInOneSensorName = val
                return true
            } else {
                return false
            }
        })

        const isTempratureSensor = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories.isTemperature

        const version = sessionStorage.getItem('deviceVersion')
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        const { slideDirection } = this.state

        return (
            <div div style={{ overflowY: 'hidden' }}>
                {/* NAV BAR */}
                <div
                    style={{
                        height: '10%',
                        width: '100%',
                        position: 'absolute',
                        userSelect: 'none',
                    }}
                >
                    <ProgramHeader
                        data={{
                            options: ['Select', 'Assemble', 'Code', 'Simulate'],
                            selected: 1,
                        }}
                        showSave={true}
                        showGraph={false}
                        showHelp={true}
                        handleHelp={this.helpBtn}
                    />
                    <div className="navbarContainer" style={{ zIndex: '1000' }}>
                        <div
                            className="navbar-Action"
                            style={{ alignItems: 'center' }}
                        >
                            {this.state.isHelp && (
                                <div
                                    className="Ss_slide"
                                    style={{
                                        width: '106vw',
                                        top: '39vh',
                                        left: '-55%',
                                        height: '105vh',
                                    }}
                                >
                                    <AssemblyPrgm />
                                    <img
                                        className="helpClo"
                                        src={renderPrgImage('closBtn')}
                                        onClick={this.helpBtn}
                                    ></img>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : 'slide-left'
                    }`}
                    style={{ height: '100vh', width: '100vw' }}
                >
                    {' '}
                    <div
                        id="assemblyscreenid"
                        className="pure-g assemblyScreen"
                        style={{
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <div
                            className="pure-u-1-5 user-select"
                            style={{ marginTop: '5%' }}
                        >
                            <Sidebar
                                saveToLocalStorage={this.saveToLocalStorage}
                                removeFromWorkspace={this.removeFromWorkspace}
                                shield={this.state.shield}
                                setShield={this.setShield}
                                armShield={this.state.armShield}
                                setArmShield={this.setArmShield}
                                peeCeeATR={this.state.peeCeeATR}
                                setPeeCeeATR={this.setPeeCeeATR}
                                reader={reader}
                                port={this.props.webSerial.port}
                                onBack={this.onBack}
                                humanoidRead={
                                    connectedDevice == 'Hexapod'
                                        ? this.setPaBytes_Hexapod
                                        : connectedDevice == 'Klaw'
                                        ? this.klawPAread
                                        : this.setPaBytes_ZingV1V2
                                }
                                stopPA={this.stopPaOnBack}
                                check={this.check}
                                onMouse={this.handleMouseDown}
                                slideDirection={this.slideDirection}
                            />
                        </div>
                        <div id="screenshotid" className="pure-u-4-5">
                            <div
                                id="assemblyConnections"
                                style={{
                                    position: 'absolute',
                                    width: '80%',
                                    height: '100%',
                                    zIndex: 1,
                                }}
                                onWheel={this.wheel}
                            >
                                {/* IMG of ACE and  Sensor Img */}
                                {version?.startsWith('1') &&
                                ['Ace'].includes(connectedDevice) ? (
                                    <WorkSpace
                                        workspace={
                                            this.props.assembly.workspace
                                        }
                                        update={this.props.assemblyComponent}
                                        appState={this.props}
                                        height={this.props.height}
                                        width={this.props.width * 0.8}
                                        prop={this.props.assembly}
                                        updatePort={this.props.PortConnections}
                                        componentsData={
                                            this.props.assembly.components
                                        }
                                        removeFromWorkspace={
                                            this.removeFromWorkspace
                                        }
                                        stopPA={this.stopPaOnBack}
                                        nextVisbility={this.nextVisbility}
                                        responceTp0={this.state.responceTp0}
                                        responceTp1={this.state.responceTp1}
                                        responceTp2={this.state.responceTp2}
                                        touch_pad={this.state.touch_pad}
                                        touch_pad2={this.state.touch_pad2}
                                        rangeA1={this.state.rangeA1}
                                        rangeA2={this.state.rangeA2}
                                        rangeB1={this.state.rangeB1}
                                        rangeB2={this.state.rangeB2}
                                        rangeC1={this.state.rangeC1}
                                        rangeC2={this.state.rangeC2}
                                        rangeD1={this.state.rangeD1}
                                        rangeD2={this.state.rangeD2}
                                        rangeE1={this.state.rangeE1}
                                        rangeE2={this.state.rangeE2}
                                        rangeF1={this.state.rangeF1}
                                        rangeF2={this.state.rangeF2}
                                        tactswitch={this.state.tactswitch}
                                        mic={this.state.mic}
                                        temp={this.state.temp}
                                        gas={this.state.gas}
                                        zero={this.state.zero}
                                        one={this.state.one}
                                        two={this.state.two}
                                        switch1={this.state.switch1}
                                        switch2={this.state.switch2}
                                        shield={this.state.shield}
                                        setShield={this.setShield}
                                        setPeeCeeATR={this.setPeeCeeATR}
                                    />
                                ) : (
                                    <WorkSpace
                                        workspace={
                                            this.props.assembly.workspace
                                        }
                                        update={this.props.assemblyComponent}
                                        appState={this.props}
                                        height={this.props.height}
                                        width={this.props.width * 0.8}
                                        prop={this.props.assembly}
                                        updatePort={this.props.PortConnections}
                                        componentsData={
                                            this.props.assembly.components
                                        }
                                        removeFromWorkspace={
                                            this.removeFromWorkspace
                                        }
                                        stopPA={this.stopPaOnBack}
                                        handleReadBytes={this.handleReadBytes}
                                        nextVisbility={this.nextVisbility}
                                        responceTp0={this.state.responceTp0}
                                        responceTp1={this.state.responceTp1}
                                        responceTp2={this.state.responceTp2}
                                        touch_pad={this.state.touch_pad}
                                        touch_pad2={this.state.touch_pad2}
                                        rangeA1={this.state.rangeA1}
                                        rangeA2={this.state.rangeA2}
                                        rangeB1={this.state.rangeB1}
                                        rangeB2={this.state.rangeB2}
                                        rangeC1={this.state.rangeC1}
                                        rangeC2={this.state.rangeC2}
                                        rangeD1={this.state.rangeD1}
                                        rangeD2={this.state.rangeD2}
                                        rangeF1={this.state.rangeF1}
                                        rangeF2={this.state.rangeF2}
                                        tactswitch={this.state.tactswitch}
                                        mic={this.state.mic}
                                        temp={this.state.temp}
                                        gas={this.state.gas}
                                        zero={this.state.zero}
                                        one={this.state.one}
                                        two={this.state.two}
                                        switch1={this.state.switch1}
                                        switch2={this.state.switch2}
                                        shield={this.state.shield}
                                        setShield={this.setShield}
                                        armShield={this.state.armShield}
                                        setArmShield={this.setArmShield}
                                        peeCeeATR={this.state.peeCeeATR}
                                        setPeeCeeATR={this.setPeeCeeATR}
                                    />
                                )}
                            </div>
                        </div>

                        {/* WIRE ,WHITE DOTS POINT_ON ACE, SHOWS IMG at Draging Time   */}
                        <CustomDragLayer
                            style={{
                                float: 'right',
                                width: '75%',
                                height: '100%',
                                position: 'relative',
                            }}
                            height={this.props.height}
                            width={this.props.width}
                            workspace={this.props.assembly.workspace}
                            PortConnections={
                                this.props.assembly.PortConnections
                            }
                            shield={this.state.shield}
                            setShield={this.setShield}
                            armShield={this.state.armShield}
                            setArmShield={this.setArmShield}
                            peeCeeATR={this.state.peeCeeATR}
                            setPeeCeeATR={this.setPeeCeeATR}
                        />

                        {['Ace', 'Roboki', 'Toki'].includes(connectedDevice) ? (
                            <>
                                {(version?.startsWith('1') &&
                                    ['Ace'].includes(connectedDevice)) ||
                                ['Roboki', 'Toki'].includes(connectedDevice) ? (
                                    <>
                                        {this.state.isPcActive &&
                                        this.props.webSerial.isConnected ? (
                                            <div className="Read_button">
                                                <img
                                                    id="imgTemp"
                                                    src={renderPrgImage(
                                                        'readPCActive'
                                                    )}
                                                    style={{
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        top: '50%',
                                                        right: '2%',
                                                        transform:
                                                            'translateY(-50%)',
                                                        height: '60px',
                                                        width: '60px',
                                                        zIndex: '10',
                                                    }}
                                                    onClick={async () => {
                                                        await this.setState({
                                                            isPcActive: false,
                                                        })
                                                        this.setState({
                                                            isPopup: false,
                                                        })
                                                        if (
                                                            sessionStorage.getItem(
                                                                'readSetIntervalId'
                                                            ) !== null ||
                                                            undefined
                                                        ) {
                                                            this.handleReadByte_v1()
                                                        }
                                                    }}
                                                />

                                                {this.handleInternalAccessories()}
                                            </div>
                                        ) : (
                                            <>
                                                {this.state.isPcActive &&
                                                !this.props.webSerial
                                                    .isConnected ? (
                                                    <>
                                                        {this.setState({
                                                            isPcActive: false,
                                                        })}
                                                    </>
                                                ) : (
                                                    <div className="Read_button">
                                                        <img
                                                            id="imgTemp"
                                                            src={renderPrgImage(
                                                                'readPCInActive'
                                                            )}
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                cursor: 'pointer',
                                                                top: '50%',
                                                                right: '2%',
                                                                transform:
                                                                    'translateY(-50%)',
                                                                height: '60px',
                                                                width: '60px',
                                                                zIndex: '10',
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    this.props
                                                                        .webSerial
                                                                        .isConnected
                                                                )
                                                                    this.buttonClicked()
                                                            }}
                                                        />
                                                        <span className="tooltiptext">
                                                            {' '}
                                                            <p>
                                                                Read sensor
                                                                value
                                                            </p>
                                                        </span>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {this.state.readbytes &&
                                        !isFourInOneSensor &&
                                        !isTempratureSensor ? (
                                            <div className="Read_button">
                                                <img
                                                    id="img"
                                                    src={renderPrgImage(
                                                        'readPCActive'
                                                    )}
                                                    // className="iconBtnSize1"
                                                    style={{
                                                        position: 'absolute',

                                                        cursor: 'pointer',
                                                        top: '50%',
                                                        right: '2%',
                                                        transform:
                                                            'translateY(-50%)',
                                                        height: '60px',
                                                        width: '60px',
                                                        zIndex: '10',
                                                    }}
                                                    onClick={async (e) => {
                                                        await this.handleReadByte()
                                                        let ele = e.target
                                                        // ele.style.pointerEvents = 'none'
                                                        // setTimeout(() => {
                                                        //     ele.style.pointerEvents =
                                                        //         'all'
                                                        // }, 2000)
                                                    }}
                                                />
                                                {/* <span className="tooltiptext">
                                                    <p>READ SENSOR</p>
                                                </span> */}
                                            </div>
                                        ) : !isFourInOneSensor &&
                                          !isTempratureSensor ? (
                                            <div className="Read_button">
                                                <img
                                                    id="img"
                                                    src={renderPrgImage(
                                                        'readPCInActive'
                                                    )}
                                                    // className="iconBtnSize1"
                                                    style={{
                                                        position: 'absolute',

                                                        cursor: 'pointer',
                                                        top: '50%',
                                                        right: '2%',
                                                        transform:
                                                            'translateY(-50%)',
                                                        height: '60px',
                                                        width: '60px',
                                                        zIndex: '9',
                                                    }}
                                                    // onMouseDown={async () => {
                                                    //     const PLAY = [
                                                    //         'P'.charCodeAt(),
                                                    //         'L'.charCodeAt(),
                                                    //         'A'.charCodeAt(),
                                                    //         'Y'.charCodeAt(),
                                                    //     ]
                                                    //     await this.writePort(PLAY)
                                                    // }}
                                                    onClick={async () => {
                                                        if (
                                                            !this.props
                                                                .webSerial
                                                                .isConnected
                                                        )
                                                            return
                                                        this.handleReadByte()
                                                    }}
                                                />
                                                <span className="tooltiptext">
                                                    <p> Read sensor value </p>
                                                </span>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        {/* /////////////////   isFourInOneSensor   //////////////// */}

                                        {isFourInOneSensor ? (
                                            this.state.isClickFourInOneSensor ==
                                            false ? (
                                                <img
                                                    id="imgFour"
                                                    src={renderPrgImage(
                                                        'readPCInActive'
                                                    )}
                                                    style={{
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        top: '50%',
                                                        right: '2%',
                                                        transform:
                                                            'translateY(-50%)',
                                                        height: '60px',
                                                        width: '60px',
                                                        zIndex: '10',
                                                    }}
                                                    // onMouseDown={async () => {
                                                    //     const PLAY = [
                                                    //         'P'.charCodeAt(),
                                                    //         'L'.charCodeAt(),
                                                    //         'A'.charCodeAt(),
                                                    //         'Y'.charCodeAt(),
                                                    //     ]
                                                    //     await this.writePort(PLAY)
                                                    // }}
                                                    onClick={() => {
                                                        if (
                                                            !this.props
                                                                .webSerial
                                                                .isConnected
                                                        )
                                                            return
                                                        this.handleFourInOneSensor()
                                                        this.handleReadByte()
                                                    }}
                                                />
                                            ) : (
                                                <div className="propertyPanel">
                                                    {JSON.parse(
                                                        sessionStorage.getItem(
                                                            'concept'
                                                        )
                                                    ).internalaccessories
                                                        .Four_in_one_sensor
                                                        .isColorSensor ? (
                                                        <div>
                                                            {JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'concept'
                                                                )
                                                            )
                                                                .internalaccessories
                                                                .isTemperature ? (
                                                                <div
                                                                    className="propertyPanel-Details-colorSensor"
                                                                    style={
                                                                        propertyPanelStyle
                                                                    }
                                                                >
                                                                    <div className="colorSensor-container">
                                                                        <div>
                                                                            <img
                                                                                src={renderPrgImage(
                                                                                    'colorsensorActive'
                                                                                )}
                                                                                style={{
                                                                                    height: '45px',
                                                                                    width: '45px',
                                                                                    marginTop:
                                                                                        '5px',
                                                                                }}
                                                                            />
                                                                            <p
                                                                                style={{
                                                                                    color: '#707070',
                                                                                    fontSize:
                                                                                        '12px',
                                                                                    marginTop:
                                                                                        '5px',
                                                                                    transform:
                                                                                        'translateX(20%)',
                                                                                }}
                                                                            ></p>
                                                                        </div>
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    color: '#F16178',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                R
                                                                                :{' '}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .red
                                                                                }
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    color: '#3BB273',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                G
                                                                                :{' '}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .green
                                                                                }
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    color: '#30A8CE',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                B
                                                                                :{' '}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .blue
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="colorSensor-container2">
                                                                        <img
                                                                            src={renderPrgImage(
                                                                                'tempActive'
                                                                            )}
                                                                            style={{
                                                                                height: '45px',
                                                                                width: '45px',
                                                                            }}
                                                                        />

                                                                        <p>
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .temprature
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="propertyPanel-Details-colorSensor-Temp" ///////////visa
                                                                    // style={
                                                                    //     propertyPanelStyle
                                                                    // }
                                                                >
                                                                    <div className="colorSensor-container">
                                                                        <div>
                                                                            <img
                                                                                src={renderPrgImage(
                                                                                    'colorsensorActive'
                                                                                )}
                                                                                style={{
                                                                                    height: '45px',
                                                                                    width: '45px',
                                                                                    marginTop:
                                                                                        '5px',
                                                                                }}
                                                                            />
                                                                            <p
                                                                                style={{
                                                                                    color: '#707070',
                                                                                    fontSize:
                                                                                        '12px',
                                                                                    marginTop:
                                                                                        '5px',
                                                                                    transform:
                                                                                        'translateX(20%)',
                                                                                }}
                                                                            ></p>
                                                                        </div>
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    color: '#F16178',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                R
                                                                                :{' '}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .red
                                                                                }
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    color: '#3BB273',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                G
                                                                                :{' '}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .green
                                                                                }
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    color: '#30A8CE',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                B
                                                                                :{' '}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .blue
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'concept'
                                                                )
                                                            )
                                                                .internalaccessories
                                                                .isTemperature ==
                                                            true ? (
                                                                <div
                                                                    className="propertyPanel-Details"
                                                                    style={
                                                                        propertyPanelStyle
                                                                    }
                                                                >
                                                                    <div className="Item">
                                                                        <img
                                                                            src={this.renderImg()}
                                                                            style={{
                                                                                height: '45px',
                                                                                width: '45px',
                                                                            }}
                                                                        />

                                                                        <p>
                                                                            {JSON.parse(
                                                                                sessionStorage.getItem(
                                                                                    'concept'
                                                                                )
                                                                            )
                                                                                .internalaccessories
                                                                                .Four_in_one_sensor
                                                                                .isLightSensor ? (
                                                                                <>
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .light
                                                                                    }
                                                                                </>
                                                                            ) : JSON.parse(
                                                                                  sessionStorage.getItem(
                                                                                      'concept'
                                                                                  )
                                                                              )
                                                                                  .internalaccessories
                                                                                  .Four_in_one_sensor
                                                                                  .isDistanceSensors ? (
                                                                                <>
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .distance
                                                                                    }
                                                                                </>
                                                                            ) : JSON.parse(
                                                                                  sessionStorage.getItem(
                                                                                      'concept'
                                                                                  )
                                                                              )
                                                                                  .internalaccessories
                                                                                  .Four_in_one_sensor
                                                                                  .isGestureSensor ? (
                                                                                <>
                                                                                    {this
                                                                                        .state
                                                                                        .gesture ==
                                                                                    1 ? (
                                                                                        <p>
                                                                                            Down
                                                                                        </p>
                                                                                    ) : this
                                                                                          .state
                                                                                          .gesture ==
                                                                                      2 ? (
                                                                                        <p>
                                                                                            Up
                                                                                        </p>
                                                                                    ) : this
                                                                                          .state
                                                                                          .gesture ==
                                                                                      3 ? (
                                                                                        <p>
                                                                                            Right
                                                                                        </p>
                                                                                    ) : this
                                                                                          .state
                                                                                          .gesture ==
                                                                                      4 ? (
                                                                                        <p>
                                                                                            Left
                                                                                        </p>
                                                                                    ) : (
                                                                                        <p></p>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <>

                                                                                </>
                                                                            )}
                                                                        </p>
                                                                    </div>

                                                                    <div className="Item2">
                                                                        <img
                                                                            src={renderPrgImage(
                                                                                'tempActive'
                                                                            )}
                                                                            style={{
                                                                                height: '45px',
                                                                                width: '45px',
                                                                            }}
                                                                        />

                                                                        <p>
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .temprature
                                                                            }{' '}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                // gesture laxmi
                                                                <div
                                                                    className="propertyPanel-Details-FIO"
                                                                    style={
                                                                        propertyPanelStyle
                                                                    }
                                                                >
                                                                    <div className="Item3">
                                                                        <img
                                                                            src={this.renderImg()}
                                                                            style={{
                                                                                height: '45px',
                                                                                width: '45px',
                                                                            }}
                                                                        />

                                                                        <p>
                                                                            {JSON.parse(
                                                                                sessionStorage.getItem(
                                                                                    'concept'
                                                                                )
                                                                            )
                                                                                .internalaccessories
                                                                                .Four_in_one_sensor
                                                                                .isLightSensor ? (
                                                                                <>
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .light
                                                                                    }
                                                                                </>
                                                                            ) : JSON.parse(
                                                                                  sessionStorage.getItem(
                                                                                      'concept'
                                                                                  )
                                                                              )
                                                                                  .internalaccessories
                                                                                  .Four_in_one_sensor
                                                                                  .isDistanceSensors ? (
                                                                                <>
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .distance
                                                                                    }
                                                                                </>
                                                                            ) : JSON.parse(
                                                                                  sessionStorage.getItem(
                                                                                      'concept'
                                                                                  )
                                                                              )
                                                                                  .internalaccessories
                                                                                  .Four_in_one_sensor
                                                                                  .isGestureSensor ? (
                                                                                <>
                                                                                    {this
                                                                                        .state
                                                                                        .gesture ==
                                                                                    1 ? (
                                                                                        <p>
                                                                                            Down
                                                                                        </p>
                                                                                    ) : this
                                                                                          .state
                                                                                          .gesture ==
                                                                                      2 ? (
                                                                                        <p>
                                                                                            Up
                                                                                        </p>
                                                                                    ) : this
                                                                                          .state
                                                                                          .gesture ==
                                                                                      3 ? (
                                                                                        <p>
                                                                                            Right
                                                                                        </p>
                                                                                    ) : this
                                                                                          .state
                                                                                          .gesture ==
                                                                                      4 ? (
                                                                                        <p>
                                                                                            Left
                                                                                        </p>
                                                                                    ) : (
                                                                                        <p></p>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <>

                                                                                </>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}

                                                    <div className="propertyPanel-closeBtn">
                                                        <img
                                                            id="imgFour"
                                                            src={renderPrgImage(
                                                                'readPCActive'
                                                            )}
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '50%',
                                                                right: '2%',
                                                                cursor: 'pointer',
                                                                transform:
                                                                    'translateY(-50%)',
                                                                height: '60px',
                                                                width: '60px',
                                                                zIndex: '10',
                                                            }}
                                                            onClick={async () => {
                                                                await this.handleReadByte()
                                                                await this.handleFourInOneSensor()
                                                                let ele =
                                                                    document.getElementById(
                                                                        'imgFour'
                                                                    )
                                                                // ele.style.pointerEvents =
                                                                //     'none'
                                                                // setTimeout(
                                                                //     () =>
                                                                //         ele.style
                                                                //             .pointerEvents ==
                                                                //         'all',
                                                                //     2000
                                                                // )
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        ) : null}

                                        {/* ///////////////// isTempratureSensor    //////////////// */}

                                        {isTempratureSensor ? (
                                            <div>
                                                {' '}
                                                {isFourInOneSensor ? null : this
                                                      .state
                                                      .isClickTempratureSensor ==
                                                  true ? (
                                                    <div>
                                                        <div className="propertyPanel">
                                                            <div
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    right: '7%',
                                                                    transform:
                                                                        'translateY(-50%)',
                                                                    height: '100px',
                                                                    width: '65px',

                                                                    borderRadius:
                                                                        '46px',
                                                                    background:
                                                                        '#f4f4f4',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        fontSize:
                                                                            '12px',
                                                                        color: '#707070',
                                                                        margin: '10px',
                                                                        textAlign:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={renderPrgImage(
                                                                            'tempActive'
                                                                        )}
                                                                        style={{
                                                                            height: '45px',
                                                                            width: '45px',
                                                                        }}
                                                                    />

                                                                    <p
                                                                        style={{
                                                                            marginTop:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .temprature
                                                                        }{' '}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="propertyPanel-closeBtn">
                                                            <div className="Read_button">
                                                                <img
                                                                    id="imgTemp"
                                                                    src={renderPrgImage(
                                                                        'readPCActive'
                                                                    )}
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        cursor: 'pointer',
                                                                        top: '50%',
                                                                        right: '2%',
                                                                        transform:
                                                                            'translateY(-50%)',
                                                                        height: '60px',
                                                                        width: '60px',
                                                                        zIndex: '10',
                                                                    }}
                                                                    onClick={async (
                                                                        e
                                                                    ) => {
                                                                        await this.handleReadByte()
                                                                        await this.handleTempratureSensor()

                                                                        let ele =
                                                                            document.getElementById(
                                                                                'imgTemp'
                                                                            )

                                                                        // ele.style.pointerEvents =
                                                                        //     'none'

                                                                        // setTimeout(
                                                                        //     () => {
                                                                        //         ele.style.pointerEvents =
                                                                        //             'all'
                                                                        //     },
                                                                        //     2000
                                                                        // )
                                                                    }}
                                                                />
                                                                <span className="tooltiptext">
                                                                    {' '}
                                                                    <p>
                                                                        Read
                                                                        From
                                                                        Sensor
                                                                    </p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="Read_button">
                                                        <img
                                                            id="imgTemp"
                                                            src={renderPrgImage(
                                                                'readPCInActive'
                                                            )}
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                cursor: 'pointer',
                                                                top: '50%',
                                                                right: '2%',
                                                                transform:
                                                                    'translateY(-50%)',
                                                                height: '60px',
                                                                width: '60px',
                                                                zIndex: '10',
                                                            }}
                                                            // onMouseDown={async () => {
                                                            //     const PLAY = [
                                                            //         'P'.charCodeAt(),
                                                            //         'L'.charCodeAt(),
                                                            //         'A'.charCodeAt(),
                                                            //         'Y'.charCodeAt(),
                                                            //     ]
                                                            //     await this.writePort(
                                                            //         PLAY
                                                            //     )
                                                            // }}
                                                            onClick={() => {
                                                                if (
                                                                    !this.props
                                                                        .webSerial
                                                                        .isConnected
                                                                )
                                                                    return
                                                                this.handleTempratureSensor()
                                                                this.handleReadByte()
                                                            }}
                                                        />
                                                        <span className="tooltiptext">
                                                            {' '}
                                                            <p>
                                                                Read sensor
                                                                value
                                                            </p>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                    </>
                                )}{' '}
                            </>
                        ) : (
                            <></>
                        )}

                        {connectedDevice === 'Humanoid' && (
                            <>
                                <div className="Read_button">
                                    <img
                                        id="readButton"
                                        src={
                                            this.state.isReading &&
                                            this.props.webSerial.isConnected
                                                ? renderPrgImage('readPCActive')
                                                : renderPrgImage(
                                                      'readPCInActive'
                                                  )
                                        }
                                        style={{
                                            position: 'absolute',
                                            cursor: 'pointer',
                                            top: '50%',
                                            right: '2%',
                                            transform: 'translateY(-50%)',
                                            height: '60px',
                                            width: '60px',
                                            zIndex: '10',
                                        }}
                                        onClick={async (e) => {
                                            if (
                                                !this.state.isReading &&
                                                this.props.webSerial.isConnected
                                            ) {
                                                this.buttonClicked()
                                            } else {
                                                await this.setState({
                                                    isReading: false,
                                                })
                                                this.setState({
                                                    isPopup: false,
                                                })
                                                if (
                                                    sessionStorage.getItem(
                                                        'readSetIntervalId'
                                                    ) !== null ||
                                                    undefined
                                                ) {
                                                    // this.handleReadByte_v1()
                                                }
                                            }
                                        }}
                                    />
                                    {!this.state.isReading ? (
                                        <span className="tooltiptext">
                                            {' '}
                                            <p>Read sensor value</p>
                                        </span>
                                    ) : null}
                                    {this.state.isReading &&
                                        !this.props.webSerial.isConnected && (
                                            <>{this.setPaBytes_ZingV1V2()}</>
                                        )}
                                </div>

                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '37vh',
                                        left: '83vw',
                                        width: '160px',
                                        // height: "300px",

                                        display: 'flex',
                                        flexDirection: 'column',

                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                    // className="bg-primary"
                                >
                                    {this.state.isReading &&
                                        this.props.webSerial.isConnected && (
                                            <>
                                                {this.state.humanoidPortData[
                                                    '23'
                                                ].isPortSelected ||
                                                this.state.humanoidPortData[
                                                    '24'
                                                ].isPortSelected ||
                                                this.state.humanoidPortData[
                                                    '25'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activeAccelerometerX'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>

                                                        <div className="col-6">
                                                            {this.state
                                                                .humanoidPortData[
                                                                '23'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    X :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huAccelerometerX
                                                                    }
                                                                </p>
                                                            ) : null}
                                                            {this.state
                                                                .humanoidPortData[
                                                                '24'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Y :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huAccelerometerY
                                                                    }
                                                                </p>
                                                            ) : null}
                                                            {this.state
                                                                .humanoidPortData[
                                                                '25'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Z :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huAccelerometerZ
                                                                    }
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {this.state.humanoidPortData[
                                                    '26'
                                                ].isPortSelected ||
                                                this.state.humanoidPortData[
                                                    '27'
                                                ].isPortSelected ||
                                                this.state.humanoidPortData[
                                                    '28'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activeGyroX'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            {this.state
                                                                .humanoidPortData[
                                                                '26'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    X :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huGyroX
                                                                    }
                                                                </p>
                                                            ) : null}
                                                            {this.state
                                                                .humanoidPortData[
                                                                '27'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Y :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huGyroY
                                                                    }
                                                                </p>
                                                            ) : null}

                                                            {this.state
                                                                .humanoidPortData[
                                                                '28'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Z :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huGyroZ
                                                                    }
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {this.state.humanoidPortData[
                                                    '21'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                marginLeft: '',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activeBattery'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    marginRight:
                                                                        '1rem',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <p
                                                                style={{
                                                                    margin: '0',
                                                                    padding:
                                                                        '0',
                                                                    fontSize:
                                                                        '0.8rem',

                                                                    margin: 'auto',
                                                                    height: '100%',

                                                                    paddingTop:
                                                                        '30%',
                                                                }}
                                                                className="text-center"
                                                            >
                                                                {
                                                                    this.state
                                                                        .huBattery
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {this.state.humanoidPortData[
                                                    '22'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                marginLeft: '',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activeUltrasonic'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    marginRight:
                                                                        '1rem',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <p
                                                                style={{
                                                                    margin: '0',
                                                                    padding:
                                                                        '0',
                                                                    fontSize:
                                                                        '0.8rem',

                                                                    margin: 'auto',
                                                                    height: '100%',

                                                                    paddingTop:
                                                                        '30%',
                                                                }}
                                                                className="text-center"
                                                            >
                                                                {
                                                                    this.state
                                                                        .huUltrasonic
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {/* --------------read servo ------------------- */}
                                                {/* {this.state.humanoidPortData[
                                                    '4b'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activeIndividual'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            {[
                                                                1, 2, 3, 4, 5,
                                                                6, 7, 8, 9, 10,
                                                                11, 12, 13, 14,
                                                                15, 16,
                                                            ].map((ind) => (
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    {`S${ind}`}{' '}
                                                                    :{' '}
                                                                    {
                                                                        this
                                                                            .state[
                                                                            `huSred${ind}`
                                                                        ]
                                                                    }
                                                                </p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : null} */}
                                            </>
                                        )}
                                </div>
                            </>
                        )}

                        {connectedDevice === 'Hexapod' && (
                            <>
                                <div className="Read_button">
                                    <img
                                        id="readButton"
                                        src={
                                            this.state.isReading
                                                ? renderPrgImage('readPCActive')
                                                : renderPrgImage(
                                                      'readPCInActive'
                                                  )
                                        }
                                        style={{
                                            position: 'absolute',
                                            cursor: 'pointer',
                                            top: '50%',
                                            right: '2%',
                                            transform: 'translateY(-50%)',
                                            height: '60px',
                                            width: '60px',
                                            zIndex: '10',
                                        }}
                                        onClick={async (e) => {
                                            if (
                                                !this.state.isReading &&
                                                this.props.webSerial.isConnected
                                            ) {
                                                this.buttonClicked()
                                            } else {
                                                await this.setState({
                                                    isReading: false,
                                                })
                                                this.setState({
                                                    isPopup: false,
                                                })
                                                if (
                                                    sessionStorage.getItem(
                                                        'readSetIntervalId'
                                                    ) !== null ||
                                                    undefined
                                                ) {
                                                    // this.handleReadByte_v1()
                                                }
                                            }
                                        }}
                                    />
                                    {!this.state.isReading ? (
                                        <span className="tooltiptext">
                                            {' '}
                                            <p>Read sensor value</p>
                                        </span>
                                    ) : null}
                                    {this.state.isReading &&
                                        !this.props.webSerial.isConnected && (
                                            <>{this.setPaBytes_Hexapod()}</>
                                        )}
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '37vh',
                                        left: '83vw',
                                        width: '160px',
                                        // height: "300px",

                                        display: 'flex',
                                        flexDirection: 'column',

                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                    // className="bg-primary"
                                >
                                    {this.state.isReading &&
                                        this.props.webSerial.isConnected && (
                                            <>
                                                {this.state.humanoidPortData[
                                                    '25'
                                                ].isPortSelected ||
                                                this.state.humanoidPortData[
                                                    '26'
                                                ].isPortSelected ||
                                                this.state.humanoidPortData[
                                                    '27'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activeAccelerometerX'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>

                                                        <div className="col-6">
                                                            {this.state
                                                                .humanoidPortData[
                                                                '25'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    X :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huAccelerometerX
                                                                    }
                                                                </p>
                                                            ) : null}
                                                            {this.state
                                                                .humanoidPortData[
                                                                '26'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Y :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huAccelerometerY
                                                                    }
                                                                </p>
                                                            ) : null}
                                                            {this.state
                                                                .humanoidPortData[
                                                                '27'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Z :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huAccelerometerZ
                                                                    }
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {this.state.humanoidPortData[
                                                    '28'
                                                ].isPortSelected ||
                                                this.state.humanoidPortData[
                                                    '29'
                                                ].isPortSelected ||
                                                this.state.humanoidPortData[
                                                    '30'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activeGyroX'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            {this.state
                                                                .humanoidPortData[
                                                                '28'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    X :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huGyroX
                                                                    }
                                                                </p>
                                                            ) : null}
                                                            {this.state
                                                                .humanoidPortData[
                                                                '29'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Y :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huGyroY
                                                                    }
                                                                </p>
                                                            ) : null}

                                                            {this.state
                                                                .humanoidPortData[
                                                                '30'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Z :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .huGyroZ
                                                                    }
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {this.state.humanoidPortData[
                                                    '23'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                marginLeft: '',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activeBattery'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    marginRight:
                                                                        '1rem',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <p
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    margin: '0',
                                                                    padding:
                                                                        '0',
                                                                    fontSize:
                                                                        '0.8rem',

                                                                    margin: 'auto',
                                                                    height: '100%',

                                                                    paddingTop:
                                                                        '30%',
                                                                    paddingRight:
                                                                        '35%',
                                                                }}
                                                                className="text-center"
                                                            >
                                                                B:{'  '}
                                                                {
                                                                    this.state
                                                                        .huBattery
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {this.state.humanoidPortData[
                                                    '24'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                marginLeft: '',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activeUltrasonic'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    marginRight:
                                                                        '1rem',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <p
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    margin: '0',
                                                                    padding:
                                                                        '0',
                                                                    fontSize:
                                                                        '0.8rem',

                                                                    margin: 'auto',
                                                                    height: '100%',

                                                                    paddingTop:
                                                                        '30%',
                                                                    paddingRight:
                                                                        '35%',
                                                                }}
                                                                className="text-center"
                                                            >
                                                                U :{' '}
                                                                {
                                                                    this.state
                                                                        .huUltrasonic
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                </div>
                            </>
                        )}
                        {connectedDevice === 'Klaw' && (
                            <>
                                <div className="Read_button">
                                    <img
                                        id="readButton"
                                        src={
                                            this.state.isReading
                                                ? renderPrgImage('readPCActive')
                                                : renderPrgImage(
                                                      'readPCInActive'
                                                  )
                                        }
                                        style={{
                                            position: 'absolute',
                                            cursor: 'pointer',
                                            top: '50%',
                                            right: '2%',
                                            transform: 'translateY(-50%)',
                                            height: '60px',
                                            width: '60px',
                                            zIndex: '10',
                                        }}
                                        onClick={async (e) => {
                                            if (
                                                !this.state.isReading &&
                                                this.props.webSerial.isConnected
                                            ) {
                                                this.buttonClicked()
                                            } else {
                                                await this.setState({
                                                    isReading: false,
                                                })
                                                this.setState({
                                                    isPopup: false,
                                                })
                                                if (
                                                    sessionStorage.getItem(
                                                        'readSetIntervalId'
                                                    ) !== null ||
                                                    undefined
                                                ) {
                                                    // this.handleReadByte_v1()
                                                }
                                            }
                                        }}
                                    />
                                    {!this.state.isReading ? (
                                        <span className="tooltiptext">
                                            {' '}
                                            <p>Read sensor value</p>
                                        </span>
                                    ) : null}
                                    {/* {this.state.isReading &&
                                        !this.props.webSerial.isConnected && (
                                            <>{this.hexapodPARead()}</>
                                        )} */}
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '37vh',
                                        left: '83vw',
                                        width: '160px',
                                        // height: "300px",

                                        display: 'flex',
                                        flexDirection: 'column',

                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                    // className="bg-primary"
                                >
                                    {this.state.isReading &&
                                        this.props.webSerial.isConnected && (
                                            <>
                                                {/*----------pot1-------------*/}
                                                {this.state.humanoidPortData[
                                                    '0'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '200px',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activePot1'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>

                                                        <div className="col-6">
                                                            {/* joystick1 */}
                                                            {this.state
                                                                .humanoidPortData[
                                                                '0'
                                                            ].isPortSelected ? (
                                                                <>
                                                                    <p
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            fontSize:
                                                                                '0.8rem',
                                                                        }}
                                                                    >
                                                                        Pot1 :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .pot1
                                                                        }
                                                                    </p>
                                                                    {/* <p
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            fontSize:
                                                                                '0.8rem',
                                                                        }}
                                                                    >
                                                                        Y :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .joystick1_Y
                                                                        }
                                                                    </p> */}
                                                                </>
                                                            ) : null}

                                                            {/* switch1 */}
                                                            {/* {this.state
                                                                .humanoidPortData[
                                                                '3'
                                                            ].isPortSelected ? (
                                                                <>
                                                                    <p
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            fontSize:
                                                                                '0.8rem',
                                                                        }}
                                                                    >
                                                                        S :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .switch1
                                                                        }
                                                                    </p>
                                                                </>
                                                            ) : null} */}
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {/*----------pot2-------------*/}
                                                {/* ////////////////////////// */}
                                                {this.state.humanoidPortData[
                                                    '1'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '200px',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activePot2'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>

                                                        <div className="col-6">
                                                            {/* joystick2 */}
                                                            {this.state
                                                                .humanoidPortData[
                                                                '1'
                                                            ].isPortSelected ? (
                                                                <>
                                                                    <p
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            fontSize:
                                                                                '0.8rem',
                                                                        }}
                                                                    >
                                                                        Pot2 :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .pot2
                                                                        }
                                                                    </p>
                                                                    {/* <p
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            fontSize:
                                                                                '0.8rem',
                                                                        }}
                                                                    >
                                                                        Y :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .joystick2_Y
                                                                        }
                                                                    </p> */}
                                                                </>
                                                            ) : null}

                                                            {/* switch2 */}
                                                            {/* {this.state
                                                                .humanoidPortData[
                                                                '4'
                                                            ].isPortSelected ? (
                                                                <>
                                                                    <p
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            fontSize:
                                                                                '0.8rem',
                                                                        }}
                                                                    >
                                                                        S :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .switch2
                                                                        }
                                                                    </p>
                                                                </>
                                                            ) : null} */}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {/*----------pot3-------------*/}
                                                {/* ////////////////////////// */}
                                                {this.state.humanoidPortData[
                                                    '2'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '200px',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activePot3'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            {/* joystick3 */}
                                                            {this.state
                                                                .humanoidPortData[
                                                                '2'
                                                            ].isPortSelected ? (
                                                                <>
                                                                    <p
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            fontSize:
                                                                                '0.8rem',
                                                                        }}
                                                                    >
                                                                        Pot3 :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .pot3
                                                                        }
                                                                    </p>
                                                                    {/* <p
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            fontSize:
                                                                                '0.8rem',
                                                                        }}
                                                                    >
                                                                        Y :{' '}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .joystick3_Y
                                                                        }
                                                                    </p> */}
                                                                </>
                                                            ) : null}
                                                            {/* switch3 */}
                                                            {/* {this.state
                                                                .humanoidPortData[
                                                                '5'
                                                            ].isPortSelected ? (
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    S :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .switch3
                                                                    }
                                                                </p>
                                                            ) : null} */}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {/*----------pot4-------------*/}
                                                {this.state.humanoidPortData[
                                                    '3'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '200px',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activePot4'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <>
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Pot4 :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .pot4
                                                                    }
                                                                </p>
                                                            </>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {/*----------pot5-------------*/}
                                                {this.state.humanoidPortData[
                                                    '4'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '200px',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activePot5'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <>
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Pot5 :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .pot5
                                                                    }
                                                                </p>
                                                            </>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {/*----------pot6-------------*/}
                                                {this.state.humanoidPortData[
                                                    '5'
                                                ].isPortSelected ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '200px',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-6"
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                src={renderImage(
                                                                    'activePot6'
                                                                )}
                                                                style={{
                                                                    width: '40px',
                                                                    height: '40px',
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <>
                                                                <p
                                                                    style={{
                                                                        margin: '0',
                                                                        padding:
                                                                            '0',
                                                                        fontSize:
                                                                            '0.8rem',
                                                                    }}
                                                                >
                                                                    Pot6 :{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .pot6
                                                                    }
                                                                </p>
                                                            </>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                </div>
                            </>
                        )}
                        <div id="assemblyShot"></div>
                    </div>
                </div>

                {/* BOTTOM BACK,NEXT BTN and discription*/}
                <div
                    className="SelectScreenBottom"
                    style={{ width: '80%', marginLeft: '8%', zIndex: '100' }}
                >
                    {this.state.showPopup ? (
                        <div className="snackbar">
                            "Read sensor" paramaters are supported only in new
                            Firmware. Please update the firmware -{' '}
                            <a
                                target="_blank"
                                href="https://firmware.plode.org/"
                            >
                                Update Here
                            </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="bottom-child" style={{ zIndex: '1000' }}>
                        <div className="decription" style={{ left: '50%' }}>
                            <p>Drag and drop components to make connection </p>
                        </div>
                        {/* <img
                            className="iconBtnSize imgNextBtn"
                            src={renderPrgImage('nextBtn')}
                            onMouseDown={() => {
                                this.setState({ readbytes: false })
                                this.setState({ isPcActive: false })
                            }}
                            onClick={this.check}
                            style={{ top: '50%' }}
                        /> */}
                    </div>
                </div>
            </div>
        )
    }
}

// export default Assembly;

const mapStateToProps = (state) => {
    return state
}

//   return {
//     webserialPort: state.webSerial,
//   };
// };
const mapDispatchToProps = (dispatch) => {
    return {
        assemblyComponent: (data) => {
            dispatch({ type: 'ASSEMBLY_SELECTION', payload: data })
        },
        logicComponent: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
        PortConnections: (data) => {
            dispatch({ type: 'PORT_CONNECTION', payload: data })
        },
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
        updateInternalAccessories: (data) => {
            console.log('changing Internal')
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
    }
}

Assembly = withRouter(connect(mapStateToProps, mapDispatchToProps)(Assembly))
Assembly = withRouter(
    DragDropContext(
        sessionStorage.getItem('isTouchEnabled') == 'true'
            ? TouchBackend
            : HTML5Backend
    )(Assembly)
)
// Sidebar = DragSource('items', itemSource, collect)(Sidebar)

export default Assembly
// export default connect(mapStateToProp)(Assembly);
