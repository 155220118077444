/**
 * This module  is a function that returns the port over which the component is
 * currently, if that port is already not oocupied and of an allowable type or
 * returns false otherwise
 * @module components/assembly/IsOverPort
 */

import ItemTypes from './ItemTypes'
import ImageSizes from './ImageSizes'
import Ports from './PortData'
import PortTypes from './PortTypes'

import AllowedPortTypes from './AllowedPortTypes'
import { PORTDATA } from '../Humanoid/defaultData'

var IsOverPort = function (
    component,
    bibox,
    componentConnected,
    allowedPortTypeData,
    PortConnections,
    shield,
    armShield
) {
    var portName = false
    var LeftPort, TopPort
    var LeftG, TopG

    // TOUCHPAD
    let internalAccessoriesData = JSON.parse(
        sessionStorage.getItem('concept')
    ).internalaccessories
    // let version = sessionStorage.getItem('deviceVersion') || '0.0.0'
    let deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    let connectedDevice = sessionStorage.getItem('connectedDevice') || '0.0.0'
    let newHumanoidPortData = JSON.parse(
        sessionStorage.getItem('humanoidPortData')
    )
    let PCshield = sessionStorage.getItem('shield') || false
    let PCarmShield = sessionStorage.getItem('armShield') || false
    let peeCeeATR = sessionStorage.getItem('peeCeeATR') || false

    // STEPPER
    let isStepperConnected = () => {
        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                .STPM != null
        ) {
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .STPM.type == 'stepper_motor'
            ) {
                return 'STPM'
            }
        }
    }

    // SERVO

    let isServoMotorConnected_BD = () => {
        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.B1 !=
            null
        ) {
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.B1.type
                )
            ) {
                return 'B1'
            }
        }

        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.B2 !=
            null
        ) {
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.B2.type
                )
            ) {
                return 'B2'
            }
        }

        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.D2 !=
            null
        ) {
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.D2.type
                )
            ) {
                return 'D2'
            }
        }
        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.D1 !=
            null
        ) {
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.D1.type
                )
            ) {
                return 'D1'
            }
        }
    }

    let isServoMotorConnected_AC = () => {
        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.A1 !=
            null
        ) {
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.A1.type
                )
            ) {
                return 'A1'
            }
        }

        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.A2 !=
            null
        ) {
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.A2.type
                )
            ) {
                return 'A2'
            }
        }

        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.C2 !=
            null
        ) {
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.C2.type
                )
            ) {
                return 'C2'
            }
        }
        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.C1 !=
            null
        ) {
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.C1.type
                )
            ) {
                return 'C1'
            }
        }
    }

    // GEARED
    let isGearedConnected_BD = () => {
        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.B1 !=
            null
        ) {
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .B1.type == 'geared_motor' ||
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .B1.type == 'mini_geared_motor' ||
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .B1.type == 'dc_motor'
            ) {
                return 'B1'
            }
        }

        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.D1 !=
            null
        ) {
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .D1.type == 'geared_motor' ||
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .D1.type == 'mini_geared_motor' ||
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .D1.type == 'dc_motor'
            ) {
                return 'D1'
            }
        }
    }

    let isGearedConnected_AC = () => {
        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.A1 !=
            null
        ) {
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .A1.type == 'geared_motor' ||
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .A1.type == 'mini_geared_motor' ||
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .A1.type == 'dc_motor'
            ) {
                return 'A1'
            }
        }

        if (
            JSON.parse(sessionStorage.getItem('assembly')).PortConnections.C1 !=
            null
        ) {
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .C1.type == 'geared_motor' ||
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .C1.type == 'mini_geared_motor' ||
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .C1.type == 'dc_motor'
            ) {
                return 'C1'
            }
        }
    }

    /////////////////////// PLAYC-39 /////////////////////////////
    // Enbaling certain ports on playShield
    // fix for auto connect on hover on disabled playshield ports

    const canItConnectOnPlayShield = (port, sensor) => {
        const PLAYSHIELD_SENSORS_COMPATIBILITY = {
            A1: [
                'led',
                'led_1c',
                'tact_switch',
                'dip_switch',
                'dual_switch',
                'touch_sensor',
                'metal_detector',
                'linear_pot',
                'extender',
                'pot',
                'light_sensor',
                'distance_sensor',
                'temperature_sensor',
                'humidity',
                'rain_sensor',
                'gas',
                'laser',
            ],
            A2: [
                'led',
                'led_1c',
                'tact_switch',
                'dip_switch',
                'dual_switch',
                'touch_sensor',
                'metal_detector',
                'linear_pot',
                'extender',
                'pot',
                'light_sensor',
                'distance_sensor',
                'temperature_sensor',
                'humidity',
                'rain_sensor',
                'gas',
            ],
            B1: [
                'led',
                'led_1c',
                'tact_switch',
                'dip_switch',
                'dual_switch',
                'touch_sensor',
                'metal_detector',
                'linear_pot',
                'extender',
                'pot',
                'light_sensor',
                'distance_sensor',
                'temperature_sensor',
                'humidity',
                'rain_sensor',
                'gas',
                'laser',
                'RGB',
            ],
            B2: [
                'led',
                'led_1c',
                'tact_switch',
                'dip_switch',
                'dual_switch',
                'touch_sensor',
                'metal_detector',
                'linear_pot',
                'extender',
                'pot',
                'light_sensor',
                'distance_sensor',
                'temperature_sensor',
                'humidity',
                'rain_sensor',
                'gas',
            ],
            C1: ['servo_motor', 'servo_motor_270', 'servo_motor_360'],
            C2: ['servo_motor', 'servo_motor_270', 'servo_motor_360'],
            D1: ['servo_motor', 'servo_motor_270', 'servo_motor_360'],
            D2: ['servo_motor', 'servo_motor_270', 'servo_motor_360'],
        }
        return PLAYSHIELD_SENSORS_COMPATIBILITY[port].includes(sensor)
    }

    //////////////////////////////////////////////////////////////

    if (localStorage.getItem('programMode') == 'learn') {
        var PortConnectionsTutor = JSON.parse(
            sessionStorage.getItem('tutorialPort')
        )
        var AllowedPortTypesTutor = {}

        Object.entries(PortConnectionsTutor).map(([key, value]) => {
            if (AllowedPortTypesTutor[value]) {
                AllowedPortTypesTutor[value].push(key)
            } else {
                AllowedPortTypesTutor[value] = [key]
            }
        })
    } else {
        // this var have a PORTS (A1,A2.............) on which the SLIDE-COMPONENTS WILL BE ACTIVETED on the DEVICE
        var AllowedPortTypesTutor = allowedPortTypeData
        // if (PCshield != false && deviceVersion) {
        //     AllowedPortTypesTutor =
        //         AllowedPortTypes[`SHIELD${deviceVersion.slice(0, 1)}`]
        // } else if (peeCeeATR != false && deviceVersion) {
        //     AllowedPortTypesTutor =
        //         AllowedPortTypes[`PEECEEATR${deviceVersion.slice(0, 1)}`]
        // } else if (PCarmShield && deviceVersion) {
        //     AllowedPortTypesTutor =
        //         AllowedPortTypes[`ARMSHIELD${deviceVersion.slice(0, 1)}`]
        // } else {
        //     console.log(
        //         'checking allowed types',
        //         `${connectedDevice.toUpperCase()}${deviceVersion.slice(0, 1)}`
        //     )
        //     AllowedPortTypesTutor =
        //         AllowedPortTypes[
        //             `${connectedDevice.toUpperCase()}${deviceVersion.slice(
        //                 0,
        //                 1
        //             )}`
        //         ]
        // }
    }

    if (AllowedPortTypesTutor[component.type]) {
        // led :[a,b,c,d.....]
        AllowedPortTypesTutor[component.type].some((portType) => {
            // A,B,C,D......
            return PortTypes[`${connectedDevice.toUpperCase()}`][
                portType
            ]?.ports.some((port) => {
                if (PortConnections[port]) {
                    return false
                }

                if (
                    sessionStorage.getItem('connectedDevice') == 'Humanoid' &&
                    ['1'].includes(deviceVersion)
                ) {
                    if (
                        port === 'B' &&
                        newHumanoidPortData[40].isPortSelected == true
                    ) {
                        return false
                    } else if (
                        port === 'D' &&
                        sessionStorage.getItem('dragingItem') != 'OLED' &&
                        (newHumanoidPortData[23].isPortSelected == true ||
                            newHumanoidPortData[24].isPortSelected == true ||
                            newHumanoidPortData[25].isPortSelected == true ||
                            PortConnections['D1'] != null ||
                            PortConnections['D2'] != null)
                    ) {
                        return false
                    }
                }

                if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                    if (
                        (port === 'M1' || port === 'M2') &&
                        (internalAccessoriesData.isSmileOne === true ||
                            internalAccessoriesData.isSmileTwo === true)
                    ) {
                        return false
                    } else if (
                        (port === 'M3' || port === 'M4') &&
                        (internalAccessoriesData.isSmileThree === true ||
                            internalAccessoriesData.isSmileFour === true)
                    ) {
                        return false
                    }

                    if (
                        port == 'D' &&
                        sessionStorage.getItem('dragingItem') != 'OLED' &&
                        (internalAccessoriesData.isFour_in_one_sensor ===
                            true ||
                            internalAccessoriesData.isAccelerometer === true)
                    ) {
                        return false
                    }
                } else {
                    // if([].includes())

                    if (
                        port == 'A' &&
                        (internalAccessoriesData.isTouchZero == true ||
                            internalAccessoriesData.isTouchZeroOutput == true ||
                            PortConnections['A1'] != null ||
                            PortConnections['A2'] != null)
                    ) {
                        return false
                    } else if (
                        port == 'B' &&
                        (internalAccessoriesData.isTouchOne == true ||
                            internalAccessoriesData.isTouchOneOutput == true ||
                            PortConnections['B1'] != null ||
                            PortConnections['B2'] != null)
                    ) {
                        return false
                    } else if (
                        port == 'C' &&
                        (internalAccessoriesData.isTouchTwo == true ||
                            internalAccessoriesData.isTouchTwoOutput == true ||
                            PortConnections['C1'] != null ||
                            PortConnections['C2'] != null)
                    ) {
                        return false
                    } else if (
                        port == 'D' &&
                        sessionStorage.getItem('dragingItem') != 'OLED' &&
                        (internalAccessoriesData.isFour_in_one_sensor == true ||
                            PortConnections['D1'] != null ||
                            PortConnections['D2'] != null)
                    ) {
                        return false
                    }
                }

                ////////////// HOTFIX : motors can't connect if the port is already  occupied /////////////
                const draggingItem = sessionStorage.getItem('dragingItem')
                if (
                    draggingItem === 'mini_geared_motor' ||
                    draggingItem === 'geared_motor' ||
                    draggingItem === 'dc_motor'
                ) {
                    if (port == 'A1' && PortConnections['A2'] != null)
                        return false
                    if (port == 'B1' && PortConnections['B2'] != null)
                        return false
                    if (
                        port == 'C1' &&
                        PortConnections['C2'] != null &&
                        !['Toki'].includes(connectedDevice)
                    )
                        return false
                    if (port == 'D1' && PortConnections['D2'] != null)
                        return false
                    if (port == 'E1' && PortConnections['E2'] != null)
                        return false
                    if (connectedDevice !== 'Klaw') {
                        //in klaw dc motor are connect in separate port

                        if (port == 'F1' && PortConnections['F2'] != null)
                            return false
                    }
                }

                if (
                    draggingItem === 'OLED' &&
                    (PortConnections['D1'] != null ||
                        PortConnections['D2'] != null)
                ) {
                    return false
                }
                /////////////////////////////////////////////////////////////////////////////////////////

                const internalAccessories = JSON.parse(
                    sessionStorage.getItem('concept')
                ).internalaccessories

                const isTouch0 =
                    internalAccessories.isTouchZero ||
                    internalAccessories.isTouchZeroOutput ||
                    false
                const isTouch1 =
                    internalAccessories.isTouchOne ||
                    internalAccessories.isTouchOneOutput ||
                    false
                const isTouch2 =
                    internalAccessories.isTouchTwo ||
                    internalAccessories.isTouchTwoOutput ||
                    false

                const isA1 = PortConnections['A1'] || false
                const isB1 = PortConnections['B1'] || false
                const isC1 = PortConnections['C1'] || false
                const isA2 = PortConnections['A2'] || false
                const isC2 = PortConnections['C2'] || false
                const isF1 = PortConnections['F1'] || false
                const isF2 = PortConnections['F2'] || false

                if (!sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                    if ((port === 'A1' || port === 'P0') && (isTouch0 || isA1))
                        return false
                    if ((port === 'B1' || port === 'P1') && (isTouch1 || isB1))
                        return false
                    if ((port === 'C1' || port === 'P2') && (isTouch2 || isC1))
                        return false
                }
                if (!sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                    if (
                        internalAccessoriesData.isFour_in_one_sensor == true &&
                        (port === 'D1' || port === 'D2')
                    )
                        return false
                }
                // if (sessionStorage.getItem('deviceVersion') == 1) {
                //     if (
                //         (newHumanoidPortData[23].isPortSelected == true ||
                //             newHumanoidPortData[24].isPortSelected == true ||
                //             newHumanoidPortData[25].isPortSelected == true) &&
                //         (port === 'D1' || port === 'D2')
                //     )
                //         return false
                // }

                if (
                    sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
                    sessionStorage.getItem('shield') === 'true'
                ) {
                    const components = JSON.parse(
                        sessionStorage.getItem('assembly')
                    ).workspace.components
                    if (
                        [
                            'servo_motor_360',
                            'servo_motor_270',
                            'servo_motor',
                            'dc_motor',
                            'geared_motor',
                            'mini_geared_motor',
                        ].includes(sessionStorage.getItem('dragingItem'))
                    ) {
                        const cal_length = (motorlist) => {
                            let sum = 0
                            motorlist.map((motor) => {
                                if (motor.connectedTo !== undefined) {
                                    sum = sum + 1
                                }
                            })
                            return sum
                        }
                        const servo_motor =
                            components.servo_motor === undefined
                                ? 0
                                : cal_length(components.servo_motor)
                        const servo_motor_270 =
                            components.servo_motor_270 === undefined
                                ? 0
                                : cal_length(components.servo_motor_270)
                        const servo_motor_360 =
                            components.servo_motor_360 === undefined
                                ? 0
                                : cal_length(components.servo_motor_360)
                        const dc_motor =
                            components.dc_motor === undefined
                                ? 0
                                : cal_length(components.dc_motor)
                        const geared_motor =
                            components.geared_motor === undefined
                                ? 0
                                : cal_length(components.geared_motor)
                        const mini_geared_motor =
                            components.mini_geared_motor === undefined
                                ? 0
                                : cal_length(components.mini_geared_motor)
                        if (
                            (components.dc_motor === undefined
                                ? true
                                : components.dc_motor.length == 0) &&
                            (components.geared_motor === undefined
                                ? true
                                : components.geared_motor.length == 0) &&
                            (components.mini_geared_motor === undefined
                                ? true
                                : components.mini_geared_motor.length == 0) &&
                            (components.servo_motor !== undefined ||
                                components.servo_motor_270 !== undefined ||
                                components.servo_motor_360 !== undefined)
                        ) {
                            if (
                                !(
                                    servo_motor +
                                        servo_motor_270 +
                                        servo_motor_360 <
                                    14
                                )
                            ) {
                                return false
                            }
                        } else if (
                            ((components.dc_motor === undefined
                                ? false
                                : components.dc_motor.length != 0) ||
                                (components.geared_motor === undefined
                                    ? false
                                    : components.geared_motor.length != 0) ||
                                (components.mini_geared_motor === undefined
                                    ? false
                                    : components.mini_geared_motor.length !=
                                      0)) &&
                            ((components.servo_motor === undefined
                                ? false
                                : components.servo_motor.length != 0) ||
                                (components.servo_motor_360 === undefined
                                    ? false
                                    : components.servo_motor_360.length != 0) ||
                                (components.servo_motor_270 === undefined
                                    ? false
                                    : components.servo_motor_270.length != 0))
                        ) {
                            const noMotors =
                                (dc_motor + mini_geared_motor + geared_motor) *
                                    2 +
                                servo_motor +
                                servo_motor_270 +
                                servo_motor_360
                            if (noMotors == 12) {
                                return false
                            } else if (noMotors == 11) {
                                if (
                                    noMotors % 2 == 1 &&
                                    [
                                        'dc_motor',
                                        'geared_motor',
                                        'mini_geared_motor',
                                    ].includes(
                                        sessionStorage.getItem('dragingItem')
                                    )
                                ) {
                                    return false
                                }
                            }
                        }
                    }
                }
                if (!sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                    if ((port === 'A1' || port === 'S1') && isA1) return false
                    if ((port === 'C1' || port === 'S2') && isC1) return false
                }

                if (
                    port === 'M1' &&
                    (internalAccessories.isSmile1 ||
                        internalAccessories.isSmile2)
                )
                    return false
                if (
                    port === 'M2' &&
                    (internalAccessories.isSmile3 ||
                        internalAccessories.isSmile4)
                )
                    return false

                ////////////// FIXED AS MENTIONED IN PLAYC-277 //////////////
                // if (port === 'M3' && (isA2 || isF2)) return false
                // if (port === 'M4' && (isC2 || isF1)) return false

                ///////////////////////////////// BUGFIX PLAYC-18 ///////////////////////////////////////
                // Disabling ports A1, A2, B1, B2 when A or B is already occupied
                const connection = JSON.parse(
                    sessionStorage.getItem('assembly')
                ).PortConnections

                if (
                    (port == 'A1' || port == 'A2') &&
                    connection['A'] != null &&
                    connection['A'].type !== 'dual_splitter' &&
                    !['Toki'].includes(connectedDevice)
                )
                    return false
                else if (
                    (port == 'B1' || port == 'B2') &&
                    connection['B'] != null &&
                    connection['B'].type !== 'dual_splitter' &&
                    !['Toki'].includes(connectedDevice)
                )
                    return false

                if (
                    sessionStorage.getItem('connectedDevice') === 'Klaw' &&
                    draggingItem === 'serial_servo_motor'
                ) {
                    if (
                        connection.serial_servo1 !== null ||
                        connection.serial_servo2 !== null
                    )
                        return false
                }

                /////////////////////////////////////////////////////////////////////////////////////////

                /////////////////////// PLAYC-39 ////////////////////////
                ///// Disabling certain ports when certain
                ///// internal accessories are selected
                const is4in1SensorConnected =
                    JSON.parse(sessionStorage.getItem('concept'))
                        .internalaccessories.isFour_in_one_sensor || false
                const isSmile1 =
                    JSON.parse(sessionStorage.getItem('concept'))
                        .internalaccessories.isSmileOne || false
                const isSmile2 =
                    JSON.parse(sessionStorage.getItem('concept'))
                        .internalaccessories.isSmileTwo || false
                const isSmile3 =
                    JSON.parse(sessionStorage.getItem('concept'))
                        .internalaccessories.isSmileThree || false
                const isSmile4 =
                    JSON.parse(sessionStorage.getItem('concept'))
                        .internalaccessories.isSmileFour || false

                if (shield && port === 'M1' && (isSmile1 || isSmile2))
                    return false
                if (shield && port === 'M3' && (isSmile3 || isSmile4))
                    return false

                if (armShield && port === 'MOTOR1' && (isSmile2 || isSmile3))
                    return false
                if (armShield && port === 'F') return false
                /////////////////////////////////////////////////////////

                if (port === 'SS' && (isSmile1 || isSmile4)) return false

                if (
                    (port == 'A1' || port == 'A2') &&
                    !['Toki'].includes(connectedDevice)
                ) {
                    Object.keys(componentConnected).map((dual_splitter) => {
                        if (dual_splitter == 'dual_splitter') {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    if (componentConnected.connectedTo == 'A') {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top

                                        l = Ports(port)[0] + LeftPort
                                        t = Ports(port)[1] + TopPort
                                    }
                                    if (
                                        componentConnected.connectedTo == 'A' &&
                                        (shield || armShield)
                                    ) {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top
                                        if (port == 'A1') {
                                            l = Ports(port)[0] + LeftPort - 87
                                            t = Ports(port)[1] + TopPort - 252
                                        }
                                        if (port == 'A2') {
                                            l = Ports(port)[0] + LeftPort - 32
                                            t = Ports(port)[1] + TopPort - 285
                                        }
                                    }
                                }
                            )
                        }

                        if (
                            (connection['A'] == null ||
                                connection['A'].type !== 'dual_splitter') &&
                            dual_splitter == 'play_shield' &&
                            canItConnectOnPlayShield(port, component.type)
                        ) {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    LeftPort = componentConnected.left
                                    TopPort = componentConnected.top

                                    if (port == 'A1') {
                                        l = Ports(port)[0] + bibox.left
                                        t = Ports(port)[1] + bibox.top
                                    }
                                    if (port == 'A2') {
                                        l = Ports(port)[0] + bibox.left
                                        t = Ports(port)[1] + bibox.top
                                    }
                                }
                            )
                        }

                        let adaasd = JSON.parse(
                            sessionStorage.getItem('assembly')
                        ).PortConnections.A

                        if (
                            dual_splitter == 'pc_motor_driver' &&
                            JSON.parse(sessionStorage.getItem('assembly'))
                                .PortConnections.A !== null
                        ) {
                            if (
                                JSON.parse(sessionStorage.getItem('assembly'))
                                    .PortConnections.A.type == 'pc_motor_driver'
                            ) {
                                componentConnected[dual_splitter].map(
                                    (componentConnected, index) => {
                                        if (
                                            componentConnected.connectedTo ==
                                                'A' ||
                                            componentConnected.connectedTo ==
                                                'C'
                                        ) {
                                            LeftPort = componentConnected.left
                                            TopPort = componentConnected.top

                                            switch (component.type) {
                                                case 'mini_geared_motor':
                                                case 'dc_motor':
                                                case 'geared_motor': {
                                                    let DataServoMotorConnected_AC =
                                                        isServoMotorConnected_AC()

                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataServoMotorConnected_AC ==
                                                            'A1' ||
                                                        DataServoMotorConnected_AC ==
                                                            'A2' ||
                                                        DataServoMotorConnected_AC ==
                                                            'C1' ||
                                                        DataServoMotorConnected_AC ==
                                                            'C2' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        // console.log("BAD");
                                                        l =
                                                            Ports(port)[0] +
                                                            LeftPort -
                                                            70
                                                        t =
                                                            Ports(port)[1] +
                                                            TopPort +
                                                            30
                                                    }

                                                    break
                                                }

                                                case 'servo_motor': {
                                                    let DataGearedMotorConnected_AC =
                                                        isGearedConnected_AC()
                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataGearedMotorConnected_AC ==
                                                            'A1' ||
                                                        DataGearedMotorConnected_AC ==
                                                            'B1' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        switch (port) {
                                                            case 'A1': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort -
                                                                    30
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }

                                                            case 'A2': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort -
                                                                    10
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }
                                                        }
                                                    }

                                                    break
                                                }
                                            }
                                        }
                                    }
                                )
                            }
                        }
                        /** */
                    })
                } else if (port == 'I1' || port == 'I2') {
                    Object.keys(componentConnected).map((dual_splitter) => {
                        if (dual_splitter == 'dual_splitter') {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    if (componentConnected.connectedTo == 'I') {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top

                                        l = Ports(port)[0] + LeftPort
                                        t = Ports(port)[1] + TopPort
                                    }
                                }
                            )
                        }
                    })
                } else if (port == 'H1' || port == 'H2') {
                    Object.keys(componentConnected).map((dual_splitter) => {
                        if (dual_splitter == 'dual_splitter') {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    if (componentConnected.connectedTo == 'H') {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top

                                        l = Ports(port)[0] + LeftPort
                                        t = Ports(port)[1] + TopPort
                                    }
                                }
                            )
                        }
                    })
                } else if (port == 'E1' || port == 'E2') {
                    Object.keys(componentConnected).map((dual_splitter) => {
                        if (dual_splitter == 'dual_splitter') {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    if (componentConnected.connectedTo == 'E') {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top

                                        l = Ports(port)[0] + LeftPort
                                        t = Ports(port)[1] + TopPort - 70
                                    }
                                }
                            )
                        }
                    })
                } else if (port == 'D1' || port == 'D2') {
                    /////////////////////// PLAYC-39 ////////////////////////
                    ///// Disabling certain ports when certain
                    ///// internal accessories are selected

                    const is4in1SensorConnected =
                        JSON.parse(sessionStorage.getItem('concept'))
                            .internalaccessories.isFour_in_one_sensor || false

                    if (
                        !sessionStorage
                            .getItem('deviceVersion')
                            ?.startsWith('1') &&
                        is4in1SensorConnected
                    )
                        return false
                    /////////////////////////////////////////////////////////

                    Object.keys(componentConnected).map((key) => {
                        if (key == 'dual_splitter') {
                            componentConnected[key].map(
                                (componentConnected, index) => {
                                    if (
                                        componentConnected.connectedTo == 'D' &&
                                        (shield || armShield)
                                    ) {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top
                                        if (port == 'D1') {
                                            l = Ports(port)[0] + LeftPort - 230
                                            t = Ports(port)[1] + TopPort - 319
                                        }
                                        if (port == 'D2') {
                                            l = Ports(port)[0] + LeftPort - 139
                                            t = Ports(port)[1] + TopPort - 315
                                        }
                                    } else if (
                                        componentConnected.connectedTo == 'D'
                                    ) {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top

                                        l = Ports(port)[0] + LeftPort
                                        t = Ports(port)[1] + TopPort
                                    }
                                }
                            )
                        }

                        if (
                            key == 'play_shield' &&
                            canItConnectOnPlayShield(port, component.type)
                        ) {
                            componentConnected[key].map(
                                (componentConnected, index) => {
                                    LeftPort = componentConnected.left
                                    TopPort = componentConnected.top
                                    if (PortConnections.D == null) {
                                        l = Ports(port)[0] + bibox.left
                                        t = Ports(port)[1] + bibox.top
                                    }
                                }
                            )
                        }

                        if (
                            key == 'pc_motor_driver' &&
                            JSON.parse(sessionStorage.getItem('assembly'))
                                .PortConnections.D !== null
                        ) {
                            if (
                                JSON.parse(sessionStorage.getItem('assembly'))
                                    .PortConnections.D.type == 'pc_motor_driver'
                            ) {
                                componentConnected[key].map(
                                    (componentConnected, index) => {
                                        if (
                                            componentConnected.connectedTo ==
                                                'B' ||
                                            componentConnected.connectedTo ==
                                                'D'
                                        ) {
                                            LeftPort = componentConnected.left
                                            TopPort = componentConnected.top

                                            switch (component.type) {
                                                case 'mini_geared_motor':
                                                case 'dc_motor':
                                                case 'geared_motor': {
                                                    let DataServoMotorConnected_BD =
                                                        isServoMotorConnected_BD()
                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataServoMotorConnected_BD ==
                                                            'B1' ||
                                                        DataServoMotorConnected_BD ==
                                                            'B2' ||
                                                        DataServoMotorConnected_BD ==
                                                            'D1' ||
                                                        DataServoMotorConnected_BD ==
                                                            'D2' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        // console.log("BAD");
                                                        l =
                                                            Ports(port)[0] +
                                                            LeftPort +
                                                            125
                                                        t =
                                                            Ports(port)[1] +
                                                            TopPort +
                                                            30
                                                    }

                                                    break
                                                }

                                                case 'servo_motor': {
                                                    let DataGearedMotorConnected_BD =
                                                        isGearedConnected_BD()

                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataGearedMotorConnected_BD ==
                                                            'B1' ||
                                                        DataGearedMotorConnected_BD ==
                                                            'D1' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        switch (port) {
                                                            case 'D1': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort +
                                                                    60
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }

                                                            case 'D2': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort +
                                                                    80
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }
                                                        }
                                                    }

                                                    break
                                                }
                                            }
                                        }
                                    }
                                )
                            }
                        }
                    })
                } else if (
                    (port == 'F1' || port == 'F2') &&
                    sessionStorage.getItem('connectedDevice') !== 'Klaw'
                ) {
                    // if (sessionStorage.getItem('connectedDevice') === 'Klaw') {
                    //     l = Ports(port)[0] + bibox.left
                    //     t = Ports(port)[1] + bibox.top
                    // }
                    Object.keys(componentConnected).map((dual_splitter) => {
                        if (dual_splitter == 'dual_splitter') {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    if (componentConnected.connectedTo == 'F') {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top

                                        l = Ports(port)[0] + LeftPort
                                        t = Ports(port)[1] + TopPort
                                    }
                                    if (
                                        componentConnected.connectedTo == 'F' &&
                                        shield == true
                                    ) {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top
                                        if (port == 'F1') {
                                            l = Ports(port)[0] + LeftPort + 5
                                            t = Ports(port)[1] + TopPort - 74
                                        }
                                        if (port == 'F2') {
                                            l = Ports(port)[0] + LeftPort - 5
                                            t = Ports(port)[1] + TopPort - 68
                                        }
                                    }
                                }
                            )
                        }
                    })
                } else if (
                    (port == 'C1' || port == 'C2') &&
                    !['Toki'].includes(connectedDevice)
                ) {
                    Object.keys(componentConnected).map((dual_splitter) => {
                        if (dual_splitter == 'dual_splitter') {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    if (
                                        componentConnected.connectedTo == 'C' &&
                                        (shield || armShield)
                                    ) {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top
                                        if (port == 'C1') {
                                            l = Ports(port)[0] + LeftPort - 125
                                            t = Ports(port)[1] + TopPort - 320
                                        }
                                        if (port == 'C2') {
                                            l = Ports(port)[0] + LeftPort - 105
                                            t = Ports(port)[1] + TopPort - 315
                                        }
                                    } else if (
                                        componentConnected.connectedTo == 'C'
                                    ) {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top
                                        if (port == 'C1') {
                                            l = Ports(port)[0] + LeftPort
                                            t = Ports(port)[1] + TopPort
                                        }
                                        if (port == 'C2') {
                                            l = Ports(port)[0] + LeftPort
                                            t = Ports(port)[1] + TopPort
                                        }
                                    }
                                }
                            )
                        }

                        if (
                            dual_splitter == 'play_shield' &&
                            canItConnectOnPlayShield(port, component.type)
                        ) {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    LeftPort = componentConnected.left
                                    TopPort = componentConnected.top
                                    if (PortConnections.C == null) {
                                        l = Ports(port)[0] + bibox.left
                                        t = Ports(port)[1] + bibox.top
                                    }
                                }
                            )
                        }

                        if (
                            dual_splitter == 'pc_motor_driver' &&
                            JSON.parse(sessionStorage.getItem('assembly'))
                                .PortConnections.C !== null
                        ) {
                            if (
                                JSON.parse(sessionStorage.getItem('assembly'))
                                    .PortConnections.C.type == 'pc_motor_driver'
                            ) {
                                componentConnected[dual_splitter].map(
                                    (componentConnected, index) => {
                                        if (
                                            componentConnected.connectedTo ==
                                                'A' ||
                                            componentConnected.connectedTo ==
                                                'C'
                                        ) {
                                            LeftPort = componentConnected.left
                                            TopPort = componentConnected.top

                                            switch (component.type) {
                                                case 'mini_geared_motor':
                                                case 'dc_motor':
                                                case 'geared_motor': {
                                                    let DataServoMotorConnected_AC =
                                                        isServoMotorConnected_AC()
                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataServoMotorConnected_AC ==
                                                            'A1' ||
                                                        DataServoMotorConnected_AC ==
                                                            'A2' ||
                                                        DataServoMotorConnected_AC ==
                                                            'C1' ||
                                                        DataServoMotorConnected_AC ==
                                                            'C2' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        // console.log("BAD");
                                                        l =
                                                            Ports(port)[0] +
                                                            LeftPort +
                                                            125
                                                        t =
                                                            Ports(port)[1] +
                                                            TopPort +
                                                            30
                                                    }

                                                    break
                                                }

                                                case 'servo_motor': {
                                                    let DataGearedMotorConnected_AC =
                                                        isGearedConnected_AC()

                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataGearedMotorConnected_AC ==
                                                            'A1' ||
                                                        DataGearedMotorConnected_AC ==
                                                            'C1' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        switch (port) {
                                                            case 'C1': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort +
                                                                    60
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }

                                                            case 'C2': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort +
                                                                    80
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }
                                                        }
                                                    }

                                                    break
                                                }
                                            }
                                        }
                                    }
                                )
                            }
                        }
                        /** */
                    })
                } else if (
                    (port == 'B1' || port == 'B2') &&
                    !['Toki'].includes(connectedDevice)
                ) {
                    ////////////////// BUGFIX PLAYC-18 && PLAYC-39 /////////////////////////
                    // auto connect bug
                    const isDualSplitterOnB =
                        PortConnections.B &&
                        PortConnections.B.type === 'dual_splitter'
                            ? true
                            : false

                    if (
                        !isDualSplitterOnB &&
                        [
                            'servo_motor_360',
                            'servo_motor_180',
                            'servo_motor_270',
                        ].includes(draggingItem)
                    ) {
                        return false
                    }
                    ////////////////////////////////////////////////////////////////////////
                    Object.keys(componentConnected).map((key) => {
                        if (key == 'dual_splitter') {
                            componentConnected[key].map(
                                (componentConnected, index) => {
                                    if (componentConnected.connectedTo == 'B') {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top

                                        l = Ports(port)[0] + LeftPort
                                        t = Ports(port)[1] + TopPort
                                    }
                                    if (
                                        componentConnected.connectedTo == 'B' &&
                                        (shield || armShield)
                                    ) {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top
                                        if (port == 'B1') {
                                            l = Ports(port)[0] + LeftPort - 268
                                            t = Ports(port)[1] + TopPort - 256
                                        }
                                        if (port == 'B2') {
                                            l = Ports(port)[0] + LeftPort - 212
                                            t = Ports(port)[1] + TopPort - 287
                                        }
                                    }
                                }
                            )
                        }
                        if (
                            (connection['B'] == null ||
                                connection['B'].type !== 'dual_splitter') &&
                            key == 'play_shield' &&
                            canItConnectOnPlayShield(port, component.type)
                        ) {
                            componentConnected[key].map(
                                (componentConnected, index) => {
                                    LeftPort = componentConnected.left
                                    TopPort = componentConnected.top

                                    l = Ports(port)[0] + bibox.left
                                    t = Ports(port)[1] + bibox.top
                                }
                            )
                        }
                        if (key == 'servo_extender') {
                            componentConnected[key].map(
                                (componentConnected, index) => {
                                    if (componentConnected.connectedTo == 'B') {
                                        LeftPort = componentConnected.left
                                        TopPort = componentConnected.top

                                        l = Ports(port)[0] + LeftPort
                                        t = Ports(port)[1] + TopPort
                                    }
                                }
                            )
                        }

                        if (
                            key == 'pc_motor_driver' &&
                            JSON.parse(sessionStorage.getItem('assembly'))
                                .PortConnections.B !== null
                        ) {
                            if (
                                JSON.parse(sessionStorage.getItem('assembly'))
                                    .PortConnections.B.type == 'pc_motor_driver'
                            ) {
                                componentConnected[key].map(
                                    (componentConnected, index) => {
                                        if (
                                            componentConnected.connectedTo ==
                                                'B' ||
                                            componentConnected.connectedTo ==
                                                'D'
                                        ) {
                                            LeftPort = componentConnected.left
                                            TopPort = componentConnected.top

                                            switch (component.type) {
                                                case 'mini_geared_motor':
                                                case 'dc_motor':
                                                case 'geared_motor': {
                                                    let DataServoMotorConnected_BD =
                                                        isServoMotorConnected_BD()

                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataServoMotorConnected_BD ==
                                                            'B1' ||
                                                        DataServoMotorConnected_BD ==
                                                            'B2' ||
                                                        DataServoMotorConnected_BD ==
                                                            'D1' ||
                                                        DataServoMotorConnected_BD ==
                                                            'D2' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        // console.log("BAD");
                                                        l =
                                                            Ports(port)[0] +
                                                            LeftPort -
                                                            70
                                                        t =
                                                            Ports(port)[1] +
                                                            TopPort +
                                                            30
                                                    }
                                                    break
                                                }

                                                case 'servo_motor': {
                                                    let DataGearedMotorConnected_BD =
                                                        isGearedConnected_BD()
                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataGearedMotorConnected_BD ==
                                                            'B1' ||
                                                        DataGearedMotorConnected_BD ==
                                                            'D1' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        switch (port) {
                                                            case 'B1': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort -
                                                                    30
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }

                                                            case 'B2': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort -
                                                                    10
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }
                                                        }
                                                    }

                                                    break
                                                }
                                            }
                                        }
                                    }
                                )
                            }
                        }
                    })
                } else if (port == 'M1' || port == 'M3') {
                    if (sessionStorage.getItem('connectedDevice') === 'Klaw') {
                        l = Ports(port)[0] + bibox.left
                        t = Ports(port)[1] + bibox.top
                    }

                    /////////////////////// PLAYC-39 ////////////////////////
                    ///// Disabling certain ports when certain
                    ///// internal accessories are selected

                    const isSmile1 =
                        JSON.parse(sessionStorage.getItem('concept'))
                            .internalaccessories.isSmileOne || false
                    const isSmile2 =
                        JSON.parse(sessionStorage.getItem('concept'))
                            .internalaccessories.isSmileTwo || false
                    const isSmile3 =
                        JSON.parse(sessionStorage.getItem('concept'))
                            .internalaccessories.isSmileThree || false
                    const isSmile4 =
                        JSON.parse(sessionStorage.getItem('concept'))
                            .internalaccessories.isSmileFour || false

                    if (port === 'M1' && (isSmile1 || isSmile2)) return false
                    if (port === 'M3' && (isSmile3 || isSmile4)) return false

                    if (
                        ['Roboki', 'Toki'].includes(
                            sessionStorage.getItem('connectedDevice')
                        ) &&
                        ['M1', 'M3'].includes(port)
                    ) {
                        l = Ports(port)[0] + bibox.left
                        t = Ports(port)[1] + bibox.top
                    }
                    /////////////////////////////////////////////////////////
                    Object.keys(componentConnected).map((key) => {
                        if (
                            key == 'play_shield' ||
                            key == 'play_shield_v1' ||
                            key == 'peeCee_ATR'
                        ) {
                            componentConnected[key].map(
                                (componentConnected, index) => {
                                    LeftPort = componentConnected.left
                                    TopPort = componentConnected.top

                                    l = Ports(port)[0] + bibox.left
                                    t = Ports(port)[1] + bibox.top
                                }
                            )
                        }

                        if (
                            key == 'pc_motor_driver' &&
                            JSON.parse(sessionStorage.getItem('assembly'))
                                .PortConnections.B !== null
                        ) {
                            if (
                                JSON.parse(sessionStorage.getItem('assembly'))
                                    .PortConnections.B.type == 'pc_motor_driver'
                            ) {
                                componentConnected[key].map(
                                    (componentConnected, index) => {
                                        if (
                                            componentConnected.connectedTo ==
                                                'B' ||
                                            componentConnected.connectedTo ==
                                                'D'
                                        ) {
                                            LeftPort = componentConnected.left
                                            TopPort = componentConnected.top

                                            switch (component.type) {
                                                case 'mini_geared_motor':
                                                case 'dc_motor':
                                                case 'geared_motor': {
                                                    let DataServoMotorConnected_BD =
                                                        isServoMotorConnected_BD()

                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataServoMotorConnected_BD ==
                                                            'B1' ||
                                                        DataServoMotorConnected_BD ==
                                                            'B2' ||
                                                        DataServoMotorConnected_BD ==
                                                            'D1' ||
                                                        DataServoMotorConnected_BD ==
                                                            'D2' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        // console.log("BAD");
                                                        l =
                                                            Ports(port)[0] +
                                                            LeftPort -
                                                            70
                                                        t =
                                                            Ports(port)[1] +
                                                            TopPort +
                                                            30
                                                    }
                                                    break
                                                }

                                                case 'servo_motor': {
                                                    let DataGearedMotorConnected_BD =
                                                        isGearedConnected_BD()
                                                    let DataisStepperConnected =
                                                        isStepperConnected()

                                                    if (
                                                        DataGearedMotorConnected_BD ==
                                                            'B1' ||
                                                        DataGearedMotorConnected_BD ==
                                                            'D1' ||
                                                        DataisStepperConnected ==
                                                            'STPM'
                                                    ) {
                                                        // console.log("GOOD");
                                                    } else {
                                                        switch (port) {
                                                            case 'B1': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort -
                                                                    30
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }

                                                            case 'B2': {
                                                                l =
                                                                    Ports(
                                                                        port
                                                                    )[0] +
                                                                    LeftPort -
                                                                    10
                                                                t =
                                                                    Ports(
                                                                        port
                                                                    )[1] +
                                                                    TopPort
                                                                break
                                                            }
                                                        }
                                                    }

                                                    break
                                                }
                                            }
                                        }
                                    }
                                )
                            }
                        }
                    })
                } else if (port == 'G1' || port == 'G2') {
                    Object.keys(componentConnected).map((dual_splitter) => {
                        if (dual_splitter == 'dual_splitter') {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    if (componentConnected.connectedTo == 'G') {
                                        LeftG = componentConnected.left
                                        TopG = componentConnected.top

                                        l = Ports(port)[0] + LeftG
                                        t = Ports(port)[1] + TopG
                                    }
                                }
                            )
                        }
                    })
                } else if (port == 'STPM') {
                    Object.keys(componentConnected).map((dual_splitter) => {
                        if (dual_splitter == 'pc_motor_driver') {
                            componentConnected[dual_splitter].map(
                                (componentConnected, index) => {
                                    if (
                                        componentConnected.connectedTo == 'B' ||
                                        componentConnected.connectedTo == 'D'
                                    ) {
                                        let DataServoMotorConnected_BD =
                                            isServoMotorConnected_BD()

                                        let DataisGearedConnected_BD =
                                            isGearedConnected_BD()
                                        if (
                                            DataServoMotorConnected_BD ==
                                                'B1' ||
                                            DataServoMotorConnected_BD ==
                                                'B2' ||
                                            DataServoMotorConnected_BD ==
                                                'D1' ||
                                            DataServoMotorConnected_BD ==
                                                'D2' ||
                                            DataisGearedConnected_BD == 'B1' ||
                                            DataisGearedConnected_BD == 'D1'
                                        ) {
                                            // console.log("GOOD");
                                        } else {
                                            LeftG = componentConnected.left
                                            TopG = componentConnected.top

                                            l = Ports(port)[0] + LeftG
                                            t = Ports(port)[1] + TopG
                                        }
                                    } else if (
                                        componentConnected.connectedTo == 'A' ||
                                        componentConnected.connectedTo == 'C'
                                    ) {
                                        let DataServoMotorConnected_AC =
                                            isServoMotorConnected_AC()

                                        let DataisGearedConnected_AC =
                                            isGearedConnected_AC()
                                        if (
                                            DataServoMotorConnected_AC ==
                                                'A1' ||
                                            DataServoMotorConnected_AC ==
                                                'A2' ||
                                            DataServoMotorConnected_AC ==
                                                'C1' ||
                                            DataServoMotorConnected_AC ==
                                                'C2' ||
                                            DataisGearedConnected_AC == 'A1' ||
                                            DataisGearedConnected_AC == 'C1'
                                        ) {
                                            // console.log("GOOD");
                                        } else {
                                            LeftG = componentConnected.left
                                            TopG = componentConnected.top

                                            l = Ports(port)[0] + LeftG
                                            t = Ports(port)[1] + TopG
                                        }
                                    }
                                }
                            )
                        }
                    })
                } else {
                    var l = Ports(port)[0] + bibox.left
                    var t = Ports(port)[1] + bibox.top
                }

                if (
                    component.left < l &&
                    l < component.left + ImageSizes[ItemTypes.COMPONENT][0] &&
                    component.top < t &&
                    t < component.top + ImageSizes[ItemTypes.COMPONENT][1]
                ) {
                    portName = port
                    return true
                }
            })
        })

        // These conditon is for make connectedTo as false and portName for driverMotor
        if (sessionStorage.getItem('dragingItem') == 'pc_motor_driver') {
            if (portName == 'C') {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.A === null &&
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.C === null
                ) {
                    return portName
                }
            }
            if (portName == 'A') {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.A === null &&
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.C === null
                ) {
                    return portName
                }
            }
            if (portName == 'B') {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.B === null &&
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.D === null
                ) {
                    return portName
                }
            }
            if (portName == 'D') {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.B === null &&
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.D === null
                ) {
                    return portName
                }
            } else {
                return false
            }
        } else {
            return portName
        }
    }
}

export default IsOverPort
