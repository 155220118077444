import React, { Component } from 'react'
import data from '../concept/data'
import { DragSource } from 'react-dnd-old'
import ItemTypes from './ItemTypes'
import DraggingInfo from './DraggingInfo'
import { getEmptyImage } from 'react-dnd-html5-backend-old'
import renderCompImg from '../../source/Comp_Img'

const isPlayShield = sessionStorage.getItem('shield') == 'true'
const isArmShield = sessionStorage.getItem('armShield') == 'true'
const isPeeCeeATR = sessionStorage.getItem('peeCeeATR') == 'true'

const cardSource = {
    beginDrag(props) {
        sessionStorage.setItem('dragingItem', props.type)

        DraggingInfo.isDragging = true
        return { type: props.type }
    },
    endDrag: (props) => {
        DraggingInfo.isDragging = false
        DraggingInfo.newComponentPort = null
        sessionStorage.setItem('dragingItem', null)

        if (
            props.type === 'play_shield' ||
            props.type === 'play_shield_v1' ||
            props.type === 'arm_shield' ||
            props.type === 'peeCee_ATR'
        ) {
            window.location.reload()
        }
    },
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }
}

class Card extends Component {
    constructor(props) {
        super(props)

        this.state = {}

        this.isComImgev1 =
            ['1', '2'].includes(sessionStorage.getItem('deviceVersion')[0]) ||
            ['Roboki', 'Toki'].includes(
                sessionStorage.getItem('connectedDevice')
            )
    }
    componentDidMount() {
        // Use empty image as a drag preview so browsers don't draw it
        // and we can draw whatever we want on the custom drag layer instead.
        this.props.connectDragPreview(getEmptyImage(), {
            // IE fallback: specify that we'd rather screenshot the node
            // when it already knows it's being dragged so we can hide it with CSS.
            captureDraggingState: true,
        })
    }
    render() {
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        const isPlayShield = sessionStorage.getItem('shield') == 'true'
        const isArmShield = sessionStorage.getItem('armShield') == 'true'
        const isPeeCeeATR = sessionStorage.getItem('peeCeeATR') == 'true'
        const deviceVersion = sessionStorage.getItem('PCVersion') == '1.0.03'
        const { isDragging, connectDragSource, type } = this.props
        console.log('draggable', this.props)
        var widthSize
        var leftPro
        var topPro

        //set each components style
        switch (type) {
            case 'ultrasonic_sensor':
                widthSize = '85%'
                topPro = '1vh'
                leftPro = '7%'
                break
            case 'mp3':
                widthSize = '80%'
                topPro = '5vh'
                leftPro = '7%'
                break
            case 'play_shield_v1':
                widthSize = '90%'
                topPro = '3vh'
                break
            case 'play_shield':
                widthSize = '93%'
                topPro = '3vh'
                leftPro = '-4%'
                break
            case 'linear_pot':
                if (this.isComImgev1) {
                    widthSize = '81%'
                    topPro = '2.5vh'
                    leftPro = '8%'
                } else {
                    widthSize = '81%'
                    topPro = '0.6vh'
                    leftPro = '8%'
                }
                break
            case 'tact_switch':
                if (this.isComImgev1) {
                    widthSize = '65%'
                    topPro = '-2.2vh'
                    leftPro = '23%'
                } else {
                    widthSize = '53%'
                    leftPro = '23%'
                }
                break
            case 'laser':
                if (this.isComImgev1) {
                    widthSize = '53%'
                    topPro = '3vh'
                    leftPro = '22%'
                } else {
                    widthSize = '60%'
                    leftPro = '22%'
                }
                break
            case 'RGB':
                if (this.isComImgev1) {
                    widthSize = '80%'
                    topPro = '3vh'
                    leftPro = '7%'
                } else {
                    widthSize = '80%'
                    topPro = '2vh'
                    leftPro = '7%'
                }
                break
            case 'OLED':
                if (this.isComImgev1) {
                    widthSize = '80%'
                    topPro = '3vh'
                    leftPro = '7%'
                } else {
                    widthSize = '80%'
                    topPro = '2vh'
                    leftPro = '7%'
                }
                break
            case 'mini_geared_motor':
                widthSize = '67%'
                topPro = '2vh'
                leftPro = '2%'
                break
            case 'dc_motor':
                if (this.isComImgev1) {
                    widthSize = '67%'
                    topPro = '0vh'
                    leftPro = '2%'
                } else {
                    widthSize = '67%'
                    topPro = '-1vh'
                    leftPro = '2%'
                }
                break
            case 'dynamex_motor':
                widthSize = '80%'
                topPro = '4vh'
                leftPro = '5%'

                break
            case 'dual_splitter':
                widthSize = '80%'
                topPro = '3vh'
                leftPro = '7%'
                break
            case 'joystick':
                if (this.isComImgev1) {
                    widthSize = '90%'
                    topPro = '4vh'
                } else {
                    widthSize = '60%'
                    topPro = '-2vh'
                    leftPro = '22%'
                }
                break
            case 'peeCee_ATR':
                if (this.isComImgev1) {
                    widthSize = '85%'
                    topPro = '4.5vh'
                    leftPro = '8%'
                } else {
                    widthSize = '85%'
                    topPro = '2vh'
                    leftPro = '8%'
                }
                break
            case 'arm_shield':
                widthSize = '86%'
                topPro = '2vh'
                leftPro = '10%'
                break
            case 'speaker':
                widthSize = '107%'
                topPro = '-1vh'
                leftPro = '-6%'
                break
            case 'dual_switch':
                widthSize = '60%'
                topPro = '-1vh'
                leftPro = '22%'
                break
            case 'geared_motor':
                if (this.isComImgev1) {
                    widthSize = '60%'
                    topPro = '3vh'
                    leftPro = '18%'
                } else {
                    widthSize = '60%'
                    topPro = '1.5vh'
                    leftPro = '18%'
                }
                break
            case 'lenear_pot':
                if (this.isComImgev1) {
                    widthSize = '60%'
                    topPro = '3vh'
                    leftPro = '18%'
                } else {
                    widthSize = '60%'
                    topPro = '1.5vh'
                    leftPro = '18%'
                }
                break
            case 'dip_switch':
                widthSize = '53%'
                topPro = '2vh'
                leftPro = '23%'
                break
            case 'gas':
                if (!this.isComImgev1) {
                    widthSize = '60%'
                    topPro = '2vh'
                    leftPro = '22%'
                } else {
                    widthSize = '60%'
                    leftPro = '22%'
                }
                break
            case 'pot':
                if (this.isComImgev1) {
                    widthSize = '56%'
                    topPro = '2vh'
                    leftPro = '24%'
                } else {
                    widthSize = '60%'
                    leftPro = '22%'
                }
                break
            case 'servo_motor_360':
                if (this.isComImgev1) {
                    widthSize = '60%'
                    topPro = '2vh'
                    leftPro = '22%'
                } else {
                    widthSize = '60%'
                    leftPro = '22%'
                }
                break
            case 'servo_motor':
                if (this.isComImgev1) {
                    widthSize = '60%'
                    topPro = '2vh'
                    leftPro = '22%'
                } else {
                    widthSize = '60%'
                    leftPro = '22%'
                }
                break

            case 'L293D_motor_driver':
            case 'PIR_sensor':
            case 'capacitative_touch_sensor':
            case 'rain_sensor':
            case 'rain_sensor_module_analog':
            case 'rain_sensor_module_digital':
                if (connectedDevice == 'Toki') {
                    widthSize = '97%'
                    topPro = '3.5vh'
                    leftPro = '-3%'
                } else {
                    widthSize = '60%'
                    leftPro = '22%'
                }
                break
            default:
                widthSize = '60%'
                // topPro = '12%'
                leftPro = '22%'
        }
        // console.log(type, isArmShield, isPeeCeeATR, isPlayShield)
        if (
            (type == 'play_shield' ||
                type == 'arm_shield' ||
                type == 'peeCee_ATR' ||
                type == 'play_shield_v1') &&
            (isPlayShield || isArmShield || isPeeCeeATR)
        ) {
            // console.log('rtuequweuqqqw')
            return (
                // <div style={{ width: '100%' }}>
                //     <img
                //         style={{
                //             zIndex: '50',
                //             opacity: '1',
                //             width: widthSize,
                //             // position: 'absolute',
                //             // top: topPro,
                //             // left: leftPro,
                //             objectFit: 'fill',
                //             // transform: 'scale(1.45,1.45)',
                //             opacity: 0.5,

                //             // marginLeft: "-125px",
                //             // marginTop: "20px",
                //         }}
                //         // src={`images/oldImages/component_` + type + ".png"}
                //         src={renderCompImg(type)}
                //     />
                //     <br />
                // </div>

                <img
                    style={{
                        zIndex: '50',
                        opacity: '1',
                        width: widthSize,
                        position: 'absolute',
                        top: topPro,
                        left: leftPro,
                        objectFit: 'fill',
                        transform: 'scale(1.45,1.45)',
                        opacity: 0.5,

                        // marginLeft: "-125px",
                        // marginTop: "20px",
                    }}
                    // src={`images/oldImages/component_` + type + ".png"}
                    src={renderCompImg(type)}
                />
            )
        }

        return connectDragSource(
            // <img style={{ height: "120px", width: "80px", position: "absolute", marginLeft: "-125px", marginTop: "20px" }} src={`images/oldImages/component_` + type + '.png'} />
            // "dual_splitter"  "ultrasonic_sensor"

            <img
                style={{
                    zIndex: '50',
                    opacity: '1',
                    width: widthSize,
                    position: 'absolute',
                    top: topPro,
                    left: leftPro,
                    objectFit: 'fill',
                    transform: 'scale(1.45,1.45)',
                    cursor: 'pointer',
                    // marginLeft: "-125px",
                    // marginTop: "20px",
                }}
                // src={`images/oldImages/component_` + type + ".png"}
                src={renderCompImg(type)}
            />

            // <img
            //   style={{
            //     zIndex: "100",
            //     opacity: "1",
            //     height: "110px",
            //     width: "40%",
            //     position: "absolute",
            //     top: "12%",
            //     left: "20%",
            //     objectFit: "fill",
            //     // marginLeft: "-125px",
            //     // marginTop: "20px",
            //   }}
            //   src={`images/oldImages/component_` + type + ".png"}
            // />
        )
    }
}

// export default Card;
export default DragSource(ItemTypes.COMPONENT, cardSource, collect)(Card)
