import React, { Component } from 'react'

import Colors from '../../Colors'

// in toki has frontend show port and backend program port are different, so the ports are map here.
const tokiPortNameMap = {
    A1: 'AIN1',
    A2: 'AIN2',
    B1: 'AIN3',
    C1: 'DRV1',
    C2: 'DRV1',
    servo_motor1: 'S1',
    servo_motor2: 'S2',
    M1: 'M1',
    M2: 'M2',
}

class Select extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    onChange = (e) => {
        this.props.onChange(e.target.value)
    }

    render() {
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        var style = {
            color: '#000',
            background: '#FFF',
            outline: 'none',
            texAlign: 'center',
            fontFamily: 'Halcyon_Medium ',
            height: '100%',
            width: '100%',
            border: '1px solid white',
            borderRadius: '15px',
            cursor: 'pointer',
        }

        console.log(this.props, 'CONDITUOAN')
        if (this.props.disabled) style.background = Colors.disabledgrey
        // CHANGED NAMING OF FOUR IN SENSOR
        var customOptions = {}
        this.props.order.map((option, index) => {
            console.log('loser', option)
            if (option == 'battery') {
                customOptions[index] = 'Select Items'
            } else if (option == '4-IN-1 SENSOR  →  RED') {
                customOptions[index] = 'PC -> COLOUR SENSOR (RED)'
            } else if (option == '4-IN-1 SENSOR  →  BLUE') {
                customOptions[index] = 'PC -> COLOUR SENSOR (BLUE)'
            } else if (option == '4-IN-1 SENSOR  →  GREEN') {
                customOptions[index] = 'PC -> COLOUR SENSOR (GREEN)'
            } else if (option == '4-IN-1 SENSOR  →  DIST') {
                customOptions[index] = 'PC Proximity Sensor'
            } else if (option == '4-IN-1 SENSOR  →  GESTURE') {
                customOptions[index] = 'PC Gesture Sensor'
            } else if (option == '4-IN-1 SENSOR  →  LIGHT') {
                customOptions[index] = 'PC Light Sensor'
            } else if (
                ['Switch_One', 'Switch_Two'].includes(option) &&
                ['Roboki'].includes(connectedDevice)
            ) {
                customOptions[index] = 'Roboki ' + option
            } else {
                if (this.props.options[option] != null) {
                    if (
                        option === 'C1' &&
                        ['Roboki'].includes(connectedDevice)
                    ) {
                        let removePortStr = this.props.options[option].replace(
                            / → .*/,
                            ''
                        )
                        customOptions[index] = removePortStr + ' \u2192 ' + 'B1'
                    } else if (
                        option === 'C2' &&
                        ['Roboki'].includes(connectedDevice)
                    ) {
                        let removePortStr = this.props.options[option].replace(
                            / → .*/,
                            ''
                        )
                        customOptions[index] = removePortStr + ' \u2192 ' + 'B2'
                    } else if (connectedDevice == 'Toki') {
                        let labelSplit = this.props.options[option].split('→')
                        if (labelSplit[1]) {
                            customOptions[index] =
                                labelSplit[0] +
                                ' \u2192 ' +
                                tokiPortNameMap[labelSplit[1].trim()]
                        } else {
                            customOptions[index] = this.props.options[option]
                        }
                    } else {
                        customOptions[index] = this.props.options[option]
                    }
                } else {
                    let deviceName = ''
                    switch (connectedDevice) {
                        case 'Ace':
                            deviceName = 'PC '
                            break
                        case 'Roboki':
                            deviceName = 'Roboki '
                            break
                        default:
                            deviceName = `${connectedDevice} `
                    }
                    customOptions[index] = deviceName + option
                }
            }
        })
        console.log('loser', customOptions)
        if (this.props.typeDropDown == 'read') {
            return (
                <select
                    onChange={this.onChange}
                    style={style}
                    value={this.props.selected}
                    disabled={this.props.disabled}
                >
                    {this.props.order
                        ? this.props.order.map((value, index) => (
                              <option
                                  className="sensor-txt"
                                  key={index}
                                  value={value}
                              >
                                  {customOptions[index]}
                              </option>
                          ))
                        : Object.keys(this.props.options).map(
                              (value, index) => (
                                  <option
                                      className="sensor-txt"
                                      key={index}
                                      value={value}
                                  >
                                      {this.props.options[value]}
                                  </option>
                              )
                          )}
                </select>
            )
        } else {
            return (
                <select
                    onChange={this.onChange}
                    style={style}
                    value={this.props.selected}
                    disabled={this.props.disabled}
                >
                    {this.props.order
                        ? this.props.order.map((value, index) => (
                              <option
                                  className="sensor-txt"
                                  key={index}
                                  value={value}
                              >
                                  {customOptions[index]}
                                  {/* {this.props.options[value] || value} */}
                              </option>
                          ))
                        : Object.keys(this.props.options).map(
                              (value, index) => (
                                  <option
                                      className="sensor-txt"
                                      key={index}
                                      value={value}
                                  >
                                      {this.props.options[value]}
                                  </option>
                              )
                          )}
                </select>
            )
        }
    }
}

export default Select
