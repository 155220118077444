/**
 * This module exports a draggable Bibox which is drawn in Workspace
 * @module components/assembly/Bibox
 */

import React, { Component } from 'react'

import { DragSource } from 'react-dnd-old'

import ItemTypes from './ItemTypes'
import ImageSizes from './ImageSizes'

import DraggingInfo from './DraggingInfo'

import renderPrgImage from '../../source/programImg'
import selectStyle from '../Humanoid/HumanoidSelectFeatures.module.css' ///'./HumanoidSelectFeatures.module.css'
import renderImage from '../../source/importImg'

var style = {
    position: 'relative',
    cursor: 'move',
    // backgroundImage: "url(images/Learn/tern.png)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    maxWidth: '100%',
    height: '100%',
    width: '100%',
    zIndex: '1',

    // border: "1px solid blue",
}

const biboxSource = {
    beginDrag(props) {
        DraggingInfo.isDragging = true
        console.log('dragging', props)
        var { left, top } = props
        // left = left - 500;
        if (['Ace', 'Roboki', 'Toki'].includes(Device))
            document.getElementById('PC_dragSource').style.opacity = 0
        if (Device == 'Humanoid')
            document.getElementById('Zing_DragSource').style.opacity = 0
        if (Device == 'Klaw') {
            document.getElementById('Klaw_DragSource').style.opacity = 0
        }
        return { left, top }
    },
    endDrag() {
        DraggingInfo.isDragging = false
    },
}

var Device = sessionStorage.getItem('connectedDevice')
var shield = sessionStorage.getItem('shield')
var armShield = sessionStorage.getItem('armShield')
var peeCeeATR = sessionStorage.getItem('peeCeeATR')

class Bibox extends Component {
    componentDidMount() {}
    typeCheck = () => {
        this.removeComponent()
    }
    removeComponent = () => {
        console.log('remove shield', this.props)
        var shield = sessionStorage.getItem('shield')
        if (shield == 'true') {
            sessionStorage.setItem('shield', 'false')
            this.props.setShield(false)
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                this.props.removeFromWorkspace({ type: 'play_shield_v1' })
            } else {
                this.props.removeFromWorkspace({ type: 'play_shield' })
            }
        }

        if (armShield == 'true') {
            sessionStorage.setItem('armShield', 'false')
            this.props.setArmShield(false)
            this.props.removeFromWorkspace({ type: 'arm_shield' })
        } else if (peeCeeATR == 'true') {
            sessionStorage.setItem('peeCeeATR', 'false')
            this.props.setPeeCeeATR(false)
            this.props.removeFromWorkspace({ type: 'peeCee_ATR' })
        }

        this.forceUpdate()
    }
    render() {
        var Device = sessionStorage.getItem('connectedDevice')
        var shield = sessionStorage.getItem('shield')
        var armShield = sessionStorage.getItem('armShield')
        var peeCeeATR = sessionStorage.getItem('peeCeeATR')
        let Url
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (Device == 'Ace' && shield == 'true') {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                Url = renderPrgImage('PlayComputerwithShieldImg_v1')
            } else {
                Url = renderPrgImage('PlayComputerwithShieldImg')
            }
        } else if (Device == 'Ace' && armShield == 'true') {
            Url = renderPrgImage('PlayComputerwithArmShield')
        } else if (Device == 'Ace' && peeCeeATR == 'true') {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                Url = renderPrgImage('PlayComputerwithPeeCeeATRV1')
            } else {
                Url = renderPrgImage('PlayComputerwithPeeCeeATR')
            }
        } else if (Device == 'Ace') {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                Url = renderPrgImage('pc_v1')
            } else {
                Url = renderPrgImage('PlayComputerImg')
            }
        } else if (Device == 'Roboki') {
            Url = renderPrgImage('Roboki')
            // Url = renderPrgImage('humanoidWithPCv1')
        } else if (Device == 'Humanoid') {
            Url = renderPrgImage('humanoidWithPCv1')
        } else if (Device == 'Hexapod') {
            Url = renderPrgImage('hexapod')
        } else if (Device == 'Klaw') {
            Url = renderPrgImage('NewklawShield')
        } else if (Device == 'Toki') {
            Url = renderPrgImage('Toki')
        }
        // else if (Device == 'Humanoid' && version == 1) {
        //     Url = renderPrgImage('humanoidWithPCv1')
        // }
        var {
            left,
            top,
            scale,
            connectDragSource,
            isDragging,
            workspaceConnect,
        } = this.props
        if (isDragging) {
            return null
        }
        // left = left + 200;
        if (sessionStorage.getItem('connectedDevice') == 'Tern') {
            var height = ImageSizes[ItemTypes.BIBOX][1] * scale + 15
            var width = ImageSizes[ItemTypes.BIBOX][0] * scale - 17
        } else if (sessionStorage.getItem('connectedDevice') == 'Humanoid') {
            var height = 476 * scale * 0.75
            var width = 346 * scale * 0.75
        } else if (sessionStorage.getItem('connectedDevice') == 'Hexapod') {
            var height = 476 * scale * 0.75 - 57
            var width = 346 * scale * 0.75 + 136.5
        } else if (
            ['Roboki', 'Toki'].includes(
                sessionStorage.getItem('connectedDevice')
            )
        ) {
            var height = 476 * scale
            var width = 346 * scale
        } else if (
            sessionStorage.getItem('connectedDevice') == 'Ace' &&
            (shield == 'true' || armShield == 'true' || peeCeeATR == 'true')
        ) {
            scale = 1.4
            var height = ImageSizes[ItemTypes.BIBOX][1] * scale
            var width = ImageSizes[ItemTypes.BIBOX][0] * scale
        } else {
            var height = ImageSizes[ItemTypes.BIBOX][1] * scale
            var width = ImageSizes[ItemTypes.BIBOX][0] * scale
        }

        // ISACTIVE_DATA
        let isCheckedMic = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isMic']
        let isCheckedEyeLeft = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isLeftEye']
        let isCheckedEyeRight = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isRightEye']
        let isCheckedEye = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isEYES']
        let isCheckedSimeleFour = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isSmileFour']
        let isCheckedSimeleOne = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isSmileOne']
        let isCheckedSimeleThree = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isSmileThree']
        let isCheckedSimeleTwo = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isSmileTwo']
        let isCheckedTouchThree = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isTouchThree']
        let isCheckedTouchOne = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isTouchOne']
        let isCheckedTouchTwo = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isTouchTwo']
        let isCheckedTouchZero = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isTouchZero']

        let isCheckedTouchOneOutput = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories['isTouchOneOutput']
        let isCheckedTouchTwoOutput = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories['isTouchTwoOutput']
        let isCheckedTouchZeroOutput = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories['isTouchZeroOutput']
        let isCheckedTouchThreeOutput = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories['isTouchThreeOutput']

        let isCheckedbuzzer = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isbuzzer']
        let isCheckedColorSensor = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories.Four_in_one_sensor['isColorSensor']
        let isCheckedDistanceSensors = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories.Four_in_one_sensor['isDistanceSensors']
        let isCheckedGestureSensor = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories.Four_in_one_sensor['isGestureSensor']
        let isCheckedLightSensor = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories.Four_in_one_sensor['isLightSensor']

        let isSwitch1 = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isSwitch1']
        let isSwitch2 = JSON.parse(sessionStorage.getItem('concept'))
            .internalaccessories['isSwitch2']
        let TouchPads = {
            isCheckedTouchZero,
            isCheckedTouchOne,
            isCheckedTouchTwo,
        }
        console.log(
            'chhfjkdshfgdsfgds',
            isCheckedTouchOne,
            isCheckedTouchOneOutput,
            this.props.one
        )
        if (
            Device == 'Ace' &&
            (shield == 'false' || shield === null) &&
            (armShield == 'false' || armShield === null) &&
            (peeCeeATR == 'false' || peeCeeATR === null)
        ) {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                return connectDragSource(
                    <div
                        id="PC_dragSource"
                        style={{
                            ...style,
                            left,
                            top,
                            backgroundImage: `url("${Url}")`,
                            overflow: 'visible',
                            height: '300px',
                            width: '285px',
                        }}
                    >
                        <img
                            src={renderPrgImage(
                                isCheckedMic
                                    ? 'PcinternalMicActive'
                                    : 'PcinternalMicInActive'
                            )}
                            style={{
                                height: '25%',
                                width: '15%',
                                position: 'absolute',
                                top: '-3%',
                                left: '49.8%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        />

                        {/*RGB EYE  */}

                        <div>
                            {[30.5, 37.5, 44.2, 51.5, 58, 65.2].map(
                                (value, index) => {
                                    return (
                                        <>
                                            <img
                                                src={renderPrgImage(
                                                    isCheckedEye
                                                        ? 'PcinternalEYEActive_v1'
                                                        : 'PcinternalEYEInActive_v1'
                                                )}
                                                style={{
                                                    height: '6%',
                                                    width: '3.8%',
                                                    position: 'absolute',
                                                    top: '37.5%',
                                                    left: `${value}%`,
                                                }}
                                            />
                                            <img
                                                src={renderPrgImage(
                                                    isCheckedEye
                                                        ? 'PcinternalEYEActive_v1'
                                                        : 'PcinternalEYEInActive_v1'
                                                )}
                                                style={{
                                                    height: '6%',
                                                    width: '3.8%',
                                                    position: 'absolute',
                                                    top: '41.8%',
                                                    left: `${value}%`,
                                                }}
                                            />
                                        </>
                                    )
                                }
                            )}
                        </div>

                        {/* 1-teeth Active*/}
                        <img
                            src={renderPrgImage(
                                isCheckedSimeleOne
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '3.8%',
                                width: '2%',
                                position: 'absolute',
                                top: '61.5%',
                                left: '43.4%',
                                transform: `translate(-40.4%,-60%)`,
                            }}
                        />

                        {/* 2-teeth Active*/}
                        <img
                            src={renderPrgImage(
                                isCheckedSimeleTwo
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '3.8%',
                                width: '2%',
                                position: 'absolute',
                                top: '62.5%',
                                left: '45.8%',
                                transform: `translate(-43.5%,-61%)`,
                            }}
                        />

                        {/* 4-in-1 Sensor  */}
                        <img
                            src={renderPrgImage(
                                isCheckedColorSensor ||
                                    isCheckedDistanceSensors ||
                                    isCheckedGestureSensor ||
                                    isCheckedLightSensor
                                    ? 'Pcinternal4in1Active_v1'
                                    : 'Pcinternal4in1InActive_v1'
                            )}
                            style={{
                                height: '5%',
                                width: '8%',
                                position: 'absolute',
                                left: '48.5%',
                                top: '63%',
                                transform: `translate(-30%,-61%)`,
                            }}
                        />

                        {/* 3-teeth Active*/}
                        <img
                            src={renderPrgImage(
                                isCheckedSimeleThree
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '3.8%',
                                width: '2%',
                                position: 'absolute',
                                top: '62.5%',
                                left: '54%',
                                transform: `translate(-57%,-61%)`,
                            }}
                        />

                        {/* 4-teeth Active*/}

                        <img
                            src={renderPrgImage(
                                isCheckedSimeleFour
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '3.8%',
                                width: '2%',
                                position: 'absolute',
                                top: '61.5%',
                                left: '56.4%',
                                transform: `translate(-60.3%,-60%)`,
                            }}
                        />

                        {/*  0 Touch Pad*/}

                        <img
                            style={{
                                position: 'absolute',
                                left: '16.5%',
                                bottom: '6.5%',
                                transform: `translate(-30%,0%)`,
                                width: '19.5%',
                                height: '12%',
                                borderRadius: '15% 26% 1% 13%',
                                background:
                                    isCheckedTouchZero ||
                                    isCheckedTouchZeroOutput
                                        ? 'rgb(255 153 64 / 94%)'
                                        : 'rgb(176 174 161 / 95%)',
                            }}
                        />

                        {/*  1 Touch Pad*/}

                        <img
                            style={{
                                position: 'absolute',
                                left: '37%',
                                bottom: '6.8%',
                                transform: `translate(-30%,0%)`,
                                width: '18%',
                                height: '12%',
                                borderRadius: '15% 13% 1% 13%',
                                background:
                                    isCheckedTouchOne || isCheckedTouchOneOutput
                                        ? 'rgb(255 153 64 / 94%)'
                                        : 'rgb(176 174 161 / 95%)',
                            }}
                            draggable="false"
                        />

                        {/*  2 Touch Pad*/}
                        <img
                            style={{
                                position: 'absolute',
                                left: '57%',
                                bottom: '6.5%',
                                transform: `translate(-30%,0%)`,
                                width: '19%',
                                height: '12%',
                                borderRadius: '15% 13% 1% 13%',
                                background:
                                    isCheckedTouchTwo || isCheckedTouchTwoOutput
                                        ? 'rgb(255 153 64 / 94%)'
                                        : 'rgb(176 174 161 / 95%)',
                            }}
                            draggable="false"
                        />

                        {/*  3 Touch Pad*/}
                        <img
                            style={{
                                position: 'absolute',
                                left: '71%',
                                top: '81.5%',
                                width: '17%',
                                height: '12%',
                                borderRadius: '15% 13% 16% 13%',
                                background:
                                    isCheckedTouchThree ||
                                    isCheckedTouchThreeOutput
                                        ? 'rgb(255 153 64 / 94%)'
                                        : 'rgb(176 174 161 / 95%)',
                            }}
                        />

                        <p
                            style={{
                                fontSize: '18px',
                                height: '5%',
                                width: '20%',
                                position: 'absolute',
                                top: '97%',
                                left: '23%',
                                transform: 'translate(-50%,-50%)',
                                color: '#707070',
                            }}
                        >
                            {isCheckedTouchZero ? this.props.zero : null}
                            {/* {this.props.responceTp0} */}
                        </p>

                        <p
                            style={{
                                fontSize: '18px',
                                // backgroundColor: "blue",
                                height: '5%',
                                width: '20%',
                                position: 'absolute',
                                top: '97%',
                                left: '46%',
                                transform: 'translate(-50%,-50%)',
                                color: '#707070',
                            }}
                        >
                            {isCheckedTouchOne ? this.props.one : null}
                        </p>

                        <p
                            style={{
                                fontSize: '18px',
                                height: '5%',
                                width: '20%',
                                position: 'absolute',
                                top: '97%',
                                left: '66%',
                                transform: 'translate(-50%,-50%)',
                                color: '#707070',
                            }}
                        >
                            {/* {this.props.responceTp2} */}
                            {isCheckedTouchTwo ? this.props.two : null}
                        </p>

                        <p
                            style={{
                                fontSize: '18px',
                                height: '5%',
                                width: '20%',
                                position: 'absolute',
                                top: '96%',
                                left: '85%',
                                transform: 'translate(-50%,-50%)',
                                color: '#707070',
                            }}
                        >
                            {/* {this.props.responceTp2} */}
                            {isCheckedTouchThree ? this.props.temp : null}
                        </p>

                        <p
                            style={{
                                fontSize: '18px',
                                // height: "5%",
                                // width: "5%",
                                position: 'absolute',
                                top: '85%',
                                marginTop: '-102.5%',
                                marginLeft: '68%',
                                color: '#707070',
                            }}
                        >
                            {isCheckedMic ? this.props.mic : null}
                        </p>
                    </div>
                )
            } else {
                return connectDragSource(
                    <div
                        id="PC_dragSource"
                        style={{
                            ...style,
                            left,
                            top,
                            height,
                            width,
                            backgroundImage: `url("${Url}")`,
                            overflow: 'visible',

                            // background: "red",
                        }}
                        //onDoubleClick={() => this.typeCheck(this)}
                    >
                        {isCheckedMic ? (
                            <img
                                src={renderPrgImage('PcinternalMicActive')}
                                style={{
                                    height: '35%',
                                    width: '15%',
                                    marginTop: '-100px',
                                    marginLeft: '42%',
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalMicInActive')}
                                style={{
                                    height: '35%',
                                    width: '15%',
                                    marginTop: '-100px',
                                    marginLeft: '42%',
                                }}
                            />
                        )}

                        {/*LEFT EYE  */}
                        {isCheckedEyeLeft ? (
                            <img
                                src={renderPrgImage('PcinternalEYEActive')}
                                style={{
                                    height: '8%',
                                    width: '10%',
                                    position: 'absolute',
                                    top: '31%',
                                    left: '34%',
                                    transform: `translate(-34%,-31%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalEYEInActive')}
                                style={{
                                    height: '8%',
                                    width: '10%',
                                    position: 'absolute',
                                    top: '31%',
                                    left: '34%',
                                    transform: `translate(-34%,-31%)`,
                                }}
                            />
                        )}

                        {/*RIGHT EYE  */}
                        {isCheckedEyeRight ? (
                            <img
                                src={renderPrgImage('PcinternalEYEActive')}
                                style={{
                                    height: '8%',
                                    width: '10%',
                                    position: 'absolute',
                                    top: '31%',
                                    left: '67.5%',
                                    transform: `translate(-67.5%,-31%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalEYEInActive')}
                                style={{
                                    height: '8%',
                                    width: '10%',
                                    position: 'absolute',
                                    top: '31%',
                                    left: '67.5%',
                                    transform: `translate(-67.5%,-31%)`,
                                }}
                            />
                        )}

                        {/* 1-teeth Active*/}
                        {isCheckedSimeleOne ? (
                            <img
                                src={renderPrgImage('PcinternalTeethActive')}
                                style={{
                                    height: '6%',
                                    width: '2.7%',
                                    position: 'absolute',
                                    top: '60%',
                                    left: '40.4%',
                                    transform: `translate(-40.4%,-60%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalTeethInActive')}
                                style={{
                                    height: '6%',
                                    width: '2.7%',
                                    position: 'absolute',
                                    top: '60%',
                                    left: '40.4%',
                                    transform: `translate(-40.4%,-60%)`,
                                }}
                            />
                        )}
                        {/* 2-teeth Active*/}
                        {isCheckedSimeleTwo ? (
                            <img
                                src={renderPrgImage('PcinternalTeethActive')}
                                style={{
                                    height: '6%',
                                    width: '2.7%',
                                    position: 'absolute',
                                    top: '61%',
                                    left: '43.5%',
                                    transform: `translate(-43.5%,-61%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalTeethInActive')}
                                style={{
                                    height: '6%',
                                    width: '2.7%',
                                    position: 'absolute',
                                    top: '61%',
                                    left: '43.5%',
                                    transform: `translate(-43.5%,-61%)`,
                                }}
                            />
                        )}

                        {/* 4-in-1 Sensor  */}
                        {isCheckedColorSensor ||
                        isCheckedDistanceSensors ||
                        isCheckedGestureSensor ||
                        isCheckedLightSensor ? (
                            <img
                                src={renderPrgImage('Pcinternal4in1Active')}
                                style={{
                                    height: '5%',
                                    width: '8%',
                                    position: 'absolute',
                                    left: '48.5%',
                                    top: '61%',
                                    transform: `translate(-30%,-61%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('Pcinternal4in1InActive')}
                                style={{
                                    height: '5%',
                                    width: '8%',
                                    position: 'absolute',
                                    left: '48.5%',
                                    top: '61%',
                                    transform: `translate(-30%,-61%)`,
                                }}
                            />
                        )}

                        {/* 3-teeth Active*/}
                        {isCheckedSimeleThree ? (
                            <img
                                src={renderPrgImage('PcinternalTeethActive')}
                                style={{
                                    height: '6%',
                                    width: '2.7%',
                                    position: 'absolute',
                                    top: '61%',
                                    left: '57%',
                                    transform: `translate(-57%,-61%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalTeethInActive')}
                                style={{
                                    height: '6%',
                                    width: '2.7%',
                                    position: 'absolute',
                                    top: '61%',
                                    left: '57%',
                                    transform: `translate(-57%,-61%)`,
                                }}
                            />
                        )}

                        {/* 4-teeth Active*/}
                        {isCheckedSimeleFour ? (
                            <img
                                src={renderPrgImage('PcinternalTeethActive')}
                                style={{
                                    height: '6%',
                                    width: '2.7%',
                                    position: 'absolute',
                                    top: '60%',
                                    left: '60.3%',
                                    transform: `translate(-60.3%,-60%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalTeethInActive')}
                                style={{
                                    height: '6%',
                                    width: '2.7%',
                                    position: 'absolute',
                                    top: '60%',
                                    left: '60.3%',
                                    transform: `translate(-60.3%,-60%)`,
                                }}
                            />
                        )}

                        {/*  0 Touch Pad*/}
                        {isCheckedTouchZero || isCheckedTouchZeroOutput ? (
                            <img
                                src={renderPrgImage(
                                    'PcinternalTouchpadsActive'
                                )}
                                style={{
                                    height: '14%',
                                    width: '8%',
                                    position: 'absolute',
                                    left: '14%',
                                    bottom: '4.5%',
                                    transform: `translate(-30%,0%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage(
                                    'PcinternalTouchpadsInActive'
                                )}
                                style={{
                                    height: '14%',
                                    width: '8%',
                                    position: 'absolute',
                                    left: '14%',
                                    bottom: '4.5%',
                                    transform: `translate(-30%,0%)`,
                                }}
                            />
                        )}

                        {/*  1 Touch Pad*/}
                        {isCheckedTouchOne || isCheckedTouchOneOutput ? (
                            <img
                                src={renderPrgImage(
                                    'PcinternalTouchpadsActive'
                                )}
                                style={{
                                    height: '14%',
                                    width: '8%',
                                    position: 'absolute',
                                    left: '30%',
                                    bottom: '4.5%',
                                    transform: `translate(-30%,0%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage(
                                    'PcinternalTouchpadsInActive'
                                )}
                                style={{
                                    height: '14%',
                                    width: '8%',
                                    position: 'absolute',
                                    left: '30%',
                                    bottom: '4.5%',
                                    transform: `translate(-30%,0%)`,
                                }}
                            />
                        )}

                        {/*  2 Touch Pad*/}
                        {isCheckedTouchTwo || isCheckedTouchTwoOutput ? (
                            <img
                                src={renderPrgImage(
                                    'PcinternalTouchpadsActive'
                                )}
                                style={{
                                    height: '14%',
                                    width: '8%',
                                    position: 'absolute',
                                    left: '48.5%',
                                    bottom: '4.5%',
                                    transform: `translate(-30%,0%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage(
                                    'PcinternalTouchpadsInActive'
                                )}
                                style={{
                                    height: '14%',
                                    width: '8%',
                                    position: 'absolute',
                                    left: '48.5%',
                                    bottom: '4.5%',
                                    transform: `translate(-30%,0%)`,
                                }}
                            />
                        )}

                        <p
                            style={{
                                fontSize: '18px',
                                height: '5%',
                                width: '20%',
                                position: 'absolute',
                                top: '100%',
                                left: '21%',
                                transform: 'translate(-50%,-50%)',
                                color: '#707070',
                            }}
                        >
                            {isCheckedTouchZero ? this.props.zero : null}
                            {/* {this.props.responceTp0} */}
                        </p>

                        <p
                            style={{
                                fontSize: '18px',
                                // backgroundColor: "blue",
                                height: '5%',
                                width: '20%',
                                position: 'absolute',
                                top: '100%',
                                left: '35%',
                                transform: 'translate(-50%,-50%)',
                                color: '#707070',
                            }}
                        >
                            {isCheckedTouchOne ? this.props.one : null}
                        </p>
                        <p
                            style={{
                                fontSize: '18px',
                                height: '5%',
                                width: '20%',
                                position: 'absolute',
                                top: '100%',
                                left: '55%',
                                transform: 'translate(-50%,-50%)',
                                color: '#707070',
                            }}
                        >
                            {/* {this.props.responceTp2} */}
                            {isCheckedTouchTwo ? this.props.two : null}
                        </p>
                        <p
                            style={{
                                fontSize: '18px',
                                // height: "5%",
                                // width: "5%",
                                position: 'absolute',
                                top: '85%',
                                marginTop: '-102.5%',
                                marginLeft: '68%',
                                color: '#707070',
                            }}
                        >
                            {isCheckedMic ? this.props.mic : null}
                        </p>
                    </div>
                )
            }
        } else if (Device == 'Ace' && shield == 'true') {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                return connectDragSource(
                    <div
                        id="PC_dragSource"
                        style={{
                            ...style,
                            left,
                            top,
                            height: '382.8px',
                            width: '345px',
                            backgroundImage: `url("${Url}")`,
                            overflow: 'visible',
                        }}
                        onDoubleClick={() => this.typeCheck(this)}
                    >
                        <img
                            src={renderPrgImage(
                                isCheckedMic
                                    ? 'PcinternalMicActive'
                                    : 'PcinternalMicInActive'
                            )}
                            style={{
                                height: '25%',
                                width: '15%',
                                position: 'absolute',
                                top: '-7%',
                                left: '49.5%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        />

                        {/*RIGHT EYE  */}

                        <div>
                            {[33, 38.5, 45, 51, 56.5, 63].map(
                                (value, index) => {
                                    return (
                                        <>
                                            <img
                                                src={renderPrgImage(
                                                    isCheckedEye
                                                        ? 'PcinternalEYEActive_v1'
                                                        : 'PcinternalEYEInActive_v1'
                                                )}
                                                style={{
                                                    height: '6%',
                                                    width: '3.5%',
                                                    position: 'absolute',
                                                    top: '28%',
                                                    left: `${value}%`,
                                                }}
                                            />
                                            <img
                                                src={renderPrgImage(
                                                    isCheckedEye
                                                        ? 'PcinternalEYEActive_v1'
                                                        : 'PcinternalEYEInActive_v1'
                                                )}
                                                style={{
                                                    height: '6%',
                                                    width: '3.5%',
                                                    position: 'absolute',
                                                    top: '31.8%',
                                                    left: `${value}%`,
                                                }}
                                            />
                                        </>
                                    )
                                }
                            )}
                        </div>

                        {/* 1-teeth Active*/}
                        <img
                            src={renderPrgImage(
                                isCheckedSimeleOne
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '3.8%',
                                width: '2%',
                                position: 'absolute',
                                top: '48.5%',
                                left: '43.6%',
                                transform: `translate(-40.4%,-60%)`,
                            }}
                        />

                        {/* 2-teeth Active*/}
                        <img
                            src={renderPrgImage(
                                isCheckedSimeleTwo
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '3.8%',
                                width: '2%',
                                position: 'absolute',
                                top: '49.5%',
                                left: '46%',
                                transform: `translate(-43.5%,-61%)`,
                            }}
                        />

                        {/* 4-in-1 Sensor  */}
                        <img
                            src={renderPrgImage(
                                isCheckedColorSensor ||
                                    isCheckedDistanceSensors ||
                                    isCheckedGestureSensor ||
                                    isCheckedLightSensor
                                    ? 'Pcinternal4in1Active_v1'
                                    : 'Pcinternal4in1InActive_v1'
                            )}
                            style={{
                                height: '5%',
                                width: '8%',
                                position: 'absolute',
                                left: '48%',
                                top: '49.8%',
                                transform: `translate(-30%,-61%)`,
                            }}
                        />

                        {/* 3-teeth Active*/}
                        <img
                            src={renderPrgImage(
                                isCheckedSimeleThree
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '3.8%',
                                width: '2%',
                                position: 'absolute',
                                top: '49.5%',
                                left: '53%',
                                transform: `translate(-57%,-61%)`,
                            }}
                        />

                        {/* 4-teeth Active*/}

                        <img
                            src={renderPrgImage(
                                isCheckedSimeleFour
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '3.8%',
                                width: '2%',
                                position: 'absolute',
                                top: '48.5%',
                                left: '55.5%',
                                transform: `translate(-60.3%,-60%)`,
                            }}
                        />

                        {/* mic */}
                        <p
                            style={{
                                fontSize: '18px',
                                position: 'absolute',
                                top: '85%',
                                marginTop: '-102.5%',
                                marginLeft: '68%',
                                color: '#707070',
                            }}
                        >
                            {isCheckedMic ? this.props.mic : null}
                        </p>
                    </div>
                )
            } else {
                return connectDragSource(
                    <div
                        id="PC_dragSource"
                        style={{
                            ...style,
                            left,
                            top,
                            height,
                            width,
                            backgroundImage: `url("${Url}")`,
                            overflow: 'visible',

                            // background: "red",
                        }}
                        onDoubleClick={() => this.typeCheck(this)}
                    >
                        {isCheckedMic ? (
                            <img
                                src={renderPrgImage('PcinternalMicActive')}
                                style={{
                                    height: '24%',
                                    width: '15%',
                                    marginTop: '-90px',
                                    marginLeft: '42%',
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalMicInActive')}
                                style={{
                                    height: '24%',
                                    width: '15%',
                                    marginTop: '-90px',
                                    marginLeft: '42%',
                                }}
                            />
                        )}

                        {/*LEFT EYE  */}
                        {isCheckedEyeLeft ? (
                            <img
                                src={renderPrgImage('PcinternalEYEActive')}
                                style={{
                                    height: '8%',
                                    width: '6.5%',
                                    position: 'absolute',
                                    top: '18.5%',
                                    left: '40%',
                                    transform: `translate(-34%,-31%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalEYEInActive')}
                                style={{
                                    height: '8%',
                                    width: '6.5%',
                                    position: 'absolute',
                                    top: '18.5%',
                                    left: '40%',
                                    transform: `translate(-34%,-31%)`,
                                }}
                            />
                        )}

                        {/*RIGHT EYE  */}
                        {isCheckedEyeRight ? (
                            <img
                                src={renderPrgImage('PcinternalEYEActive')}
                                style={{
                                    height: '8%',
                                    width: '6.5%',
                                    position: 'absolute',
                                    top: '18.5%',
                                    left: '58%',
                                    transform: `translate(-67.5%,-31%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalEYEInActive')}
                                style={{
                                    height: '8%',
                                    width: '6.5%',
                                    position: 'absolute',
                                    top: '18.5%',
                                    left: '58%',
                                    transform: `translate(-67.5%,-31%)`,
                                }}
                            />
                        )}

                        {/* 1-teeth Active*/}
                        {isCheckedSimeleOne ? (
                            <img
                                src={renderPrgImage('PcinternalTeethActive')}
                                style={{
                                    height: '3%',
                                    width: '1.9%',
                                    position: 'absolute',
                                    top: '34%',
                                    left: '43.4%',
                                    transform: `translate(-40.4%,-60%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalTeethInActive')}
                                style={{
                                    height: '3%',
                                    width: '1.9%',
                                    position: 'absolute',
                                    top: '34%',
                                    left: '43.4%',
                                    transform: `translate(-40.4%,-60%)`,
                                }}
                            />
                        )}
                        {/* 2-teeth Active*/}
                        {isCheckedSimeleTwo ? (
                            <img
                                src={renderPrgImage('PcinternalTeethActive')}
                                style={{
                                    height: '3%',
                                    width: '1.9%',
                                    position: 'absolute',
                                    top: '35%',
                                    left: '45.5%',
                                    transform: `translate(-43.5%,-61%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalTeethInActive')}
                                style={{
                                    height: '3%',
                                    width: '1.9%',
                                    position: 'absolute',
                                    top: '35%',
                                    left: '45.5%',
                                    transform: `translate(-43.5%,-61%)`,
                                }}
                            />
                        )}

                        {/* 4-in-1 Sensor  */}
                        {isCheckedColorSensor ||
                        isCheckedDistanceSensors ||
                        isCheckedGestureSensor ||
                        isCheckedLightSensor ? (
                            <img
                                src={renderPrgImage('Pcinternal4in1Active')}
                                style={{
                                    height: '3%',
                                    width: '4%',
                                    position: 'absolute',
                                    left: '48%',
                                    top: '35%',
                                    transform: `translate(-30%,-61%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('Pcinternal4in1InActive')}
                                style={{
                                    height: '3%',
                                    width: '4%',
                                    position: 'absolute',
                                    left: '48%',
                                    top: '35%',
                                    transform: `translate(-30%,-61%)`,
                                }}
                            />
                        )}

                        {/* 3-teeth Active*/}
                        {isCheckedSimeleThree ? (
                            <img
                                src={renderPrgImage('PcinternalTeethActive')}
                                style={{
                                    height: '3%',
                                    width: '1.9%',
                                    position: 'absolute',
                                    top: '35%',
                                    left: '52.2%',
                                    transform: `translate(-57%,-61%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalTeethInActive')}
                                style={{
                                    height: '3%',
                                    width: '1.9%',
                                    position: 'absolute',
                                    top: '35%',
                                    left: '52.2%',
                                    transform: `translate(-57%,-61%)`,
                                }}
                            />
                        )}

                        {/* 4-teeth Active*/}
                        {isCheckedSimeleFour ? (
                            <img
                                src={renderPrgImage('PcinternalTeethActive')}
                                style={{
                                    height: '3%',
                                    width: '1.9%',
                                    position: 'absolute',
                                    top: '34%',
                                    left: '54.5%',
                                    transform: `translate(-60.3%,-60%)`,
                                }}
                            />
                        ) : (
                            <img
                                src={renderPrgImage('PcinternalTeethInActive')}
                                style={{
                                    height: '3%',
                                    width: '1.9%',
                                    position: 'absolute',
                                    top: '34%',
                                    left: '54.5%',
                                    transform: `translate(-60.3%,-60%)`,
                                }}
                            />
                        )}
                    </div>
                )
            }
        } else if (
            Device == 'Ace' &&
            (armShield == 'true' || peeCeeATR == 'true')
        ) {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                return connectDragSource(
                    <div
                        id="PC_dragSource"
                        style={{
                            ...style,
                            left,
                            top,
                            height: '382.8px',
                            width: '345px',
                            backgroundImage: `url("${Url}")`,
                            overflow: 'visible',
                        }}
                        onDoubleClick={() => this.typeCheck(this)}
                    >
                        <img
                            src={renderPrgImage(
                                isCheckedMic
                                    ? 'PcinternalMicActive'
                                    : 'PcinternalMicInActive'
                            )}
                            style={{
                                height: '20%',
                                width: '15%',
                                position: 'absolute',
                                top: '-0.5%',
                                left: '49.5%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        />

                        {/*RIGHT EYE  */}

                        <div>
                            {[37.5, 41.9, 46, 51, 55, 59.3].map(
                                (value, index) => {
                                    return (
                                        <>
                                            <img
                                                src={renderPrgImage(
                                                    isCheckedEye
                                                        ? 'PcinternalEYEActive_v1'
                                                        : 'PcinternalEYEInActive_v1'
                                                )}
                                                style={{
                                                    height: '6%',
                                                    width: '2.5%',
                                                    position: 'absolute',
                                                    top: '25%',
                                                    left: `${value}%`,
                                                }}
                                            />
                                            <img
                                                src={renderPrgImage(
                                                    isCheckedEye
                                                        ? 'PcinternalEYEActive_v1'
                                                        : 'PcinternalEYEInActive_v1'
                                                )}
                                                style={{
                                                    height: '6%',
                                                    width: '2.5%',
                                                    position: 'absolute',
                                                    top: '27.8%',
                                                    left: `${value}%`,
                                                }}
                                            />
                                        </>
                                    )
                                }
                            )}
                        </div>

                        {/* 1-teeth Active*/}
                        <img
                            src={renderPrgImage(
                                isCheckedSimeleOne
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '2.8%',
                                width: '1.5%',
                                position: 'absolute',
                                top: '41%',
                                left: '45.6%',
                                transform: `translate(-40.4%,-60%)`,
                            }}
                        />

                        {/* 2-teeth Active*/}
                        <img
                            src={renderPrgImage(
                                isCheckedSimeleTwo
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '2.8%',
                                width: '1.5%',
                                position: 'absolute',
                                top: '41.5%',
                                left: '47.5%',
                                transform: `translate(-43.5%,-61%)`,
                            }}
                        />

                        {/* 4-in-1 Sensor  */}
                        <img
                            src={renderPrgImage(
                                isCheckedColorSensor ||
                                    isCheckedDistanceSensors ||
                                    isCheckedGestureSensor ||
                                    isCheckedLightSensor
                                    ? 'Pcinternal4in1Active_v1'
                                    : 'Pcinternal4in1InActive_v1'
                            )}
                            style={{
                                height: '3%',
                                width: '5%',
                                position: 'absolute',
                                left: '49%',
                                top: '41.6%',
                                transform: `translate(-30%,-61%)`,
                            }}
                        />

                        {/* 3-teeth Active*/}
                        <img
                            src={renderPrgImage(
                                isCheckedSimeleThree
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '2.8%',
                                width: '1.5%',
                                position: 'absolute',
                                top: '41.4%',
                                left: '52.5%',
                                transform: `translate(-57%,-61%)`,
                            }}
                        />

                        {/* 4-teeth Active*/}

                        <img
                            src={renderPrgImage(
                                isCheckedSimeleFour
                                    ? 'PcinternalTeethActive_v1'
                                    : 'PcinternalTeethInActive_v1'
                            )}
                            style={{
                                height: '2.8%',
                                width: '1.5%',
                                position: 'absolute',
                                top: '41%',
                                left: '54.2%',
                                transform: `translate(-60.3%,-60%)`,
                            }}
                        />

                        {/* mic */}
                        <p
                            style={{
                                fontSize: '18px',
                                position: 'absolute',
                                top: '85%',
                                marginTop: '-102.5%',
                                marginLeft: '68%',
                                color: '#707070',
                            }}
                        >
                            {isCheckedMic ? this.props.mic : null}
                        </p>
                    </div>
                )
            } else {
                return connectDragSource(
                    <div
                        id="PC_dragSource"
                        style={{
                            ...style,
                            left,
                            top,
                            height,
                            width,
                            backgroundImage: `url("${Url}")`,
                            overflow: 'visible',

                            // background: "red",
                        }}
                        onDoubleClick={() => this.typeCheck(this)}
                    >
                        <img
                            src={
                                isCheckedMic
                                    ? renderPrgImage('PcinternalMicActive')
                                    : renderPrgImage('PcinternalMicInActive')
                            }
                            style={
                                peeCeeATR == 'true'
                                    ? {
                                          height: '22%',
                                          width: '15%',
                                          marginTop: '-92px',
                                          marginLeft: '42.5%',
                                      }
                                    : {
                                          height: '26%',
                                          width: '15%',
                                          marginTop: '-84px',
                                          marginLeft: '41.5%',
                                      }
                            }
                        />

                        {/*LEFT EYE  */}
                        <img
                            src={
                                isCheckedEyeLeft
                                    ? renderPrgImage('PcinternalEYEActive')
                                    : renderPrgImage('PcinternalEYEInActive')
                            }
                            style={
                                peeCeeATR == 'true'
                                    ? {
                                          height: '6%',
                                          width: '8%',
                                          position: 'absolute',
                                          top: '18%',
                                          left: '40%',
                                          transform: `translate(-34%,-31%)`,
                                      }
                                    : {
                                          height: '5%',
                                          width: '8%',
                                          position: 'absolute',
                                          top: '18%',
                                          left: '38.5%',
                                          transform: `translate(-34%,-31%)`,
                                      }
                            }
                        />

                        {/*RIGHT EYE  */}
                        <img
                            src={
                                isCheckedEyeRight
                                    ? renderPrgImage('PcinternalEYEActive')
                                    : renderPrgImage('PcinternalEYEInActive')
                            }
                            style={
                                peeCeeATR == 'true'
                                    ? {
                                          height: '6%',
                                          width: '8%',
                                          position: 'absolute',
                                          top: '18%',
                                          left: '61%',
                                          transform: `translate(-67.5%,-31%)`,
                                      }
                                    : {
                                          height: '5%',
                                          width: '8%',
                                          position: 'absolute',
                                          top: '18%',
                                          left: '59%',
                                          transform: `translate(-67.5%,-31%)`,
                                      }
                            }
                        />

                        {/* 1-teeth Active*/}
                        <img
                            src={
                                isCheckedSimeleOne
                                    ? renderPrgImage('PcinternalTeethActive')
                                    : renderPrgImage('PcinternalTeethInActive')
                            }
                            style={{
                                height: '4%',
                                width: '1.7%',
                                position: 'absolute',
                                top: '35%',
                                left: '42.5%',
                                transform: `translate(-40.4%,-60%)`,
                            }}
                        />

                        {/* 2-teeth Active*/}
                        <img
                            src={
                                isCheckedSimeleTwo
                                    ? renderPrgImage('PcinternalTeethActive')
                                    : renderPrgImage('PcinternalTeethInActive')
                            }
                            style={{
                                height: '4%',
                                width: '1.7%',
                                position: 'absolute',
                                top: '36%',
                                left: '44.5%',
                                transform: `translate(-43.5%,-61%)`,
                            }}
                        />

                        {/* 4-in-1 Sensor  */}
                        <img
                            src={
                                isCheckedColorSensor ||
                                isCheckedDistanceSensors ||
                                isCheckedGestureSensor ||
                                isCheckedLightSensor
                                    ? renderPrgImage('Pcinternal4in1Active')
                                    : renderPrgImage('Pcinternal4in1InActive')
                            }
                            style={
                                peeCeeATR == 'true'
                                    ? {
                                          height: '2.5%',
                                          width: '7%',
                                          position: 'absolute',
                                          left: '49.2%',
                                          top: '36%',
                                          transform: `translate(-30%,-61%)`,
                                      }
                                    : {
                                          height: '2%',
                                          width: '4%',
                                          position: 'absolute',
                                          left: '48%',
                                          top: '35.5%',
                                          transform: `translate(-30%,-61%)`,
                                      }
                            }
                        />

                        {/* 3-teeth Active*/}
                        <img
                            src={
                                isCheckedSimeleThree
                                    ? renderPrgImage('PcinternalTeethActive')
                                    : renderPrgImage('PcinternalTeethInActive')
                            }
                            style={
                                peeCeeATR == 'true'
                                    ? {
                                          height: '4%',
                                          width: '1.7%',
                                          position: 'absolute',
                                          top: '36%',
                                          left: '54.5%',
                                          transform: `translate(-57%,-61%)`,
                                      }
                                    : {
                                          height: '4%',
                                          width: '1.7%',
                                          position: 'absolute',
                                          top: '36%',
                                          left: '52.5%',
                                          transform: `translate(-57%,-61%)`,
                                      }
                            }
                        />

                        {/* 4-teeth Active*/}
                        <img
                            src={
                                isCheckedSimeleFour
                                    ? renderPrgImage('PcinternalTeethActive')
                                    : renderPrgImage('PcinternalTeethInActive')
                            }
                            style={
                                peeCeeATR == 'true'
                                    ? {
                                          height: '4%',
                                          width: '1.7%',
                                          position: 'absolute',
                                          top: '35.7%',
                                          left: '56.7%',
                                          transform: `translate(-60.3%,-60%)`,
                                      }
                                    : {
                                          height: '4%',
                                          width: '1.7%',
                                          position: 'absolute',
                                          top: '34.7%',
                                          left: '54.7%',
                                          transform: `translate(-60.3%,-60%)`,
                                      }
                            }
                        />
                    </div>
                )
            }
        } else if (Device == 'Humanoid') {
            return connectDragSource(
                // <img
                //     className="user-select"
                //     src={Url}
                //     id="Zing_DragSource"
                //     style={{
                //         ...style,
                //         left,
                //         top,
                //         height,
                //         width,
                //     }}
                // />
                <div
                    className="user-select"
                    id="Zing_DragSource"
                    style={{
                        ...style,
                        left,
                        top,
                        height,
                        width,
                        backgroundImage: `url("${Url}")`,
                        overflow: 'visible',

                        // background: "red",
                    }}
                    // onDoubleClick={() => this.typeCheck(this)}
                >
                    <img
                        src={renderImage(
                            humanoidPortData[0].isPortSelected
                                ? 'activeEye'
                                : 'inactiveEye'
                        )}
                        // className={selectStyle['select-leftEye']}
                        style={{
                            position: 'absolute',
                            height: '3.2%',
                            width: '4.4%',
                            top: '14.6%',
                            left: '45.9%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={renderImage(
                            humanoidPortData[1].isPortSelected
                                ? 'activeEye'
                                : 'inactiveEye'
                        )}
                        // className={selectStyle['select-leftEye']}
                        style={{
                            position: 'absolute',
                            height: '3.2%',
                            width: '4.4%',
                            top: '14.6%',
                            left: '54.2%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                </div>
            )
        } else if (Device == 'Hexapod') {
            left = left + 47
            top = top + 50
            return connectDragSource(
                <img
                    className="user-select"
                    src={Url}
                    id="hexapod_DragSource"
                    style={{
                        ...style,
                        left,
                        top,
                        height,
                        width,
                    }}
                />
            )
        } else if (Device === 'Roboki') {
            return connectDragSource(
                <div
                    id="PC_dragSource"
                    style={{
                        ...style,
                        left,
                        top,
                        backgroundImage: `url("${Url}")`,
                        overflow: 'visible',
                        height: '300px',
                        width: '285px',
                    }}
                >
                    <img
                        src={renderPrgImage(
                            isCheckedEyeLeft
                                ? 'PcinternalEYEActive_v1'
                                : 'PcinternalEYEInActive_v1'
                        )}
                        style={{
                            // height: '25%',
                            width: '6%',
                            position: 'absolute',
                            top: '38.5%',
                            left: '44.6%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    />
                    <img
                        src={renderPrgImage(
                            isCheckedEyeRight
                                ? 'PcinternalEYEActive_v1'
                                : 'PcinternalEYEInActive_v1'
                        )}
                        style={{
                            // height: '25%',
                            width: '6%',
                            position: 'absolute',
                            top: '38.5%',
                            left: '54.6%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    />
                    <p
                        style={{
                            fontSize: '18px',
                            height: '5%',
                            width: '20%',
                            position: 'absolute',
                            top: '30%',
                            left: '14%',
                            transform: 'translate(-50%,-50%)',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.responceTp2} */}
                        {isSwitch1 ? this.props.switch1 : null}
                    </p>
                    <p
                        style={{
                            fontSize: '18px',
                            height: '5%',
                            width: '20%',
                            position: 'absolute',
                            top: '30%',
                            left: '99%',
                            transform: 'translate(-50%,-50%)',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.responceTp2} */}
                        {isSwitch2 ? this.props.switch2 : null}
                    </p>
                </div>
            )
        } else if (Device === 'Toki') {
            // --------- Toki image assembley page---------------
            return connectDragSource(
                <div
                    id="PC_dragSource"
                    style={{
                        ...style,
                        left,
                        top,
                        backgroundImage: `url("${Url}")`,
                        overflow: 'visible',
                        height: '300px',
                        width: '285px',
                    }}
                ></div>
            )
        } else if (Device == 'Klaw') {
            width = width + 20
            //+ 320
            // height = height - 50
            //+ 100
            // top = top + 50
            // left = left - 15
            return connectDragSource(
                <img
                    className="user-select"
                    src={Url}
                    id="Klaw_DragSource"
                    style={{
                        ...style,
                        left,
                        top,
                        height,
                        width,
                    }}
                />
            )
        } else {
            return connectDragSource(
                <img
                    className="user-select"
                    src={Url}
                    id="biboxClass"
                    style={{
                        ...style,
                        left,
                        top,
                        height,
                        width,
                        // border: "1px solid red",
                    }}
                />
            )
        }
    }
}
// });

export default DragSource(ItemTypes.BIBOX, biboxSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}))(Bibox)
