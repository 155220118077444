import React, { Component, useState } from 'react'

import Ports from './PortData'
import ItemTypes from './ItemTypes'
import ImageSizes from './ImageSizes'
import PortTypes from './PortTypes'

import AllowedPortTypes from './AllowedPortTypes'

import DraggingInfo from './DraggingInfo'
import { PORTDATA } from '../Humanoid/defaultData'

// var Device=JSON.stringify(sessionStorage.getItem("connectedDevice"));
var Device = sessionStorage.getItem('connectedDevice')

let internalAccessoriesData = JSON.parse(
    sessionStorage.getItem('concept')
).internalaccessories

class Curve extends Component {
    // shouldComponentUpdate(nextProps, nextState, prev) {
    //     return JSON.stringify(nextProps.bibox) !== JSON.stringify(this.props.bibox) || JSON.stringify(nextProps.component) !== JSON.stringify(this.props.component) || JSON.stringify(nextProps.components) !== JSON.stringify(this.props.components) || JSON.stringify(nextProps.PortConnections) !== JSON.stringify(this.props.PortConnections) || JSON.stringify(nextProps.extraComponent) !== JSON.stringify(this.props.extraComponent);
    // }

    render() {
        Device = sessionStorage.getItem('connectedDevice')
        let deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
        const { bibox, component, components, PortConnections } = this.props
        const { connectedTo, left, top } = component
        const sessionStoragePortConnections = JSON.parse(
            sessionStorage.getItem('assembly')
        ).PortConnections
        var Left, Top
        var LeftF, TopF
        var LeftO, TopO
        var LeftB, TopB
        var LeftC, TopC
        var LeftCL, TopCL
        var LeftBL, TopBL
        var LeftG, TopG
        var cx2, cy2, cx1, cy1
        var LeftS, TopS
        var LeftSS, TopSS

        const isDeviceVersion12 =
            (['1', '2'].includes(deviceVersion[0]) &&
                ['Ace', 'Humanoid', 'Hexapod'].includes(Device)) ||
            ['Roboki', 'Klaw', 'Toki'].includes(Device)

        if (
            ['Ace', 'Humanoid', 'Hexapod', 'Klaw', 'Roboki', 'Toki'].includes(
                Device
            )
        ) {
            // GearedMotor A1 C1  && B1 D1
            let A1connectedGeared_motor =
                sessionStoragePortConnections.A1 !== null
                    ? [
                          'geared_motor',
                          'mini_geared_motor',
                          'dc_motor',
                          'dynamex_motor',
                      ].includes(sessionStoragePortConnections.A1.type)
                        ? true
                        : false
                    : false

            let C1connectedGeared_motor =
                sessionStoragePortConnections.C1 !== null
                    ? [
                          'geared_motor',
                          'mini_geared_motor',
                          'dc_motor',
                          'dynamex_motor',
                      ].includes(sessionStoragePortConnections.C1.type)
                        ? true
                        : false
                    : false

            let B1connectedGeared_motor =
                sessionStoragePortConnections.B1 !== null
                    ? [
                          'geared_motor',
                          'mini_geared_motor',
                          'dc_motor',
                          'dynamex_motor',
                      ].includes(sessionStoragePortConnections.B1.type)
                        ? true
                        : false
                    : false

            let D1connectedGeared_motor =
                sessionStoragePortConnections.D1 !== null
                    ? [
                          'geared_motor',
                          'mini_geared_motor',
                          'dc_motor',
                          'dynamex_motor',
                      ].includes(sessionStoragePortConnections.D1.type)
                        ? true
                        : false
                    : false

            // SERVO MOTORS [A1,A2,C1,C2] [B1,B2,C1,C2]

            let A1connectedServoMotor =
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .A1 !== null
                    ? JSON.parse(sessionStorage.getItem('assembly'))
                          .PortConnections.A1.type == 'servo_motor'
                        ? true
                        : false
                    : false

            let A2connectedServoMotor =
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .A2 !== null
                    ? JSON.parse(sessionStorage.getItem('assembly'))
                          .PortConnections.A2.type == 'servo_motor'
                        ? true
                        : false
                    : false

            let C1connectedServoMotor =
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .C1 !== null
                    ? JSON.parse(sessionStorage.getItem('assembly'))
                          .PortConnections.C1.type == 'servo_motor'
                        ? true
                        : false
                    : false

            let C2connectedServoMotor =
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .C2 !== null
                    ? JSON.parse(sessionStorage.getItem('assembly'))
                          .PortConnections.C2.type == 'servo_motor'
                        ? true
                        : false
                    : false

            let D1connectedServoMotor =
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .D1 !== null
                    ? JSON.parse(sessionStorage.getItem('assembly'))
                          .PortConnections.D1.type == 'servo_motor'
                        ? true
                        : false
                    : false

            let D2connectedServoMotor =
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .D2 !== null
                    ? JSON.parse(sessionStorage.getItem('assembly'))
                          .PortConnections.D2.type == 'servo_motor'
                        ? true
                        : false
                    : false

            let B1connectedServoMotor =
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .B1 !== null
                    ? JSON.parse(sessionStorage.getItem('assembly'))
                          .PortConnections.B1.type == 'servo_motor'
                        ? true
                        : false
                    : false

            let B2connectedServoMotor =
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .B2 !== null
                    ? JSON.parse(sessionStorage.getItem('assembly'))
                          .PortConnections.B2.type == 'servo_motor'
                        ? true
                        : false
                    : false
            //*************************************************** */

            if (
                (connectedTo == 'A1' || connectedTo == 'A2') &&
                !['Toki'].includes(sessionStorage.getItem('connectedDevice'))
            ) {
                Object.keys(components).map((dual_splitter) => {
                    if (dual_splitter == 'dual_splitter') {
                        components[dual_splitter].map((component, index) => {
                            if (
                                ![
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dc_motor',
                                    'dynamex_motor',
                                ].includes(this.props.componentName)
                                // this.props.componentName != 'geared_motor' &&
                                // this.props.componentName !=
                                //     'mini_geared_motor' &&
                                // this.props.componentName != 'dc_motor' &&
                                // this.props.componentName != 'dynamex_motor'
                            ) {
                                if (component.connectedTo == 'A') {
                                    LeftF = component.left
                                    TopF = component.top
                                }
                                if (
                                    component.connectedTo == 'A' &&
                                    (this.props.shield || this.props.armShield)
                                ) {
                                    if (connectedTo == 'A1') {
                                        LeftF = component.left - 87
                                        TopF = component.top - 252
                                    }
                                    if (connectedTo == 'A2') {
                                        LeftF = component.left - 32
                                        TopF = component.top - 285
                                    }
                                }
                            } else {
                                if (component.connectedTo == 'A') {
                                    LeftF = component.left + 28
                                    TopF = isDeviceVersion12
                                        ? component.top + 30
                                        : component.top + 6
                                }
                                if (
                                    component.connectedTo == 'A' &&
                                    (this.props.shield || this.props.armShield)
                                ) {
                                    LeftF = component.left - 60
                                    TopF = isDeviceVersion12
                                        ? component.top - 218
                                        : component.top - 248
                                }
                            }
                        })
                        if (connectedTo == 'A1') {
                            cx1 = left + ImageSizes[ItemTypes.COMPONENT][0] / 2
                            cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                            cx2 = LeftF + Ports(connectedTo)[0]
                            cy2 = TopF + Ports(connectedTo)[1]
                            cy2 -= 50
                        }
                        if (connectedTo == 'A2') {
                            cx1 = left + ImageSizes[ItemTypes.COMPONENT][0] / 2
                            cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                            cx2 = LeftF + Ports(connectedTo)[0]
                            cy2 = TopF + Ports(connectedTo)[1]
                            cy2 -= 50
                        }
                    }
                    if (dual_splitter == 'play_shield') {
                        components[dual_splitter].map((component, index) => {
                            if (
                                ![
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dc_motor',
                                    'dynamex_motor',
                                    'servo_motor',
                                    'servo_motor_270',
                                    'servo_motor_360',
                                ].includes(this.props.componentName)
                                // this.props.componentName != 'geared_motor' &&
                                // this.props.componentName !=
                                //     'mini_geared_motor' &&
                                // this.props.componentName != 'dc_motor' &&
                                // this.props.componentName != 'dynamex_motor' &&
                                // this.props.componentName != 'servo_motor' &&
                                // this.props.componentName != 'servo_motor_270' &&
                                // this.props.componentName != 'servo_motor_360'
                            ) {
                                if (
                                    PortConnections.A == null &&
                                    sessionStorage.getItem('dragingItem') !=
                                        null
                                ) {
                                    if (connectedTo == 'A1') {
                                        LeftF = bibox.left
                                        TopF = bibox.top
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                        cx2 -= 150
                                        cx1 -= 50
                                    }
                                    if (connectedTo == 'A2') {
                                        LeftF = bibox.left
                                        TopF = bibox.top
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                        cx2 -= 150
                                        cx1 -= 50
                                    }
                                }
                            }
                        })
                    }
                    /** */
                    if (dual_splitter == 'pc_motor_driver') {
                        let sdasa = sessionStorage.getItem('dragingItem')

                        components[dual_splitter].map((component, index) => {
                            if (
                                component.connectedTo == 'A' ||
                                component.connectedTo == 'C'
                            ) {
                                // not run initial  it will run after the
                                if (
                                    A1connectedServoMotor == true ||
                                    A2connectedServoMotor == true
                                ) {
                                    if (connectedTo == 'A2') {
                                        LeftF = component.left - 70
                                        TopF = component.top
                                    } else {
                                        LeftF = component.left - 30
                                        TopF = component.top
                                    }
                                } else if (
                                    [
                                        'geared_motor',
                                        'mini_geared_motor',
                                        'dc_motor',
                                        'dynamex_motor',
                                    ].includes(
                                        sessionStorage.getItem('dragingItem')
                                    )
                                    // sessionStorage.getItem('dragingItem') ==
                                    //     'geared_motor' ||
                                    // sessionStorage.getItem('dragingItem') ==
                                    //     'mini_geared_motor' ||
                                    // sessionStorage.getItem('dragingItem') ==
                                    //     'dc_motor' ||
                                    // sessionStorage.getItem('dragingItem') ==
                                    //     'dynamex_motor'
                                ) {
                                    LeftF = component.left - 70
                                    TopF = component.top + 30
                                } else if (
                                    sessionStorage.getItem('dragingItem') ==
                                        'servo_motor' &&
                                    !A1connectedGeared_motor &&
                                    !C1connectedGeared_motor
                                ) {
                                    // this is for when we drag servo_motor  changing the postion at drag time

                                    if (connectedTo == 'A2') {
                                        LeftF = component.left - 70
                                        TopF = component.top
                                    } else {
                                        LeftF = component.left - 30
                                        TopF = component.top
                                    }
                                } else {
                                    LeftF = component.left - 70
                                    TopF = component.top + 30
                                }
                            }
                        })
                        cx1 = left + ImageSizes[ItemTypes.COMPONENT][0] / 2
                        cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                        cx2 = LeftF + Ports(connectedTo)[0]
                        cy2 = TopF + Ports(connectedTo)[1]
                        cx2 +=
                            Ports(connectedTo)[0] -
                            ImageSizes[ItemTypes.COMPONENT][0] / 2
                        cy2 +=
                            Ports(connectedTo)[1] -
                            ImageSizes[ItemTypes.COMPONENT][1] / 2
                    }
                    /** */
                })

                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            cx1 +
                            ',' +
                            cy1 +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (connectedTo == 'D1' || connectedTo == 'D2') {
                Object.keys(components).map((dual_splitter) => {
                    if (dual_splitter == 'dual_splitter') {
                        components[dual_splitter].map((component, index) => {
                            if (
                                ![
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dc_motor',
                                    'dynamex_motor',
                                ].includes(this.props.componentName)
                                // this.props.componentName != 'geared_motor' &&
                                // this.props.componentName !=
                                //     'mini_geared_motor' &&
                                // this.props.componentName != 'dc_motor' &&
                                // this.props.componentName != 'dynamex_motor'
                            ) {
                                if (component.connectedTo == 'D') {
                                    LeftF = component.left
                                    TopF = component.top
                                    cx2 = LeftF + Ports(connectedTo)[0]
                                    cy2 = TopF + Ports(connectedTo)[1]
                                    cx2 +=
                                        Ports(connectedTo)[0] -
                                        ImageSizes[ItemTypes.COMPONENT][0] / 2
                                    cy2 +=
                                        Ports(connectedTo)[1] -
                                        ImageSizes[ItemTypes.COMPONENT][1] / 2
                                }
                                if (
                                    component.connectedTo == 'D' &&
                                    (this.props.shield || this.props.armShield)
                                ) {
                                    if (connectedTo == 'D1') {
                                        LeftF = component.left - 230
                                        TopF = component.top - 319
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                    }
                                    if (connectedTo == 'D2') {
                                        LeftF = component.left - 139
                                        TopF = component.top - 315
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                    }
                                }
                            } else {
                                if (component.connectedTo == 'D') {
                                    LeftF = component.left + 28
                                    TopF = isDeviceVersion12
                                        ? component.top + 30
                                        : component.top + 6
                                }
                                if (
                                    component.connectedTo == 'D' &&
                                    (this.props.shield || this.props.armShield)
                                ) {
                                    LeftF = component.left - 200
                                    TopF = isDeviceVersion12
                                        ? component.top - 284
                                        : component.top - 314
                                }
                                cx2 = LeftF + Ports(connectedTo)[0]
                                cy2 = TopF + Ports(connectedTo)[1]
                                cy2 -= 50
                            }
                        })
                    }

                    if (dual_splitter == 'play_shield') {
                        components[dual_splitter].map((component, index) => {
                            if (
                                [
                                    'servo_motor',
                                    'servo_motor_270',
                                    'servo_motor_360',
                                ].includes(this.props.componentName)
                            ) {
                                if (
                                    (connectedTo == 'D1' ||
                                        connectedTo == 'D2') &&
                                    PortConnections.D == null
                                ) {
                                    if (
                                        sessionStorage.getItem('dragingItem') !=
                                        null
                                    ) {
                                        LeftF = bibox.left
                                        TopF = bibox.top
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 100
                                        cx2 += 150
                                    }
                                }
                            }
                        })
                    }
                    if (dual_splitter == 'pc_motor_driver') {
                        components[dual_splitter].map((component, index) => {
                            if (
                                component.connectedTo == 'B' ||
                                component.connectedTo == 'D'
                            ) {
                                if (
                                    D1connectedServoMotor == true ||
                                    D2connectedServoMotor == true
                                ) {
                                    if (connectedTo == 'D2') {
                                        LeftF = component.left + 20
                                        TopF = component.top
                                    } else {
                                        LeftF = component.left + 60
                                        TopF = component.top
                                    }
                                } else if (
                                    sessionStorage.getItem('dragingItem') ==
                                        'servo_motor' &&
                                    !B1connectedGeared_motor &&
                                    !D1connectedGeared_motor
                                ) {
                                    // this is for when we drag servo_motor  changing the postion at drag time
                                    if (connectedTo == 'D2') {
                                        LeftF = component.left + 20
                                        TopF = component.top
                                    } else {
                                        LeftF = component.left + 60
                                        TopF = component.top
                                    }
                                } else {
                                    LeftF = component.left + 125
                                    TopF = component.top + 30
                                }
                            }
                        })
                    }
                })

                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            (left +
                                50 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (connectedTo == 'STPM') {
                Object.keys(components).map((dual_splitter) => {
                    if (dual_splitter == 'pc_motor_driver') {
                        components[dual_splitter].map((component, index) => {
                            if (
                                component.connectedTo == 'B' ||
                                component.connectedTo == 'D' ||
                                component.connectedTo == 'A' ||
                                component.connectedTo == 'C'
                            ) {
                                LeftF = component.left
                                TopF = component.top
                            }
                        })
                    }
                })

                cx2 = LeftF + Ports(connectedTo)[0]
                cy2 = TopF + Ports(connectedTo)[1]
                cx2 +=
                    Ports(connectedTo)[0] -
                    ImageSizes[ItemTypes.COMPONENT][0] / 2
                cy2 +=
                    Ports(connectedTo)[1] -
                    ImageSizes[ItemTypes.COMPONENT][1] / 2
                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            (left + ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (
                (connectedTo == 'C1' || connectedTo == 'C2') &&
                !['Toki'].includes(sessionStorage.getItem('connectedDevice'))
            ) {
                Object.keys(components).map((dual_splitter) => {
                    if (dual_splitter == 'dual_splitter') {
                        components[dual_splitter].map((component, index) => {
                            if (
                                ![
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dc_motor',
                                    'dynamex_motor',
                                ].includes(this.props.componentName)
                                // this.props.componentName != 'geared_motor' &&
                                // this.props.componentName !=
                                //     'mini_geared_motor' &&
                                // this.props.componentName != 'dc_motor' &&
                                // this.props.componentName != 'dynamex_motor'
                            ) {
                                if (component.connectedTo == 'C') {
                                    if (connectedTo == 'C1') {
                                        LeftF = component.left
                                        TopF = component.top
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cx2 +=
                                            Ports(connectedTo)[0] -
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy2 +=
                                            Ports(connectedTo)[1] -
                                            ImageSizes[ItemTypes.COMPONENT][1] /
                                                2
                                    }
                                    if (connectedTo == 'C2') {
                                        LeftF = component.left
                                        TopF = component.top
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cx2 +=
                                            Ports(connectedTo)[0] -
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy2 +=
                                            Ports(connectedTo)[1] -
                                            ImageSizes[ItemTypes.COMPONENT][1] /
                                                2
                                    }
                                }
                                if (
                                    component.connectedTo == 'C' &&
                                    (this.props.shield || this.props.armShield)
                                ) {
                                    if (connectedTo == 'C1') {
                                        LeftF = component.left - 125
                                        TopF = component.top - 320
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                    }
                                    if (connectedTo == 'C2') {
                                        LeftF = component.left - 105
                                        TopF = component.top - 315
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                    }
                                }
                            } else {
                                if (component.connectedTo == 'C') {
                                    LeftF = component.left + 28
                                    TopF = isDeviceVersion12
                                        ? component.top + 30
                                        : component.top + 6
                                }
                                if (
                                    component.connectedTo == 'C' &&
                                    (this.props.shield || this.props.armShield)
                                ) {
                                    LeftF = component.left - 100
                                    TopF = isDeviceVersion12
                                        ? component.top - 284
                                        : component.top - 315
                                }
                                cx1 =
                                    left +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2
                                cy1 =
                                    top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                                cx2 = LeftF + Ports(connectedTo)[0]
                                cy2 = TopF + Ports(connectedTo)[1]
                                cy2 -= 50
                            }
                        })
                    }
                    if (dual_splitter == 'play_shield') {
                        components[dual_splitter].map((component, index) => {
                            if (
                                [
                                    'servo_motor',
                                    'servo_motor_270',
                                    'servo_motor_360',
                                ].includes(this.props.componentName)
                            ) {
                                if (
                                    (connectedTo == 'C1' ||
                                        connectedTo == 'C2') &&
                                    PortConnections.C == null
                                ) {
                                    if (
                                        sessionStorage.getItem('dragingItem') !=
                                        null
                                    ) {
                                        LeftF = bibox.left
                                        TopF = bibox.top
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 10
                                        cx2 -= 150
                                        cx1 -= 50
                                    }
                                }
                            }
                        })
                    }

                    if (dual_splitter == 'pc_motor_driver') {
                        components[dual_splitter].map((component, index) => {
                            if (
                                component.connectedTo == 'A' ||
                                component.connectedTo == 'C'
                            ) {
                                if (
                                    C1connectedServoMotor == true ||
                                    C2connectedServoMotor == true
                                ) {
                                    if (connectedTo == 'C2') {
                                        LeftF = component.left + 20
                                        TopF = component.top
                                    } else {
                                        LeftF = component.left + 60
                                        TopF = component.top
                                    }
                                } else if (
                                    sessionStorage.getItem('dragingItem') ==
                                        'servo_motor' &&
                                    !A1connectedGeared_motor &&
                                    !C1connectedGeared_motor
                                ) {
                                    // this is for when we drag servo_motor  changing the postion at drag time
                                    if (connectedTo == 'C2') {
                                        LeftF = component.left + 20
                                        TopF = component.top
                                    } else {
                                        LeftF = component.left + 60
                                        TopF = component.top
                                    }
                                } else {
                                    LeftF = component.left + 125
                                    TopF = component.top + 30
                                }
                            }
                        })
                    }
                })

                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            cx1 +
                            ',' +
                            cy1 +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (
                (connectedTo == 'B1' || connectedTo == 'B2') &&
                !['Toki'].includes(sessionStorage.getItem('connectedDevice'))
            ) {
                Object.keys(components).map((key) => {
                    if (key == 'dual_splitter') {
                        // if(this.props.componentName = "")
                        components[key].map((component, index) => {
                            if (
                                ![
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dc_motor',
                                    'dynamex_motor',
                                ].includes(this.props.componentName)
                                // this.props.componentName != 'geared_motor' &&
                                // this.props.componentName !=
                                //     'mini_geared_motor' &&
                                // this.props.componentName != 'dc_motor' &&
                                // this.props.componentName != 'dynamex_motor'
                            ) {
                                if (component.connectedTo == 'B') {
                                    LeftF = component.left
                                    TopF = component.top
                                    cx1 =
                                        left +
                                        ImageSizes[ItemTypes.COMPONENT][0] / 2
                                    cy1 =
                                        top +
                                        2 * ImageSizes[ItemTypes.COMPONENT][1]
                                    cx2 = LeftF + Ports(connectedTo)[0]
                                    cy2 = TopF + Ports(connectedTo)[1]
                                    cx2 +=
                                        Ports(connectedTo)[0] -
                                        ImageSizes[ItemTypes.COMPONENT][0] / 2
                                    cy2 +=
                                        Ports(connectedTo)[1] -
                                        ImageSizes[ItemTypes.COMPONENT][1] / 2
                                }
                                if (
                                    component.connectedTo == 'B' &&
                                    (this.props.shield || this.props.armShield)
                                ) {
                                    if (connectedTo == 'B1') {
                                        LeftF = component.left - 268
                                        TopF = component.top - 256
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                    }
                                    if (connectedTo == 'B2') {
                                        LeftF = component.left - 212
                                        TopF = component.top - 287
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                    }
                                }
                            } else {
                                if (component.connectedTo == 'B') {
                                    LeftF = component.left + 28
                                    TopF = isDeviceVersion12
                                        ? component.top + 30
                                        : component.top + 6
                                }
                                if (
                                    component.connectedTo == 'B' &&
                                    (this.props.shield || this.props.armShield)
                                ) {
                                    LeftF = component.left - 240
                                    TopF = isDeviceVersion12
                                        ? component.top - 220
                                        : component.top - 250
                                }
                                cx1 =
                                    left +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2
                                cy1 =
                                    top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                                cx2 = LeftF + Ports(connectedTo)[0]
                                cy2 = TopF + Ports(connectedTo)[1]
                                cy2 -= 50
                            }
                        })
                    }
                    if (key == 'play_shield') {
                        components[key].map((component, index) => {
                            if (
                                ![
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dc_motor',
                                    'dynamex_motor',
                                    'servo_motor',
                                    'servo_motor_270',
                                    'servo_motor_360',
                                ].includes(this.props.componentName)
                                // this.props.componentName != 'geared_motor' &&
                                // this.props.componentName !=
                                //     'mini_geared_motor' &&
                                // this.props.componentName != 'dc_motor' &&
                                // this.props.componentName != 'servo_motor' &&
                                // this.props.componentName != 'servo_motor_270' &&
                                // this.props.componentName != 'servo_motor_360'
                            ) {
                                if (PortConnections.B == null) {
                                    if (
                                        connectedTo == 'B1' &&
                                        sessionStorage.getItem('dragingItem') !=
                                            null
                                    ) {
                                        LeftF = bibox.left + 4
                                        TopF = bibox.top + 2
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                        cx2 += 150
                                        cx1 += 50
                                    }

                                    if (
                                        connectedTo == 'B2' &&
                                        sessionStorage.getItem('dragingItem') !=
                                            null
                                    ) {
                                        LeftF = bibox.left + 4
                                        TopF = bibox.top + 2
                                        cx1 =
                                            left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2
                                        cy1 =
                                            top +
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]
                                        cx2 = LeftF + Ports(connectedTo)[0]
                                        cy2 = TopF + Ports(connectedTo)[1]
                                        cy2 -= 50
                                        cx2 += 150
                                        cx1 += 50
                                    }
                                }
                            }
                        })
                    }
                    if (key == 'servo_extender') {
                        components[key].map((component, index) => {
                            if (component.connectedTo == 'B') {
                                LeftF = component.left + 30
                                TopF = component.top + 5
                            }
                        })
                    }

                    let adads = sessionStorage.getItem('dragingItem')

                    if (key == 'pc_motor_driver') {
                        components[key].map((component, index) => {
                            if (
                                component.connectedTo == 'B' ||
                                component.connectedTo == 'D'
                            ) {
                                // not run initial  it will run after the
                                if (
                                    B1connectedServoMotor == true ||
                                    B2connectedServoMotor == true
                                ) {
                                    if (connectedTo == 'B2') {
                                        LeftF = component.left - 70
                                        TopF = component.top
                                    } else {
                                        LeftF = component.left - 30
                                        TopF = component.top
                                    }
                                } else if (
                                    [
                                        'geared_motor',
                                        'mini_geared_motor',
                                        'dc_motor',
                                        'dynamex_motor',
                                    ].includes(
                                        sessionStorage.getItem('dragingItem')
                                    )
                                    // sessionStorage.getItem('dragingItem') ==
                                    //     'geared_motor' ||
                                    // sessionStorage.getItem('dragingItem') ==
                                    //     'mini_geared_motor' ||
                                    // sessionStorage.getItem('dragingItem') ==
                                    //     'dc_motor'
                                ) {
                                    LeftF = component.left - 70
                                    TopF = component.top + 30
                                } else if (
                                    sessionStorage.getItem('dragingItem') ==
                                        'servo_motor' &&
                                    !B1connectedGeared_motor &&
                                    !D1connectedGeared_motor
                                ) {
                                    // this is for when we drag servo_motor  changing the postion at drag time

                                    if (connectedTo == 'B2') {
                                        LeftF = component.left - 70
                                        TopF = component.top
                                    } else {
                                        LeftF = component.left - 30
                                        TopF = component.top
                                    }
                                } else {
                                    LeftF = component.left - 70
                                    TopF = component.top + 30
                                }
                            }
                        })
                    }
                })

                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            cx1 +
                            ',' +
                            cy1 +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (connectedTo == 'E1' || connectedTo == 'E2') {
                Object.keys(components).map((key) => {
                    if (key == 'dual_splitter') {
                        // if(this.props.componentName = "")
                        components[key].map((component, index) => {
                            if (
                                ![
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dc_motor',
                                    'dynamex_motor',
                                ].includes(this.props.componentName)
                                // this.props.componentName != 'geared_motor' &&
                                // this.props.componentName !=
                                //     'mini_geared_motor' &&
                                // this.props.componentName != 'dc_motor' &&
                                // this.props.componentName != 'dynamex_motor'
                            ) {
                                if (component.connectedTo == 'E') {
                                    LeftF = component.left
                                    TopF = component.top
                                }
                                if (
                                    component.connectedTo == 'E' &&
                                    this.props.shield == true
                                ) {
                                    if (connectedTo == 'E1') {
                                        LeftF = component.left + 5
                                        TopF = component.top - 74
                                    }
                                    if (connectedTo == 'E2') {
                                        LeftF = component.left - 5
                                        TopF = component.top - 68
                                    }
                                }
                                cx2 = LeftF + Ports(connectedTo)[0]
                                cy2 = TopF + Ports(connectedTo)[1]
                                cx2 +=
                                    Ports(connectedTo)[0] -
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2
                                cy2 +=
                                    Ports(connectedTo)[1] -
                                    ImageSizes[ItemTypes.COMPONENT][1] / 2
                            } else {
                                if (component.connectedTo == 'E') {
                                    LeftF = component.left + 30
                                    TopF = isDeviceVersion12
                                        ? component.top - 40
                                        : component.top - 70
                                }
                                cx2 = LeftF + Ports(connectedTo)[0]
                                cy2 = TopF + Ports(connectedTo)[1]
                                cy2 -= 50
                            }
                        })
                    }
                })

                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            (left + ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (connectedTo == 'F1' || connectedTo == 'F2') {
                if (sessionStorage.getItem('connectedDevice') == 'Klaw') {
                    if (
                        connectedTo == 'F1' &&
                        sessionStorage.getItem('dragingItem') != null
                    ) {
                        LeftF = bibox.left
                        TopF = bibox.top
                        cx1 = left + ImageSizes[ItemTypes.COMPONENT][0] / 2
                        cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                        cx2 = LeftF + Ports(connectedTo)[0]
                        cy2 = TopF + Ports(connectedTo)[1]
                        cy2 += 75
                    }
                    if (
                        connectedTo == 'F2' &&
                        sessionStorage.getItem('dragingItem') != null
                    ) {
                        LeftF = bibox.left
                        TopF = bibox.top
                        cx1 = left + 50 + ImageSizes[ItemTypes.COMPONENT][0] / 2
                        cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                        cx2 = LeftF + Ports(connectedTo)[0]
                        cy2 = TopF + Ports(connectedTo)[1]

                        cy2 += 75
                    }
                }
                Object.keys(components).map((key) => {
                    if (key == 'dual_splitter') {
                        // if(this.props.componentName = "")
                        components[key].map((component, index) => {
                            if (
                                ![
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dc_motor',
                                    'dynamex_motor',
                                ].includes(this.props.componentName)
                                // this.props.componentName != 'geared_motor' &&
                                // this.props.componentName !=
                                //     'mini_geared_motor' &&
                                // this.props.componentName != 'dc_motor' &&
                                // this.props.componentName != 'dynamex_motor'
                            ) {
                                if (component.connectedTo == 'F') {
                                    LeftF = component.left
                                    TopF = component.top + 30
                                }
                                if (
                                    component.connectedTo == 'F' &&
                                    this.props.shield == true
                                ) {
                                    if (connectedTo == 'F1') {
                                        LeftF = component.left + 5
                                        TopF = component.top - 74
                                    }
                                    if (connectedTo == 'F2') {
                                        LeftF = component.left - 5
                                        TopF = component.top - 68
                                    }
                                }
                                cx2 = LeftF + Ports(connectedTo)[0]
                                cy2 = TopF + Ports(connectedTo)[1]
                                cx2 +=
                                    Ports(connectedTo)[0] -
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2
                                cy2 +=
                                    Ports(connectedTo)[1] -
                                    ImageSizes[ItemTypes.COMPONENT][1] / 2
                            } else {
                                if (component.connectedTo == 'F') {
                                    LeftF = component.left + 30
                                    TopF = isDeviceVersion12
                                        ? component.top - 40
                                        : component.top - 70
                                }
                                cx2 = LeftF + Ports(connectedTo)[0]
                                cy2 = TopF + Ports(connectedTo)[1]
                                cy2 -= 50
                            }
                        })
                    }
                })

                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            (left + ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (
                connectedTo == 'M1' ||
                connectedTo == 'M2' ||
                connectedTo == 'M3' ||
                connectedTo == 'M4' ||
                connectedTo == 'G' ||
                connectedTo == 'H'
            ) {
                if (
                    ['Roboki', 'Klaw', 'Toki'].includes(
                        sessionStorage.getItem('connectedDevice')
                    )
                ) {
                    if (
                        connectedTo == 'M1' &&
                        sessionStorage.getItem('dragingItem') != null
                    ) {
                        LeftF = bibox.left
                        TopF = bibox.top
                        cx1 = left + ImageSizes[ItemTypes.COMPONENT][0] / 2
                        cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                        cx2 = LeftF + Ports(connectedTo)[0]
                        cy2 = TopF + Ports(connectedTo)[1]
                        cy2 += 75
                    }
                    if (
                        connectedTo == 'M3' &&
                        sessionStorage.getItem('dragingItem') != null
                    ) {
                        LeftF = bibox.left
                        TopF = bibox.top
                        cx1 = left + 50 + ImageSizes[ItemTypes.COMPONENT][0] / 2
                        cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                        cx2 = LeftF + Ports(connectedTo)[0]
                        cy2 = TopF + Ports(connectedTo)[1]

                        cy2 += 75
                    }
                }
                Object.keys(components).map((key) => {
                    if (key == 'play_shield' || key == 'play_shield_v1') {
                        components[key].map((component, index) => {
                            if (
                                [
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dc_motor',
                                    'dynamex_motor',
                                ].includes(this.props.componentName)
                                // this.props.componentName == 'geared_motor' ||
                                // this.props.componentName ==
                                //     'mini_geared_motor' ||
                                // this.props.componentName == 'dc_motor'
                            ) {
                                if (
                                    connectedTo == 'M1' &&
                                    sessionStorage.getItem('dragingItem') !=
                                        null
                                ) {
                                    LeftF = bibox.left
                                    TopF = bibox.top
                                    cx1 =
                                        left +
                                        ImageSizes[ItemTypes.COMPONENT][0] / 2
                                    cy1 =
                                        top +
                                        2 * ImageSizes[ItemTypes.COMPONENT][1]
                                    cx2 = LeftF + Ports(connectedTo)[0]
                                    cy2 = TopF + Ports(connectedTo)[1]
                                    cy2 += 75
                                }
                                if (
                                    connectedTo == 'M3' &&
                                    sessionStorage.getItem('dragingItem') !=
                                        null
                                ) {
                                    LeftF = bibox.left
                                    TopF = bibox.top
                                    cx1 =
                                        left +
                                        50 +
                                        ImageSizes[ItemTypes.COMPONENT][0] / 2
                                    cy1 =
                                        top +
                                        2 * ImageSizes[ItemTypes.COMPONENT][1]
                                    cx2 = LeftF + Ports(connectedTo)[0]
                                    cy2 = TopF + Ports(connectedTo)[1]

                                    cy2 += 75
                                }
                            }
                        })
                    } else if (key == 'peeCee_ATR') {
                        components[key].map((component, index) => {
                            if (this.props.peeCeeATR) {
                                LeftF = bibox.left
                                TopF = bibox.top
                                cx1 =
                                    left +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2
                                cy1 =
                                    top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                                cx2 = LeftF + Ports(connectedTo)[0]
                                cy2 = TopF + Ports(connectedTo)[1]
                                cy2 += 75
                            }
                        })
                    }
                })

                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            cx1 +
                            ',' +
                            cy1 +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (connectedTo == 'I1' || connectedTo == 'I2') {
                Object.keys(components).map((dual_splitter) => {
                    if (dual_splitter == 'dual_splitter') {
                        components[dual_splitter].map((component, index) => {
                            if (component.connectedTo == 'I') {
                                LeftF = component.left
                                TopF = component.top
                            }
                        })
                    }
                })

                cx2 = LeftF + Ports(connectedTo)[0]
                cy2 = TopF + Ports(connectedTo)[1]
                cx2 +=
                    Ports(connectedTo)[0] -
                    ImageSizes[ItemTypes.COMPONENT][0] / 2
                cy2 +=
                    Ports(connectedTo)[1] -
                    ImageSizes[ItemTypes.COMPONENT][1] / 2
                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            (left + ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (
                (connectedTo == 'servo_motor1' ||
                    connectedTo == 'servo_motor2') &&
                !['Toki'].includes(sessionStorage.getItem('connectedDevice'))
            ) {
                if (
                    ['Roboki', 'Toki'].includes(
                        sessionStorage.getItem('connectedDevice')
                    )
                ) {
                    if (
                        [
                            'servo_motor',
                            'servo_motor_270',
                            'servo_motor_360',
                        ].includes(this.props.componentName) &&
                        sessionStorage.getItem('dragingItem') != null
                    ) {
                        LeftF = bibox.left
                        TopF = bibox.top
                        cx1 = left + ImageSizes[ItemTypes.COMPONENT][0] / 2
                        cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                        cx2 = LeftF + Ports(connectedTo)[0]
                        cy2 = TopF + Ports(connectedTo)[1]
                        cy2 += 75
                    }
                    if (
                        [
                            'servo_motor',
                            'servo_motor_270',
                            'servo_motor_360',
                        ].includes(this.props.componentName) &&
                        sessionStorage.getItem('dragingItem') != null
                    ) {
                        LeftF = bibox.left
                        TopF = bibox.top
                        cx1 = left + 50 + ImageSizes[ItemTypes.COMPONENT][0] / 2
                        cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                        cx2 = LeftF + Ports(connectedTo)[0]
                        cy2 = TopF + Ports(connectedTo)[1]

                        cy2 += 75
                    }
                }
                Object.keys(components).map((key) => {
                    if (key == 'play_shield_v1') {
                        components[key].map((component, index) => {
                            if (
                                [
                                    'servo_motor',
                                    'servo_motor_270',
                                    'servo_motor_360',
                                ].includes(this.props.componentName)
                            ) {
                                if (
                                    connectedTo == 'servo_motor1' &&
                                    sessionStorage.getItem('dragingItem') !=
                                        null
                                ) {
                                    LeftF = bibox.left
                                    TopF = bibox.top
                                    cx1 =
                                        left +
                                        ImageSizes[ItemTypes.COMPONENT][0] / 2
                                    cy1 =
                                        top +
                                        2 * ImageSizes[ItemTypes.COMPONENT][1]
                                    cx2 = LeftF + Ports(connectedTo)[0]
                                    cy2 = TopF + Ports(connectedTo)[1]
                                    cy2 += 75
                                }
                                if (
                                    connectedTo == 'servo_motor2' &&
                                    sessionStorage.getItem('dragingItem') !=
                                        null
                                ) {
                                    LeftF = bibox.left
                                    TopF = bibox.top
                                    cx1 =
                                        left +
                                        50 +
                                        ImageSizes[ItemTypes.COMPONENT][0] / 2
                                    cy1 =
                                        top +
                                        2 * ImageSizes[ItemTypes.COMPONENT][1]
                                    cx2 = LeftF + Ports(connectedTo)[0]
                                    cy2 = TopF + Ports(connectedTo)[1]

                                    cy2 += 75
                                }
                            }
                        })
                    }
                })

                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            cx1 +
                            ',' +
                            cy1 +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (connectedTo == 'A') {
                Top = bibox.top
                Left = bibox.left

                cx2 = Left + Ports(connectedTo)[0]
                cy2 = Top + Ports(connectedTo)[1]
                cx2 +=
                    Ports(connectedTo)[0] - ImageSizes[ItemTypes.BIBOX][0] / 2
                cy2 +=
                    Ports(connectedTo)[1] - ImageSizes[ItemTypes.BIBOX][1] / 2
                if (this.props.componentName == 'pc_motor_driver') {
                    if (connectedTo == 'A' || connectedTo == 'C') {
                        return (
                            <>
                                <path
                                    d={
                                        'M ' +
                                        (left -
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('A')[0]) +
                                        ',' +
                                        (Top + Ports('A')[1])
                                    }
                                />
                                <path
                                    d={
                                        'M ' +
                                        (left +
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('C')[0]) +
                                        ',' +
                                        (Top + Ports('C')[1])
                                    }
                                />
                            </>
                        )
                    }

                    if (connectedTo == 'B' || connectedTo == 'D') {
                        return (
                            <>
                                <path
                                    d={
                                        'M ' +
                                        (left -
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('B')[0]) +
                                        ',' +
                                        (Top + Ports('B')[1])
                                    }
                                />
                                <path
                                    d={
                                        'M ' +
                                        (left +
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('D')[0]) +
                                        ',' +
                                        (Top + Ports('D')[1])
                                    }
                                />
                            </>
                        )
                    }
                } else if (this.props.componentName == 'RGB') {
                    return (
                        <path
                            d={
                                'M ' +
                                (left -
                                    15 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 80) +
                                ' C ' +
                                (left -
                                    120 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                                ' ' +
                                cx2 +
                                ',' +
                                cy2 +
                                ' ' +
                                (Left + Ports(connectedTo)[0]) +
                                ',' +
                                (Top + Ports(connectedTo)[1])
                            }
                        />
                    )
                } else {
                    return (
                        <path
                            d={
                                'M ' +
                                (left -
                                    15 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 80) +
                                ' C ' +
                                (left +
                                    60 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                                ' ' +
                                cx2 +
                                ',' +
                                cy2 +
                                ' ' +
                                (Left + Ports(connectedTo)[0]) +
                                ',' +
                                (Top + Ports(connectedTo)[1])
                            }
                        />
                    )
                }
            } else if (connectedTo == 'B') {
                Top = bibox.top
                Left = bibox.left
                cx2 = Left + Ports(connectedTo)[0]
                cy2 = Top + Ports(connectedTo)[1]
                cx2 +=
                    Ports(connectedTo)[0] - ImageSizes[ItemTypes.BIBOX][0] / 2
                cy2 +=
                    Ports(connectedTo)[1] - ImageSizes[ItemTypes.BIBOX][1] / 2
                if (this.props.componentName == 'pc_motor_driver') {
                    if (connectedTo == 'A' || connectedTo == 'C') {
                        return (
                            <>
                                <path
                                    d={
                                        'M ' +
                                        (left -
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('A')[0]) +
                                        ',' +
                                        (Top + Ports('A')[1])
                                    }
                                />
                                <path
                                    d={
                                        'M ' +
                                        (left +
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('C')[0]) +
                                        ',' +
                                        (Top + Ports('C')[1])
                                    }
                                />
                            </>
                        )
                    }

                    if (connectedTo == 'B' || connectedTo == 'D') {
                        return (
                            <>
                                <path
                                    d={
                                        'M ' +
                                        (left -
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('B')[0]) +
                                        ',' +
                                        (Top + Ports('B')[1])
                                    }
                                />
                                <path
                                    d={
                                        'M ' +
                                        (left +
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('D')[0]) +
                                        ',' +
                                        (Top + Ports('D')[1])
                                    }
                                />
                            </>
                        )
                    }
                } else if (this.props.componentName == 'RGB') {
                    return (
                        <path
                            d={
                                'M ' +
                                (left -
                                    15 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 80) +
                                ' C ' +
                                (left -
                                    120 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                                ' ' +
                                cx2 +
                                ',' +
                                cy2 +
                                ' ' +
                                (Left + Ports(connectedTo)[0]) +
                                ',' +
                                (Top + Ports(connectedTo)[1])
                            }
                        />
                    )
                } else {
                    return (
                        <path
                            d={
                                'M ' +
                                (left -
                                    15 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 80) +
                                ' C ' +
                                (left +
                                    60 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                                ' ' +
                                cx2 +
                                ',' +
                                cy2 +
                                ' ' +
                                (Left + Ports(connectedTo)[0]) +
                                ',' +
                                (Top + Ports(connectedTo)[1])
                            }
                        />
                    )
                }
            } else if (connectedTo == 'F') {
                Top = bibox.top
                Left = bibox.left
                cx2 = Left + Ports(connectedTo)[0]
                cy2 = Top + Ports(connectedTo)[1]
                cy2 -= 50
                cx2 += 150
                cx1 += 50
                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                15 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 80) +
                            ' C ' +
                            (left +
                                60 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (Left + Ports(connectedTo)[0]) +
                            ',' +
                            (Top + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (
                connectedTo == 'C' ||
                connectedTo == 'D' ||
                connectedTo == 'E'
            ) {
                Top = bibox.top
                Left = bibox.left
                cx2 = Left + Ports(connectedTo)[0]
                cy2 = Top + Ports(connectedTo)[1]
                cx2 +=
                    Ports(connectedTo)[0] - ImageSizes[ItemTypes.BIBOX][0] / 2
                cy2 +=
                    Ports(connectedTo)[1] - ImageSizes[ItemTypes.BIBOX][1] / 2
                if (this.props.componentName == 'pc_motor_driver') {
                    if (connectedTo == 'A' || connectedTo == 'C') {
                        return (
                            <>
                                <path
                                    d={
                                        'M ' +
                                        (left -
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('A')[0]) +
                                        ',' +
                                        (Top + Ports('A')[1])
                                    }
                                />
                                <path
                                    d={
                                        'M ' +
                                        (left +
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('C')[0]) +
                                        ',' +
                                        (Top + Ports('C')[1])
                                    }
                                />
                            </>
                        )
                    }

                    if (connectedTo == 'B' || connectedTo == 'D') {
                        return (
                            <>
                                <path
                                    d={
                                        'M ' +
                                        (left -
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('B')[0]) +
                                        ',' +
                                        (Top + Ports('B')[1])
                                    }
                                />
                                <path
                                    d={
                                        'M ' +
                                        (left +
                                            100 +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top + 5) +
                                        ' C ' +
                                        (left +
                                            ImageSizes[ItemTypes.COMPONENT][0] /
                                                2) +
                                        ',' +
                                        (top -
                                            2 *
                                                ImageSizes[
                                                    ItemTypes.COMPONENT
                                                ][1]) +
                                        ' ' +
                                        cx2 +
                                        ',' +
                                        cy2 +
                                        ' ' +
                                        (Left + Ports('D')[0]) +
                                        ',' +
                                        (Top + Ports('D')[1])
                                    }
                                />
                            </>
                        )
                    }
                } else if (this.props.componentName == 'RGB') {
                    return (
                        <path
                            d={
                                'M ' +
                                (left -
                                    15 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 80) +
                                ' C ' +
                                (left -
                                    120 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                                ' ' +
                                cx2 +
                                ',' +
                                cy2 +
                                ' ' +
                                (Left + Ports(connectedTo)[0]) +
                                ',' +
                                (Top + Ports(connectedTo)[1])
                            }
                        />
                    )
                } else {
                    return (
                        <path
                            d={
                                'M ' +
                                (left -
                                    15 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 80) +
                                ' C ' +
                                (left +
                                    60 +
                                    ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                                ',' +
                                (top + 2 * ImageSizes[ItemTypes.COMPONENT][1]) +
                                ' ' +
                                cx2 +
                                ',' +
                                cy2 +
                                ' ' +
                                (Left + Ports(connectedTo)[0]) +
                                ',' +
                                (Top + Ports(connectedTo)[1])
                            }
                        />
                    )
                }
            } else if (
                connectedTo == 'P0' ||
                connectedTo == 'P1' ||
                connectedTo == 'P2' ||
                connectedTo == 'SS' ||
                connectedTo == 'SPK' ||
                connectedTo == 'MOTOR1'
            ) {
                Object.keys(components).map((key) => {
                    components[key].map((component, index) => {
                        if (this.props.armShield) {
                            LeftF = bibox.left
                            TopF = bibox.top
                            cx1 = left + ImageSizes[ItemTypes.COMPONENT][0] / 2
                            cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                            cx2 = LeftF + Ports(connectedTo)[0]
                            cy2 = TopF + Ports(connectedTo)[1]
                            cy2 += 75
                        }
                    })
                })
                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            cx1 +
                            ',' +
                            cy1 +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (connectedTo == 'S1' || connectedTo == 'S2') {
                Object.keys(components).map((key) => {
                    components[key].map((component, index) => {
                        if (this.props.peeCeeATR) {
                            LeftF = bibox.left
                            TopF = bibox.top
                            cx1 = left + ImageSizes[ItemTypes.COMPONENT][0] / 2
                            cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                            cx2 = LeftF + Ports(connectedTo)[0]
                            cy2 = TopF + Ports(connectedTo)[1]
                            cy2 += 75
                        }
                    })
                })
                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            cx1 +
                            ',' +
                            cy1 +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            } else if (connectedTo) {
                LeftF = bibox.left
                TopF = bibox.top
                cx1 = left + ImageSizes[ItemTypes.COMPONENT][0] / 2
                cy1 = top + 2 * ImageSizes[ItemTypes.COMPONENT][1]
                cx2 = LeftF + Ports(connectedTo)[0]
                cy2 = TopF + Ports(connectedTo)[1]
                cy2 += 75

                return (
                    <path
                        d={
                            'M ' +
                            (left -
                                10 +
                                ImageSizes[ItemTypes.COMPONENT][0] / 2) +
                            ',' +
                            (top + 85) +
                            ' C ' +
                            cx1 +
                            ',' +
                            cy1 +
                            ' ' +
                            cx2 +
                            ',' +
                            cy2 +
                            ' ' +
                            (LeftF + Ports(connectedTo)[0]) +
                            ',' +
                            (TopF + Ports(connectedTo)[1])
                        }
                    />
                )
            }
        }
    }
}

//                 // PPP

class PortCircle extends Component {
    renderhighlighted = () => {
        const { PortConnections } = this.props
        let newHumanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        let internalAccessoriesData = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories
        let dragItem = sessionStorage.getItem('dragingItem')
        let deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.00'
        const connectedDevice = sessionStorage.getItem('connectedDevice') || ''
        if (
            deviceVersion[0] == '1' &&
            ['Hexapod', 'Humanoid'].includes(connectedDevice)
            //     sessionStorage.getItem('connectedDevice') == 'Humanoid') ||
            // (sessionStorage.getItem('connectedDevice') == 'Hexapod' &&
            //     deviceVersion[0] == '1')
        ) {
            if (
                this.props.portName == 'D' &&
                dragItem != 'OLED' &&
                (newHumanoidPortData[23].isPortSelected == true ||
                    newHumanoidPortData[24].isPortSelected == true ||
                    newHumanoidPortData[25].isPortSelected == true ||
                    PortConnections['D1'] != null ||
                    PortConnections['D2'] != null)
            ) {
                return 0
            } else if (
                this.props.portName == 'B' &&
                newHumanoidPortData[40].isPortSelected == true
            ) {
                return 0
            }
        }
        if (!sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            if (
                this.props.portName == 'A' &&
                (internalAccessoriesData.isTouchZero == true ||
                    internalAccessoriesData.isTouchZeroOutput == true ||
                    PortConnections['A1'] != null ||
                    PortConnections['A2'] != null)
            ) {
                return 0
            } else if (
                this.props.portName == 'B' &&
                (internalAccessoriesData.isTouchOne == true ||
                    internalAccessoriesData.isTouchOneOutput == true ||
                    PortConnections['B1'] != null ||
                    PortConnections['B2'] != null)
            ) {
                return 0
            } else if (
                this.props.portName == 'C' &&
                (internalAccessoriesData.isTouchTwo == true ||
                    internalAccessoriesData.isTouchTwoOutput == true ||
                    PortConnections['C1'] != null ||
                    PortConnections['C2'] != null)
            ) {
                return 0
            } else if (
                this.props.portName == 'D' &&
                dragItem != 'OLED' &&
                (internalAccessoriesData.isFour_in_one_sensor == true ||
                    PortConnections['D1'] != null ||
                    PortConnections['D2'] != null)
            ) {
                return 0
            } else {
                return 1.4
            }
        } else {
            if (
                sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
                JSON.parse(sessionStorage.getItem('peeCeeATR') || 'false') &&
                ['M2', 'M4'].includes(this.props.portName)
            ) {
                return 0
            }
            // if (this.props.portName === 'M2') {
            //     console.log("WWWWWWWWWWW")
            //     return 0
            // }
            if (
                sessionStorage.getItem('dragingItem') != 'OLED' &&
                this.props.portName == 'D' &&
                (internalAccessoriesData.isFour_in_one_sensor === true ||
                    internalAccessoriesData.isAccelerometer === true)
            ) {
                return 0
            }
            if (
                //mini_geared_motor M4
                [
                    'geared_motor',
                    'mini_geared_motor',
                    'dc_motor',
                    'dynamex_motor',
                ].includes(sessionStorage.getItem('dragingItem')) &&
                this.props.portName == 'M1' &&
                (internalAccessoriesData.isSmileOne === true ||
                    internalAccessoriesData.isSmileTwo === true)
            ) {
                return 0
            }
            if (
                [
                    'geared_motor',
                    'mini_geared_motor',
                    'dc_motor',
                    'dynamex_motor',
                ].includes(sessionStorage.getItem('dragingItem')) &&
                this.props.portName == 'M3' &&
                (internalAccessoriesData.isSmileThree === true ||
                    internalAccessoriesData.isSmileFour === true)
            ) {
                return 0
            }
            if (sessionStorage.getItem('shield') === 'true') {
                const components = JSON.parse(
                    sessionStorage.getItem('assembly')
                ).workspace.components
                if (
                    [
                        'servo_motor_360',
                        'servo_motor_270',
                        'servo_motor',
                        'dc_motor',
                        'geared_motor',
                        'mini_geared_motor',
                        'dynamex_motor',
                    ].includes(sessionStorage.getItem('dragingItem'))
                ) {
                    const cal_length = (motorlist) => {
                        let sum = 0
                        motorlist.map((motor) => {
                            if (motor.connectedTo !== undefined) {
                                sum = sum + 1
                            }
                        })
                        return sum
                    }
                    const servo_motor =
                        components.servo_motor === undefined
                            ? 0
                            : cal_length(components.servo_motor)
                    const servo_motor_270 =
                        components.servo_motor_270 === undefined
                            ? 0
                            : cal_length(components.servo_motor_270)
                    const servo_motor_360 =
                        components.servo_motor_360 === undefined
                            ? 0
                            : cal_length(components.servo_motor_360)
                    const dc_motor =
                        components.dc_motor === undefined
                            ? 0
                            : cal_length(components.dc_motor)
                    const dynamex_motor =
                        components.dynamex_motor === undefined
                            ? 0
                            : cal_length(components.dynamex_motor)
                    const geared_motor =
                        components.geared_motor === undefined
                            ? 0
                            : cal_length(components.geared_motor)
                    const mini_geared_motor =
                        components.mini_geared_motor === undefined
                            ? 0
                            : cal_length(components.mini_geared_motor)
                    if (
                        (components.dc_motor === undefined
                            ? true
                            : components.dc_motor.length == 0) &&
                        (components.dynamex_motor === undefined
                            ? true
                            : components.dynamex_motor.length == 0) &&
                        (components.geared_motor === undefined
                            ? true
                            : components.geared_motor.length == 0) &&
                        (components.mini_geared_motor === undefined
                            ? true
                            : components.mini_geared_motor.length == 0) &&
                        (components.servo_motor !== undefined ||
                            components.servo_motor_270 !== undefined ||
                            components.servo_motor_360 !== undefined)
                    ) {
                        if (
                            !(
                                servo_motor +
                                    servo_motor_270 +
                                    servo_motor_360 <
                                14
                            )
                        ) {
                            return 0
                        } else {
                            return 1.4
                        }
                    } else if (
                        ((components.dc_motor === undefined
                            ? false
                            : components.dc_motor.length != 0) ||
                            (components.dynamex_motor === undefined
                                ? false
                                : components.dynamex_motor.length != 0) ||
                            (components.geared_motor === undefined
                                ? false
                                : components.geared_motor.length != 0) ||
                            (components.mini_geared_motor === undefined
                                ? false
                                : components.mini_geared_motor.length != 0)) &&
                        ((components.servo_motor === undefined
                            ? false
                            : components.servo_motor.length != 0) ||
                            (components.servo_motor_360 === undefined
                                ? false
                                : components.servo_motor_360.length != 0) ||
                            (components.servo_motor_270 === undefined
                                ? false
                                : components.servo_motor_270.length != 0))
                    ) {
                        const noMotors =
                            (dc_motor +
                                mini_geared_motor +
                                geared_motor +
                                dynamex_motor) *
                                2 +
                            servo_motor +
                            servo_motor_270 +
                            servo_motor_360
                        if (noMotors == 12) {
                            return 0
                        } else if (noMotors == 11) {
                            if (
                                noMotors % 2 == 1 &&
                                [
                                    'dc_motor',
                                    'geared_motor',
                                    'mini_geared_motor',
                                    'dynamex_motor',
                                ].includes(
                                    sessionStorage.getItem('dragingItem')
                                )
                            ) {
                                return 0
                            } else {
                                return 1.4
                            }
                        } else {
                            return 1.4
                        }
                    } else {
                        return 1.4
                    }
                } else {
                    return 1.4
                }
            } else {
                return 1.4
            }
        }
    }

    render() {
        if (
            ImageSizes[ItemTypes.PORT_CIRCLE][0] /
                (this.props.highlighted ? 1.4 : 0) ==
            'Infinity'
        ) {
            return <circle cx={this.props.left} cy={this.props.top} r={0} />
        } else {
            return (
                <circle
                    cx={this.props.left}
                    cy={this.props.top}
                    r={
                        ImageSizes[ItemTypes.PORT_CIRCLE][0] /
                        (this.props.highlighted ? this.renderhighlighted() : 0)
                    }
                />
            )
        }
    }
}

class ComponentPort extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.PortConnections !== this.props.PortConnections
    }
    render() {
        console.log('konektion', this.props)
        const PortConnections = this.props.PortConnections
        var { left, top } = this.props.component
        left += ImageSizes[ItemTypes.COMPONENT][0] / 2
        return (
            <PortCircle
                PortConnections={PortConnections}
                left={left}
                top={top}
            />
        )
    }
}

class Connections extends Component {
    render() {
        const {
            bibox,
            components,
            extraComponent,
            Camera,
            PortConnections,
            allowedPortTypeData,
        } = this.props
        console.log('konektion', this.props)
        console.log('port', Ports('A1'))
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        let shield = JSON.parse(sessionStorage.getItem('shield')) || false
        let armShield = JSON.parse(sessionStorage.getItem('armShield')) || false
        let peeCeeATR = JSON.parse(sessionStorage.getItem('peeCeeATR')) || false

        let deviceVersion = sessionStorage.getItem('deviceVersion')

        let internalAccessoriesData = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories

        let CameraOffsetLeft = this.props.Camera.offset.left
        let CameraOffsetTop = this.props.Camera.offset.top

        if (localStorage.getItem('programMode') == 'learn') {
            var PortConnectionsTutor = JSON.parse(
                sessionStorage.getItem('tutorialPort')
            )
            //  var AllowedPortTypesTutor = AllowedPortTypes
            var AllowedPortTypesTutor = {}
            Object.entries(PortConnectionsTutor).map(([key, value]) => {
                if (AllowedPortTypesTutor[value]) {
                    AllowedPortTypesTutor[value].push(key)
                } else {
                    AllowedPortTypesTutor[value] = [key]
                }
            })
        } else {
            // var PortConnectionsTutor = JSON.parse(sessionStorage.getItem("tutorialPort"))
            var AllowedPortTypesTutor = allowedPortTypeData
            // if (shield != false) {
            //     AllowedPortTypesTutor =
            //         AllowedPortTypes[`SHIELD${deviceVersion.slice(0, 1)}`]
            // } else if (peeCeeATR != false) {
            //     AllowedPortTypesTutor =
            //         AllowedPortTypes[`PEECEEATR${deviceVersion.slice(0, 1)}`]
            // } else if (armShield) {
            //     AllowedPortTypesTutor =
            //         AllowedPortTypes[`ARMSHIELD${deviceVersion.slice(0, 1)}`]
            // } else {
            //     AllowedPortTypesTutor =
            //         AllowedPortTypes[
            //             `${connectedDevice.toUpperCase()}${deviceVersion.slice(
            //                 0,
            //                 1
            //             )}`
            //         ]
            // }

            // var AllowedPortTypesTutor = {}

            Object.entries(PortConnections).map(
                ([key, value]) => (AllowedPortTypesTutor[value] = [key])
            )
        }
        var Left
        var Top
        var connections = []
        var portCircles = []

        // ** cHECHING THE CONNECTED PORT  return the type of motor connected on the port **
        let isMotorConnected_BD = () => {
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .STPM !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.STPM.type
                return value
            }

            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .B1 !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.B1.type
                return value
            }

            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .B2 !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.B2.type
                return value
            }

            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .D1 !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.D1.type
                return value
            }
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .D2 !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.D2.type
                return value
            }
        }

        let isMotorConnected_AC = () => {
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .STPM !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.STPM.type
                return value
            }

            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .A1 !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.A1.type
                return value
            }

            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .A2 !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.A2.type
                return value
            }

            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .C1 !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.C1.type
                return value
            }
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .C2 !== null
            ) {
                let value = JSON.parse(sessionStorage.getItem('assembly'))
                    .PortConnections.C2.type
                return value
            }
        }

        /*
        RETURN THE PORT WHERE PC_MOTOR_DIRVER is CONNECTED
     */
        let isPc_motor_driverConnected_BD_AC = () => {
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .B != null
            ) {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.B.type == 'pc_motor_driver'
                ) {
                    return 'B'
                }
            }
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .D != null
            ) {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.D.type == 'pc_motor_driver'
                ) {
                    return 'D'
                }
            }
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .A != null
            ) {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.A.type == 'pc_motor_driver'
                ) {
                    return 'A'
                }
            }
            if (
                JSON.parse(sessionStorage.getItem('assembly')).PortConnections
                    .C != null
            ) {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.C.type == 'pc_motor_driver'
                ) {
                    return 'C'
                }
            }
        }

        // only for ge

        Object.keys(components).map((key) => {
            components[key].map((component, index) => {
                const { connectedTo } = component
                var sdarafs = component
                // WHEN we connect the components to port commectedTO will be the port
                if (connectedTo) {
                    connections.push(
                        <Curve
                            bibox={bibox}
                            component={component}
                            componentName={key}
                            components={components}
                            key={connections.length}
                            style={{ zIndex: -1 }}
                            PortConnections={PortConnections}
                            shield={this.props.shield}
                            armShield={this.props.armShield}
                            peeCeeATR={this.props.peeCeeATR}
                        />
                    )
                    // }
                }

                if (
                    !(
                        DraggingInfo.isDragging &&
                        DraggingInfo.draggingComponentOld &&
                        key == DraggingInfo.draggingComponentOld.type &&
                        index === DraggingInfo.draggingComponentOld.index
                    )
                ) {
                    let keyData = key
                    // PPP

                    portCircles.push(
                        <ComponentPort
                            PortConnections={PortConnections}
                            component={component}
                            key={portCircles.length}
                        />
                    )
                }
            })
        })

        if (extraComponent) {
            if (extraComponent.connectedTo) {
                connections.push(
                    <Curve
                        bibox={bibox}
                        component={extraComponent}
                        components={components}
                        key={connections.length}
                        componentName={extraComponent.type}
                        PortConnections={PortConnections}
                        shield={this.props.shield}
                        armShield={this.props.armShield}
                        peeCeeATR={this.props.peeCeeATR}
                    />
                )
            }
            console.log('konekkk', extraComponent)
            portCircles.push(
                <ComponentPort
                    PortConnections={PortConnections}
                    component={extraComponent}
                    key={portCircles.length}
                />
            )
        }

        //get the top value for hover red circle in draging time
        const getTopValueRedCircle = (port, Top, Ports, draggingItem) => {
            const isDeviceVersion12 =
                (['1', '2'].includes(deviceVersion[0]) &&
                    ['Ace', 'Humanoid', 'Hexapod'].includes(Device)) ||
                ['Roboki', 'Klaw', 'Toki'].includes(Device)

            if (
                isDeviceVersion12 &&
                [
                    'mini_geared_motor',
                    'geared_motor',
                    'dc_motor',
                    'dynamex_motor',
                ].includes(draggingItem) &&
                !['M1', 'M2', 'M3', 'M4', 'G', 'H'].includes(port)
            ) {
                if (
                    ['Toki'].includes(Device) &&
                    ['C1', 'C2', 'D1'].includes(port)
                ) {
                    return Top + Ports(port)[1]
                } else return Top + Ports(port)[1] + 25
            } else return Top + Ports(port)[1]
        }

        return (
            <div
                id="connectPort"
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 2,
                    pointerEvents: 'none',
                }}
            >
                <svg height="100%" width="100%">
                    <g
                        transform={
                            'matrix(' +
                            Camera.scale +
                            ' ' +
                            '0 0 ' +
                            Camera.scale +
                            ' ' +
                            Camera.offset.left * Camera.scale +
                            ' ' +
                            Camera.offset.top * Camera.scale +
                            ')'
                        }
                    >
                        <g stroke="#4d4d4d" strokeWidth="4px" fill="none">
                            {connections}
                        </g>
                        <g stroke="black" strokeWidth="4px" fill="white">
                            {portCircles}
                        </g>

                        {Object.keys(
                            PortTypes[`${connectedDevice.toUpperCase()}`]
                        ).map((key) => {
                            var highlighted = false
                            // this will let us know which port will active will draging components

                            if (extraComponent) {
                                if (
                                    AllowedPortTypesTutor[extraComponent.type]
                                ) {
                                    if (
                                        !(
                                            DraggingInfo.draggingComponentOld &&
                                            DraggingInfo.newComponentPort
                                        ) &&
                                        extraComponent &&
                                        AllowedPortTypesTutor[
                                            extraComponent.type
                                        ].indexOf(key) > -1
                                    )
                                        highlighted = true
                                }
                            }
                            // if (
                            //     ['A', 'B', 'A1', 'A2', 'B1', 'B2'].includes(key)
                            // )
                            //     console.log(key, 'XXXXXXXXXXXX', highlighted)
                            // S1
                            if (
                                [
                                    'Ace',
                                    'Humanoid',
                                    'Hexapod',
                                    'Klaw',
                                    'Roboki',
                                    'Toki',
                                ].includes(Device)
                            ) {
                                if (
                                    (key == 'D1' || key == 'D2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                ![
                                                                    'dc_motor',
                                                                    'geared_motor',
                                                                    'mini_geared_motor',
                                                                    'dynamex_motor',
                                                                ].includes(
                                                                    extraComponent.type
                                                                )
                                                                // extraComponent.type !=
                                                                //     'geared_motor' &&
                                                                // extraComponent.type !=
                                                                //     'mini_geared_motor' &&
                                                                // extraComponent.type !=
                                                                //     'dc_motor' &&
                                                                // extraComponent.type !=
                                                                //     'dynamex_motor'
                                                            ) {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'D'
                                                                ) {
                                                                    Left =
                                                                        component.left
                                                                    Top =
                                                                        component.top
                                                                    highlighted = true
                                                                }
                                                                if (
                                                                    component.connectedTo ==
                                                                        'D' &&
                                                                    (this.props
                                                                        .shield ||
                                                                        this
                                                                            .props
                                                                            .armShield)
                                                                ) {
                                                                    if (
                                                                        key ==
                                                                        'D1'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            230
                                                                        Top =
                                                                            component.top -
                                                                            319
                                                                        highlighted = true
                                                                    }
                                                                    if (
                                                                        key ==
                                                                        'D2'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            139
                                                                        Top =
                                                                            component.top -
                                                                            315
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            } else {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'D'
                                                                ) {
                                                                    Left =
                                                                        component.left +
                                                                        28
                                                                    Top =
                                                                        component.top +
                                                                        5
                                                                    highlighted = true
                                                                }
                                                                if (
                                                                    component.connectedTo ==
                                                                        'D' &&
                                                                    (this.props
                                                                        .shield ||
                                                                        this
                                                                            .props
                                                                            .armShield)
                                                                ) {
                                                                    Left =
                                                                        component.left -
                                                                        200
                                                                    Top =
                                                                        component.top -
                                                                        314
                                                                    highlighted = true
                                                                }
                                                            }
                                                        }
                                                    )
                                                }
                                                if (
                                                    dual_splitter ==
                                                    'play_shield'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                [
                                                                    'servo_motor',
                                                                    'servo_motor_270',
                                                                    'servo_motor_360',
                                                                ].includes(
                                                                    extraComponent.type
                                                                )
                                                            ) {
                                                                if (
                                                                    (key ==
                                                                        'D1' ||
                                                                        key ==
                                                                            'D2') &&
                                                                    PortConnections.D ==
                                                                        null
                                                                ) {
                                                                    Left =
                                                                        bibox.left
                                                                    Top =
                                                                        bibox.top
                                                                    highlighted = true
                                                                }
                                                            }
                                                        }
                                                    )
                                                }
                                                // MOTOR DRIVER
                                                if (
                                                    dual_splitter ==
                                                        'pc_motor_driver' &&
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'assembly'
                                                        )
                                                    ).PortConnections.D !== null
                                                ) {
                                                    if (
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'assembly'
                                                            )
                                                        ).PortConnections.D
                                                            .type ==
                                                        'pc_motor_driver'
                                                    ) {
                                                        //checking extraCoponent type = geared_motor
                                                        if (
                                                            [
                                                                'dc_motor',
                                                                'geared_motor',
                                                                'mini_geared_motor',
                                                                'dynamex_motor',
                                                            ].includes(
                                                                extraComponent.type
                                                            )
                                                            // extraComponent.type ==
                                                            //     'geared_motor' ||
                                                            // extraComponent.type ==
                                                            //     'mini_geared_motor' ||
                                                            // extraComponent.type ==
                                                            //     'dc_motor'
                                                        ) {
                                                            // 1 all ports == null  i.e B1,B2,D1,D2,SMTP == null

                                                            if (
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .STPM ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .B1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .B2 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .D1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .D2 === null
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'B' ||
                                                                            component.connectedTo ==
                                                                                'D'
                                                                        ) {
                                                                            Left =
                                                                                component.left +
                                                                                125
                                                                            Top =
                                                                                component.top +
                                                                                30
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }

                                                            // checking connect divice is not == to servo_motot n stepper_motor
                                                            let connectName =
                                                                isMotorConnected_BD()
                                                            if (
                                                                ![
                                                                    'servo_motor',
                                                                    'servo_motor_270',
                                                                    'stepper_motor',
                                                                ].includes(
                                                                    connectName
                                                                )
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'B' ||
                                                                            component.connectedTo ==
                                                                                'D'
                                                                        ) {
                                                                            Left =
                                                                                component.left +
                                                                                125
                                                                            Top =
                                                                                component.top +
                                                                                30
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        }

                                                        //checking extraCoponent type = servo_motor
                                                        if (
                                                            extraComponent.type ==
                                                            'servo_motor'
                                                        ) {
                                                            if (
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .B1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .D1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .STPM ===
                                                                    null
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'B' ||
                                                                            component.connectedTo ==
                                                                                'D'
                                                                        ) {
                                                                            if (
                                                                                key ==
                                                                                'D2'
                                                                            ) {
                                                                                Left =
                                                                                    component.left +
                                                                                    20
                                                                            } else {
                                                                                Left =
                                                                                    component.left +
                                                                                    60
                                                                            }
                                                                            Top =
                                                                                component.top
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }

                                                            // checking connect divice is not == to geared_motor n stepper_motor
                                                            let connectName =
                                                                isMotorConnected_BD()
                                                            if (
                                                                ![
                                                                    'dc_motor',
                                                                    'geared_motor',
                                                                    'mini_geared_motor',
                                                                    'dynamex_motor',
                                                                    'stepper_motor',
                                                                ].includes(
                                                                    connectName
                                                                )
                                                                // connectName !==
                                                                //     'geared_motor' &&
                                                                // connectName !==
                                                                //     'mini_geared_motor' &&
                                                                // connectName !==
                                                                //     'dc_motor' &&
                                                                // connectName !==
                                                                //     'stepper_motor'
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'B' ||
                                                                            component.connectedTo ==
                                                                                'D'
                                                                        ) {
                                                                            if (
                                                                                key ==
                                                                                'D2'
                                                                            ) {
                                                                                Left =
                                                                                    component.left +
                                                                                    20
                                                                            } else {
                                                                                Left =
                                                                                    component.left +
                                                                                    60
                                                                            }
                                                                            Top =
                                                                                component.top
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        }

                                                        //checking extraCoponent type = stepper_motor
                                                    }
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'C1' || key == 'C2') &&
                                    highlighted &&
                                    !['Toki'].includes(connectedDevice)
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                extraComponent.type !=
                                                                    'geared_motor' &&
                                                                extraComponent.type !=
                                                                    'mini_geared_motor' &&
                                                                extraComponent.type !=
                                                                    'dc_motor' &&
                                                                extraComponent.type !=
                                                                    'dynamex_motor'
                                                            ) {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'C'
                                                                ) {
                                                                    if (
                                                                        key ==
                                                                        'C1'
                                                                    ) {
                                                                        Left =
                                                                            component.left
                                                                        Top =
                                                                            component.top
                                                                        highlighted = true
                                                                    }
                                                                    if (
                                                                        key ==
                                                                        'C2'
                                                                    ) {
                                                                        Left =
                                                                            component.left
                                                                        Top =
                                                                            component.top
                                                                        highlighted = true
                                                                    }
                                                                }
                                                                if (
                                                                    component.connectedTo ==
                                                                        'C' &&
                                                                    (this.props
                                                                        .shield ||
                                                                        this
                                                                            .props
                                                                            .armShield)
                                                                ) {
                                                                    if (
                                                                        key ==
                                                                        'C1'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            125
                                                                        Top =
                                                                            component.top -
                                                                            320
                                                                        highlighted = true
                                                                    }
                                                                    if (
                                                                        key ==
                                                                        'C2'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            105
                                                                        Top =
                                                                            component.top -
                                                                            315
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            } else {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'C'
                                                                ) {
                                                                    Left =
                                                                        component.left +
                                                                        28
                                                                    Top =
                                                                        component.top +
                                                                        5
                                                                    highlighted = true
                                                                }
                                                                if (
                                                                    component.connectedTo ==
                                                                        'C' &&
                                                                    (this.props
                                                                        .shield ||
                                                                        this
                                                                            .props
                                                                            .armShield)
                                                                ) {
                                                                    Left =
                                                                        component.left -
                                                                        100
                                                                    Top =
                                                                        component.top -
                                                                        315
                                                                    highlighted = true
                                                                }
                                                            }
                                                        }
                                                    )
                                                }
                                                if (
                                                    dual_splitter ==
                                                    'play_shield'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                [
                                                                    'servo_motor',
                                                                    'servo_motor_360',
                                                                    'servo_motor_270',
                                                                ].includes(
                                                                    extraComponent.type
                                                                )
                                                            ) {
                                                                if (
                                                                    (key ==
                                                                        'C1' ||
                                                                        key ==
                                                                            'C2') &&
                                                                    PortConnections.C ==
                                                                        null
                                                                ) {
                                                                    Left =
                                                                        bibox.left
                                                                    Top =
                                                                        bibox.top
                                                                    highlighted = true
                                                                }
                                                            }
                                                        }
                                                    )
                                                }
                                                /** */

                                                // MOTOR DRIVER
                                                if (
                                                    dual_splitter ==
                                                        'pc_motor_driver' &&
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'assembly'
                                                        )
                                                    ).PortConnections.C !== null
                                                ) {
                                                    if (
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'assembly'
                                                            )
                                                        ).PortConnections.C
                                                            .type ==
                                                        'pc_motor_driver'
                                                    ) {
                                                        //checking extraCoponent type = geared_motor
                                                        if (
                                                            [
                                                                'dc_motor',
                                                                'geared_motor',
                                                                'mini_geared_motor',
                                                                'dynamex_motor',
                                                            ].includes(
                                                                extraComponent.type
                                                            )
                                                            // extraComponent.type ==
                                                            //     'geared_motor' ||
                                                            // extraComponent.type ==
                                                            //     'mini_geared_motor' ||
                                                            // extraComponent.type ==
                                                            //     'dc_motor'
                                                        ) {
                                                            // 1 all ports == null  i.e B1,B2,D1,D2,SMTP == null

                                                            if (
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .STPM ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .A1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .A2 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .C1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .C2 === null
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'A' ||
                                                                            component.connectedTo ==
                                                                                'C'
                                                                        ) {
                                                                            Left =
                                                                                component.left +
                                                                                125
                                                                            Top =
                                                                                component.top +
                                                                                30
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }

                                                            // checking connect divice is not == to servo_motot n stepper_motor
                                                            let connectName =
                                                                isMotorConnected_AC()
                                                            if (
                                                                connectName !==
                                                                    'servo_motor' &&
                                                                connectName !==
                                                                    'stepper_motor'
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'A' ||
                                                                            component.connectedTo ==
                                                                                'C'
                                                                        ) {
                                                                            Left =
                                                                                component.left +
                                                                                125
                                                                            Top =
                                                                                component.top +
                                                                                30
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        }

                                                        //checking extraCoponent type = servo_motor
                                                        if (
                                                            extraComponent.type ==
                                                            'servo_motor'
                                                        ) {
                                                            if (
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .A1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .C1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .STPM ===
                                                                    null
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'A' ||
                                                                            component.connectedTo ==
                                                                                'C'
                                                                        ) {
                                                                            if (
                                                                                key ==
                                                                                'C2'
                                                                            ) {
                                                                                Left =
                                                                                    component.left +
                                                                                    20
                                                                            } else {
                                                                                Left =
                                                                                    component.left +
                                                                                    60
                                                                            }
                                                                            Top =
                                                                                component.top
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }

                                                            // checking connect divice is not == to geared_motor n stepper_motor
                                                            let connectName =
                                                                isMotorConnected_AC()
                                                            if (
                                                                ![
                                                                    'dc_motor',
                                                                    'geared_motor',
                                                                    'mini_geared_motor',
                                                                    'dynamex_motor',
                                                                    'stepper_motor',
                                                                ].includes(
                                                                    connectName
                                                                )
                                                                // connectName !==
                                                                //     'geared_motor' &&
                                                                // connectName !==
                                                                //     'mini_geared_motor' &&
                                                                // connectName !==
                                                                //     'dc_motor' &&
                                                                // connectName !==
                                                                //     'stepper_motor'
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'A' ||
                                                                            component.connectedTo ==
                                                                                'C'
                                                                        ) {
                                                                            if (
                                                                                key ==
                                                                                'C2'
                                                                            ) {
                                                                                Left =
                                                                                    component.left +
                                                                                    20
                                                                            } else {
                                                                                Left =
                                                                                    component.left +
                                                                                    60
                                                                            }
                                                                            Top =
                                                                                component.top
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        }

                                                        //checking extraCoponent type = stepper_motor
                                                    }
                                                }

                                                /** */
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'B1' || key == 'B2') &&
                                    highlighted &&
                                    !['Toki'].includes(connectedDevice)
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map((value) => {
                                            // console.log("assemblComponents", value);
                                            if (value == 'dual_splitter') {
                                                components[value].map(
                                                    (component, index) => {
                                                        if (
                                                            extraComponent.type !=
                                                                'geared_motor' &&
                                                            extraComponent.type !=
                                                                'mini_geared_motor' &&
                                                            extraComponent.type !=
                                                                'dc_motor' &&
                                                            extraComponent.type !=
                                                                'dynamex_motor'
                                                        ) {
                                                            if (
                                                                component.connectedTo ==
                                                                'B'
                                                            ) {
                                                                Left =
                                                                    component.left
                                                                Top =
                                                                    component.top
                                                                highlighted = true
                                                            }
                                                            if (
                                                                component.connectedTo ==
                                                                    'B' &&
                                                                (this.props
                                                                    .shield ||
                                                                    this.props
                                                                        .armShield)
                                                            ) {
                                                                if (
                                                                    key == 'B1'
                                                                ) {
                                                                    Left =
                                                                        component.left -
                                                                        268
                                                                    Top =
                                                                        component.top -
                                                                        256
                                                                    highlighted = true
                                                                }
                                                                if (
                                                                    key == 'B2'
                                                                ) {
                                                                    Left =
                                                                        component.left -
                                                                        212
                                                                    Top =
                                                                        component.top -
                                                                        287
                                                                    highlighted = true
                                                                }
                                                            }
                                                        } else {
                                                            if (
                                                                component.connectedTo ==
                                                                'B'
                                                            ) {
                                                                Left =
                                                                    component.left +
                                                                    28
                                                                Top =
                                                                    component.top +
                                                                    5
                                                                highlighted = true
                                                            }
                                                            if (
                                                                component.connectedTo ==
                                                                    'B' &&
                                                                (this.props
                                                                    .shield ||
                                                                    this.props
                                                                        .armShield)
                                                            ) {
                                                                Left =
                                                                    component.left -
                                                                    240
                                                                Top =
                                                                    component.top -
                                                                    250
                                                                highlighted = true
                                                            }
                                                        }
                                                    }
                                                )
                                            }
                                            if (value == 'play_shield') {
                                                components[value].map(
                                                    (component, index) => {
                                                        if (
                                                            extraComponent.type !=
                                                                'geared_motor' &&
                                                            extraComponent.type !=
                                                                'mini_geared_motor' &&
                                                            extraComponent.type !=
                                                                'dc_motor' &&
                                                            extraComponent.type !=
                                                                'dynamex_motor' &&
                                                            extraComponent.type !=
                                                                'servo_motor' &&
                                                            extraComponent.type !=
                                                                'servo_motor_270' &&
                                                            extraComponent.type !=
                                                                'servo_motor_360'
                                                        ) {
                                                            if (
                                                                PortConnections.B ==
                                                                null
                                                            ) {
                                                                if (
                                                                    key == 'B1'
                                                                ) {
                                                                    Left =
                                                                        bibox.left
                                                                    Top =
                                                                        bibox.top
                                                                    highlighted = true
                                                                }

                                                                if (
                                                                    key == 'B2'
                                                                ) {
                                                                    Left =
                                                                        bibox.left
                                                                    Top =
                                                                        bibox.top
                                                                    highlighted = true
                                                                }
                                                            }
                                                        }
                                                    }
                                                )
                                            }
                                            if (value == 'servo_extender') {
                                                components[value].map(
                                                    (component, index) => {
                                                        if (
                                                            component.connectedTo ==
                                                            'B'
                                                        ) {
                                                            Left =
                                                                component.left +
                                                                30
                                                            Top =
                                                                component.top +
                                                                5
                                                            highlighted = true
                                                        }
                                                    }
                                                )
                                            }

                                            // MOTOR DRIVER
                                            if (
                                                value == 'pc_motor_driver' &&
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'assembly'
                                                    )
                                                ).PortConnections.B !== null
                                            ) {
                                                if (
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'assembly'
                                                        )
                                                    ).PortConnections.B.type ==
                                                    'pc_motor_driver'
                                                ) {
                                                    //***checking extraCoponent type = geared_motor
                                                    if (
                                                        [
                                                            'geared_motor',
                                                            'mini_geared_motor',
                                                            'dc_motor',
                                                            'dynamex_motor',
                                                        ].includes(
                                                            extraComponent.type
                                                        )
                                                    ) {
                                                        // 1 all ports == null  i.e B1,B2,D1,D2,SMTP == null

                                                        if (
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .STPM ===
                                                                null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .B1 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .B2 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .D1 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .D2 === null
                                                        ) {
                                                            components[
                                                                value
                                                            ].map(
                                                                (
                                                                    component,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        component.connectedTo ==
                                                                            'B' ||
                                                                        component.connectedTo ==
                                                                            'D'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            70
                                                                        Top =
                                                                            component.top +
                                                                            30
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            )
                                                        }

                                                        // checking connect divice is not == to servo_motot n stepper_motor
                                                        let connectName =
                                                            isMotorConnected_BD()
                                                        if (
                                                            connectName !==
                                                                'servo_motor' &&
                                                            connectName !==
                                                                'stepper_motor'
                                                        ) {
                                                            components[
                                                                value
                                                            ].map(
                                                                (
                                                                    component,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        component.connectedTo ==
                                                                            'B' ||
                                                                        component.connectedTo ==
                                                                            'D'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            70
                                                                        Top =
                                                                            component.top +
                                                                            30
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    }

                                                    //****checking extraCoponent type = servo_motor

                                                    if (
                                                        extraComponent.type ==
                                                        'servo_motor'
                                                    ) {
                                                        if (
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .B1 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .D1 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .STPM === null
                                                        ) {
                                                            components[
                                                                value
                                                            ].map(
                                                                (
                                                                    component,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        component.connectedTo ==
                                                                            'B' ||
                                                                        component.connectedTo ==
                                                                            'D'
                                                                    ) {
                                                                        if (
                                                                            key ==
                                                                            'B2'
                                                                        ) {
                                                                            Left =
                                                                                component.left -
                                                                                70
                                                                        } else {
                                                                            Left =
                                                                                component.left -
                                                                                25
                                                                        }
                                                                        Top =
                                                                            component.top
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            )
                                                        }

                                                        // checking connect divice is not == to servo_motot n stepper_motor
                                                        let connectName =
                                                            isMotorConnected_BD()
                                                        if (
                                                            ![
                                                                'dc_motor',
                                                                'geared_motor',
                                                                'mini_geared_motor',
                                                                'dynamex_motor',
                                                                'stepper_motor',
                                                            ].includes(
                                                                connectName
                                                            )
                                                            // connectName !==
                                                            //     'geared_motor' &&
                                                            // connectName !==
                                                            //     'mini_geared_motor' &&
                                                            // connectName !==
                                                            //     'dc_motor' &&
                                                            // connectName !==
                                                            //     'dynamex_motor' &&
                                                            // connectName !==
                                                            //     'stepper_motor'
                                                        ) {
                                                            components[
                                                                value
                                                            ].map(
                                                                (
                                                                    component,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        component.connectedTo ==
                                                                            'B' ||
                                                                        component.connectedTo ==
                                                                            'D'
                                                                    ) {
                                                                        if (
                                                                            key ==
                                                                            'B2'
                                                                        ) {
                                                                            Left =
                                                                                component.left -
                                                                                70
                                                                        } else {
                                                                            Left =
                                                                                component.left -
                                                                                25
                                                                        }
                                                                        Top =
                                                                            component.top
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    }

                                                    //checking extraCoponent type = stepper_motor
                                                }
                                            }
                                        })
                                    }
                                } else if (
                                    (key == 'A1' || key == 'A2') &&
                                    highlighted &&
                                    !['Toki'].includes(connectedDevice)
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                extraComponent.type !=
                                                                    'geared_motor' &&
                                                                extraComponent.type !=
                                                                    'mini_geared_motor' &&
                                                                extraComponent.type !=
                                                                    'dc_motor' &&
                                                                extraComponent.type !=
                                                                    'dynamex_motor'
                                                            ) {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'A'
                                                                ) {
                                                                    Left =
                                                                        component.left
                                                                    Top =
                                                                        component.top
                                                                    highlighted = true
                                                                }
                                                                if (
                                                                    component.connectedTo ==
                                                                        'A' &&
                                                                    (this.props
                                                                        .shield ||
                                                                        this
                                                                            .props
                                                                            .armShield)
                                                                ) {
                                                                    if (
                                                                        key ==
                                                                        'A1'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            87
                                                                        Top =
                                                                            component.top -
                                                                            252
                                                                        highlighted = true
                                                                    }
                                                                    if (
                                                                        key ==
                                                                        'A2'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            32
                                                                        Top =
                                                                            component.top -
                                                                            285
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            } else {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'A'
                                                                ) {
                                                                    Left =
                                                                        component.left +
                                                                        28
                                                                    Top =
                                                                        component.top +
                                                                        5
                                                                    highlighted = true
                                                                }
                                                                if (
                                                                    component.connectedTo ==
                                                                        'A' &&
                                                                    (this.props
                                                                        .shield ||
                                                                        this
                                                                            .props
                                                                            .armShield)
                                                                ) {
                                                                    Left =
                                                                        component.left -
                                                                        60
                                                                    Top =
                                                                        component.top -
                                                                        248
                                                                    highlighted = true
                                                                }
                                                            }
                                                        }
                                                    )
                                                }
                                                if (
                                                    dual_splitter ==
                                                    'play_shield'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                extraComponent.type !=
                                                                    'geared_motor' &&
                                                                extraComponent.type !=
                                                                    'mini_geared_motor' &&
                                                                extraComponent.type !=
                                                                    'dc_motor' &&
                                                                extraComponent.type !=
                                                                    'dynamex_motor' &&
                                                                extraComponent.type !=
                                                                    'servo_motor' &&
                                                                extraComponent.type !=
                                                                    'servo_motor_270' &&
                                                                extraComponent.type !=
                                                                    'servo_motor_360'
                                                            ) {
                                                                if (
                                                                    PortConnections.A ==
                                                                    null
                                                                ) {
                                                                    if (
                                                                        key ==
                                                                        'A1'
                                                                    ) {
                                                                        Left =
                                                                            bibox.left
                                                                        Top =
                                                                            bibox.top
                                                                        highlighted = true
                                                                    }
                                                                    if (
                                                                        key ==
                                                                        'A2'
                                                                    ) {
                                                                        Left =
                                                                            bibox.left
                                                                        Top =
                                                                            bibox.top
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    )
                                                }

                                                /** */
                                                // MOTOR DRIVER
                                                if (
                                                    dual_splitter ==
                                                        'pc_motor_driver' &&
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'assembly'
                                                        )
                                                    ).PortConnections.A !== null
                                                ) {
                                                    if (
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'assembly'
                                                            )
                                                        ).PortConnections.A
                                                            .type ==
                                                        'pc_motor_driver'
                                                    ) {
                                                        //***checking extraCoponent type = geared_motor
                                                        if (
                                                            [
                                                                'geared_motor',
                                                                'mini_geared_motor',
                                                                'dc_motor',
                                                                'dynamex_motor',
                                                            ].includes(
                                                                extraComponent.type
                                                            )
                                                        ) {
                                                            // 1 all ports == null  i.e B1,B2,D1,D2,SMTP == null

                                                            if (
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .STPM ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .A1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .A2 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .C1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .C2 === null
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'A' ||
                                                                            component.connectedTo ==
                                                                                'C'
                                                                        ) {
                                                                            Left =
                                                                                component.left -
                                                                                70
                                                                            Top =
                                                                                component.top +
                                                                                30
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }

                                                            // checking connect divice is not == to servo_motot n stepper_motor
                                                            let connectName =
                                                                isMotorConnected_AC()
                                                            if (
                                                                connectName !==
                                                                    'servo_motor' &&
                                                                connectName !==
                                                                    'stepper_motor'
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'A' ||
                                                                            component.connectedTo ==
                                                                                'C'
                                                                        ) {
                                                                            Left =
                                                                                component.left -
                                                                                70
                                                                            Top =
                                                                                component.top +
                                                                                30
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        }

                                                        //****checking extraCoponent type = servo_motor

                                                        if (
                                                            extraComponent.type ==
                                                            'servo_motor'
                                                        ) {
                                                            if (
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .A1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .C1 ===
                                                                    null &&
                                                                JSON.parse(
                                                                    sessionStorage.getItem(
                                                                        'assembly'
                                                                    )
                                                                )
                                                                    .PortConnections
                                                                    .STPM ===
                                                                    null
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'A' ||
                                                                            component.connectedTo ==
                                                                                'C'
                                                                        ) {
                                                                            if (
                                                                                key ==
                                                                                'A2'
                                                                            ) {
                                                                                Left =
                                                                                    component.left -
                                                                                    70
                                                                            } else {
                                                                                Left =
                                                                                    component.left -
                                                                                    25
                                                                            }
                                                                            Top =
                                                                                component.top
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }

                                                            // checking connect divice is not == to servo_motot n stepper_motor
                                                            let connectName =
                                                                isMotorConnected_AC()
                                                            if (
                                                                connectName !==
                                                                    'geared_motor' &&
                                                                connectName !==
                                                                    'mini_geared_motor' &&
                                                                connectName !==
                                                                    'dc_motor' &&
                                                                connectName !==
                                                                    'dynamex_motor' &&
                                                                connectName !==
                                                                    'stepper_motor'
                                                            ) {
                                                                components[
                                                                    dual_splitter
                                                                ].map(
                                                                    (
                                                                        component,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            component.connectedTo ==
                                                                                'A' ||
                                                                            component.connectedTo ==
                                                                                'C'
                                                                        ) {
                                                                            if (
                                                                                key ==
                                                                                'A2'
                                                                            ) {
                                                                                Left =
                                                                                    component.left -
                                                                                    70
                                                                            } else {
                                                                                Left =
                                                                                    component.left -
                                                                                    25
                                                                            }
                                                                            Top =
                                                                                component.top
                                                                            highlighted = true
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        }

                                                        //checking extraCoponent type = stepper_motor
                                                    }
                                                }

                                                /** */
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'E1' || key == 'E2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                extraComponent.type !=
                                                                    'geared_motor' &&
                                                                extraComponent.type !=
                                                                    'mini_geared_motor' &&
                                                                extraComponent.type !=
                                                                    'dc_motor' &&
                                                                extraComponent.type !=
                                                                    'dynamex_motor'
                                                            ) {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'E'
                                                                ) {
                                                                    if (
                                                                        key ==
                                                                        'E1'
                                                                    ) {
                                                                        Left =
                                                                            component.left +
                                                                            5
                                                                        Top =
                                                                            component.top -
                                                                            74
                                                                        highlighted = true
                                                                    }
                                                                    if (
                                                                        key ==
                                                                        'E2'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            5
                                                                        Top =
                                                                            component.top -
                                                                            68
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            } else {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'E'
                                                                ) {
                                                                    console.log(
                                                                        'highlited true'
                                                                    )
                                                                    Left =
                                                                        component.left +
                                                                        30
                                                                    Top =
                                                                        component.top -
                                                                        70
                                                                    highlighted = true
                                                                }
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'F1' || key == 'F2') &&
                                    highlighted &&
                                    !['Klaw'].includes(
                                        sessionStorage.getItem(
                                            'connectedDevice'
                                        )
                                    )
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                extraComponent.type !=
                                                                    'geared_motor' &&
                                                                extraComponent.type !=
                                                                    'mini_geared_motor' &&
                                                                extraComponent.type !=
                                                                    'dc_motor' &&
                                                                extraComponent.type !=
                                                                    'dynamex_motor'
                                                            ) {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'F'
                                                                ) {
                                                                    if (
                                                                        key ==
                                                                        'F1'
                                                                    ) {
                                                                        Left =
                                                                            component.left +
                                                                            5
                                                                        Top =
                                                                            component.top -
                                                                            74
                                                                        highlighted = true
                                                                    }
                                                                    if (
                                                                        key ==
                                                                        'F2'
                                                                    ) {
                                                                        Left =
                                                                            component.left -
                                                                            5
                                                                        Top =
                                                                            component.top -
                                                                            68
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            } else {
                                                                if (
                                                                    component.connectedTo ==
                                                                    'F'
                                                                ) {
                                                                    console.log(
                                                                        'highlited true'
                                                                    )
                                                                    Left =
                                                                        component.left +
                                                                        30
                                                                    Top =
                                                                        component.top -
                                                                        70
                                                                    highlighted = true
                                                                }
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    !['Roboki', 'Klaw', 'Toki'].includes(
                                        sessionStorage.getItem(
                                            'connectedDevice'
                                        )
                                    ) &&
                                    key == 'M1' &&
                                    highlighted
                                ) {
                                    if (components) {
                                        console.log('v1', components)
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                        'play_shield' ||
                                                    dual_splitter ==
                                                        'play_shield_v1'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                [
                                                                    'dc_motor',
                                                                    'geared_motor',
                                                                    'mini_geared_motor',
                                                                    'dynamex_motor',
                                                                ].includes(
                                                                    extraComponent.type
                                                                )
                                                                // extraComponent.type ==
                                                                //     'geared_motor' ||
                                                                // extraComponent.type ==
                                                                //     'mini_geared_motor' ||
                                                                // extraComponent.type ==
                                                                //     'dc_motor'
                                                            ) {
                                                                Left =
                                                                    bibox.left
                                                                Top = bibox.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                } else if (
                                                    dual_splitter ==
                                                    'peeCee_ATR'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            Left = bibox.left
                                                            Top = bibox.top
                                                            highlighted = true
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    !['Roboki', 'Klaw', 'Toki'].includes(
                                        sessionStorage.getItem(
                                            'connectedDevice'
                                        )
                                    ) &&
                                    key == 'M3' &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                        'play_shield' ||
                                                    dual_splitter ==
                                                        'play_shield_v1' ||
                                                    dual_splitter ==
                                                        'peeCee_ATR'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                [
                                                                    'dc_motor',
                                                                    'geared_motor',
                                                                    'mini_geared_motor',
                                                                    'dynamex_motor',
                                                                ].includes(
                                                                    extraComponent.type
                                                                )
                                                                // extraComponent.type ==
                                                                //     'geared_motor' ||
                                                                // extraComponent.type ==
                                                                //     'mini_geared_motor' ||
                                                                // extraComponent.type ==
                                                                //     'dc_motor'
                                                            ) {
                                                                Left =
                                                                    bibox.left
                                                                Top = bibox.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'M2' || key == 'M4') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (sensor) => {
                                                if (sensor == 'peeCee_ATR') {
                                                    components[sensor].map(
                                                        (component, index) => {
                                                            Left = bibox.left
                                                            Top = bibox.top
                                                            highlighted = true
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (key == 'MOTOR1' && highlighted) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (sensor) => {
                                                if (sensor == 'arm_shield') {
                                                    components[sensor].map(
                                                        (component, index) => {
                                                            if (
                                                                extraComponent.type ==
                                                                    'geared_motor' ||
                                                                extraComponent.type ==
                                                                    'mini_geared_motor' ||
                                                                extraComponent.type ==
                                                                    'dc_motor' ||
                                                                extraComponent.type ==
                                                                    'dynamex_motor'
                                                            ) {
                                                                Left =
                                                                    bibox.left
                                                                Top = bibox.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'P1' ||
                                        key == 'P2' ||
                                        key == 'P3' ||
                                        key == 'SS' ||
                                        key == 'SPK') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (sensor) => {
                                                if (sensor == 'arm_shield') {
                                                    components[sensor].map(
                                                        (component, index) => {
                                                            Left = bibox.left
                                                            Top = bibox.top
                                                            highlighted = true
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (key == 'STPM' && highlighted) {
                                    if (components) {
                                        highlighted = false

                                        Object.keys(components).map(
                                            (dataVal) => {
                                                if (
                                                    dataVal == 'pc_motor_driver'
                                                ) {
                                                    // isPcMotorDriver = true;

                                                    let pc_motor_driverConnectedTo =
                                                        isPc_motor_driverConnected_BD_AC()

                                                    if (
                                                        pc_motor_driverConnectedTo ==
                                                            'B' ||
                                                        pc_motor_driverConnectedTo ==
                                                            'D'
                                                    ) {
                                                        if (
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .B1 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .B2 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .D1 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .D2 === null
                                                        ) {
                                                            components[
                                                                dataVal
                                                            ].map(
                                                                (
                                                                    component,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        component.connectedTo ==
                                                                            'B' ||
                                                                        component.connectedTo ==
                                                                            'D'
                                                                    ) {
                                                                        Left =
                                                                            component.left
                                                                        Top =
                                                                            component.top
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    }

                                                    // if (
                                                    //   JSON.parse(sessionStorage.getItem("assembly"))
                                                    //     .PortConnections.B1 === null &&
                                                    //   JSON.parse(sessionStorage.getItem("assembly"))
                                                    //     .PortConnections.B2 === null &&
                                                    //   JSON.parse(sessionStorage.getItem("assembly"))
                                                    //     .PortConnections.D1 === null &&
                                                    //   JSON.parse(sessionStorage.getItem("assembly"))
                                                    //     .PortConnections.D2 === null
                                                    // ) {
                                                    //   components[dataVal].map((component, index) => {
                                                    //     if (
                                                    //       component.connectedTo == "B" ||
                                                    //       component.connectedTo == "D" ||
                                                    //       component.connectedTo == "A" ||
                                                    //       component.connectedTo == "C"
                                                    //     ) {
                                                    //       Left = component.left;
                                                    //       Top = component.top;
                                                    //       highlighted = true;
                                                    //     }
                                                    //   });
                                                    // }

                                                    if (
                                                        pc_motor_driverConnectedTo ==
                                                            'C' ||
                                                        pc_motor_driverConnectedTo ==
                                                            'A'
                                                    ) {
                                                        if (
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .A1 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .A2 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .C1 === null &&
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'assembly'
                                                                )
                                                            ).PortConnections
                                                                .C2 === null
                                                        ) {
                                                            components[
                                                                dataVal
                                                            ].map(
                                                                (
                                                                    component,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        component.connectedTo ==
                                                                            'A' ||
                                                                        component.connectedTo ==
                                                                            'C'
                                                                    ) {
                                                                        Left =
                                                                            component.left
                                                                        Top =
                                                                            component.top
                                                                        highlighted = true
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    }
                                                }
                                            }
                                        )
                                    }
                                } else {
                                    // ABCD
                                    Top = bibox.top
                                    Left = bibox.left
                                }
                            } else {
                                if (
                                    (key == 'F1' || key == 'F2') &&
                                    highlighted
                                ) {
                                    console.log(
                                        components,
                                        'SSSSSSSSS',
                                        highlighted
                                    )
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map((key) => {
                                            if (key == 'dual_splitter') {
                                                components[key].map(
                                                    (component, index) => {
                                                        if (
                                                            component.connectedTo ==
                                                            'F'
                                                        ) {
                                                            Left =
                                                                component.left
                                                            Top = component.top
                                                            highlighted = true
                                                        }
                                                    }
                                                )
                                            } else if (
                                                key == 'servo_extender'
                                            ) {
                                                components[key].map(
                                                    (component, index) => {
                                                        if (
                                                            component.connectedTo ==
                                                            'F'
                                                        ) {
                                                            Left =
                                                                component.left +
                                                                30
                                                            Top =
                                                                component.top +
                                                                5
                                                            highlighted = true
                                                        }
                                                    }
                                                )
                                            }
                                        })
                                    }
                                    console.log(
                                        components,
                                        'SSSSSSSSS3333',
                                        highlighted
                                    )
                                } else if (
                                    (key == 'G1' || key == 'G2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                component.connectedTo ==
                                                                'G'
                                                            ) {
                                                                Left =
                                                                    component.left
                                                                Top =
                                                                    component.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'I1' || key == 'I2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                component.connectedTo ==
                                                                'I'
                                                            ) {
                                                                Left =
                                                                    component.left
                                                                Top =
                                                                    component.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'H1' || key == 'H2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                component.connectedTo ==
                                                                'H'
                                                            ) {
                                                                Left =
                                                                    component.left
                                                                Top =
                                                                    component.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'E1' || key == 'E2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                component.connectedTo ==
                                                                'E'
                                                            ) {
                                                                Left =
                                                                    component.left
                                                                Top =
                                                                    component.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'D1' || key == 'D2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                component.connectedTo ==
                                                                'D'
                                                            ) {
                                                                Left =
                                                                    component.left
                                                                Top =
                                                                    component.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'C1' || key == 'C2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                component.connectedTo ==
                                                                'C'
                                                            ) {
                                                                Left =
                                                                    component.left
                                                                Top =
                                                                    component.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else if (
                                    (key == 'B1' || key == 'B2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map((value) => {
                                            if (value == 'dual_splitter') {
                                                components[value].map(
                                                    (component, index) => {
                                                        if (
                                                            component.connectedTo ==
                                                            'B'
                                                        ) {
                                                            Left =
                                                                component.left
                                                            Top = component.top
                                                            highlighted = true
                                                        }
                                                    }
                                                )
                                            }
                                            if (value == 'servo_extender') {
                                                components[value].map(
                                                    (component, index) => {
                                                        if (
                                                            component.connectedTo ==
                                                            'B'
                                                        ) {
                                                            Left =
                                                                component.left +
                                                                30
                                                            Top =
                                                                component.top +
                                                                5
                                                            highlighted = true
                                                        }
                                                    }
                                                )
                                            }
                                        })
                                    }
                                } else if (
                                    (key == 'A1' || key == 'A2') &&
                                    highlighted
                                ) {
                                    if (components) {
                                        highlighted = false
                                        Object.keys(components).map(
                                            (dual_splitter) => {
                                                if (
                                                    dual_splitter ==
                                                    'dual_splitter'
                                                ) {
                                                    components[
                                                        dual_splitter
                                                    ].map(
                                                        (component, index) => {
                                                            if (
                                                                component.connectedTo ==
                                                                'A'
                                                            ) {
                                                                Left =
                                                                    component.left
                                                                Top =
                                                                    component.top
                                                                highlighted = true
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                } else {
                                    Top = bibox.top
                                    Left = bibox.left
                                }
                            }

                            /* here we are checking that if led,dual splitter is connected to "A" then DRIVER_MOTOR will not connect to A, C ports*/
                            if (
                                sessionStorage.getItem('dragingItem') ==
                                'dual_spliier'
                            ) {
                                let PortConnections = JSON.parse(
                                    sessionStorage.getItem('assembly')
                                ).PortConnections

                                if (key == 'A') {
                                    if (
                                        JSON.parse(
                                            sessionStorage.getItem('assembly')
                                        ).PortConnections.A === null &&
                                        JSON.parse(
                                            sessionStorage.getItem('assembly')
                                        ).PortConnections.C === null
                                    ) {
                                        return (
                                            <g
                                                key={key}
                                                stroke="white"
                                                strokeWidth="2"
                                                fill={
                                                    PortTypes[
                                                        `${connectedDevice.toUpperCase()}`
                                                    ][key].color
                                                }
                                            >
                                                {PortTypes[
                                                    `${connectedDevice.toUpperCase()}`
                                                ][key].ports.map(
                                                    (port) => (
                                                        <PortCircle
                                                            style={{
                                                                visibility:
                                                                    'hidden',
                                                            }}
                                                            left={
                                                                Left +
                                                                Ports(port)[0]
                                                            }
                                                            top={
                                                                Top +
                                                                Ports(port)[1]
                                                            }
                                                            key={port}
                                                            highlighted={
                                                                !PortConnections[
                                                                    port
                                                                ] && highlighted
                                                            }
                                                            PortConnections={
                                                                PortConnections
                                                            }
                                                        />
                                                    )

                                                    //It highlight only those whih are
                                                )}
                                            </g>
                                        )
                                    }
                                }
                                if (key == 'B') {
                                    if (
                                        JSON.parse(
                                            sessionStorage.getItem('assembly')
                                        ).PortConnections.B === null &&
                                        JSON.parse(
                                            sessionStorage.getItem('assembly')
                                        ).PortConnections.D === null
                                    ) {
                                        return (
                                            <g
                                                key={key}
                                                stroke="white"
                                                strokeWidth="2"
                                                fill={
                                                    PortTypes[
                                                        `${connectedDevice.toUpperCase()}`
                                                    ][key].color
                                                }
                                            >
                                                {PortTypes[
                                                    `${connectedDevice.toUpperCase()}`
                                                ][key].ports.map(
                                                    (port) => (
                                                        // Ports.js helping the dual_spliter to assign dots on dual_spliter ex - D1,D2
                                                        // PPP

                                                        <PortCircle
                                                            style={{
                                                                visibility:
                                                                    'hidden',
                                                            }}
                                                            left={
                                                                Left +
                                                                Ports(port)[0]
                                                            }
                                                            top={
                                                                Top +
                                                                Ports(port)[1]
                                                            }
                                                            key={port}
                                                            highlighted={
                                                                !PortConnections[
                                                                    port
                                                                ] && highlighted
                                                            }
                                                            PortConnections={
                                                                PortConnections
                                                            }
                                                        />
                                                    )

                                                    //It highlight only those whih are
                                                )}
                                            </g>
                                        )
                                    }
                                }
                                if (key == 'C') {
                                    if (
                                        JSON.parse(
                                            sessionStorage.getItem('assembly')
                                        ).PortConnections.A === null &&
                                        JSON.parse(
                                            sessionStorage.getItem('assembly')
                                        ).PortConnections.C === null
                                    ) {
                                        return (
                                            <g
                                                key={key}
                                                stroke="white"
                                                strokeWidth="2"
                                                fill={
                                                    PortTypes[
                                                        `${connectedDevice.toUpperCase()}`
                                                    ][key].color
                                                }
                                            >
                                                {PortTypes[
                                                    `${connectedDevice.toUpperCase()}`
                                                ][key].ports.map(
                                                    (port) => (
                                                        // Ports.js helping the dual_spliter to assign dots on dual_spliter ex - D1,D2
                                                        // PPP

                                                        <PortCircle
                                                            style={{
                                                                visibility:
                                                                    'hidden',
                                                            }}
                                                            left={
                                                                Left +
                                                                Ports(port)[0]
                                                            }
                                                            top={
                                                                Top +
                                                                Ports(port)[1]
                                                            }
                                                            key={port}
                                                            highlighted={
                                                                !PortConnections[
                                                                    port
                                                                ] && highlighted
                                                            }
                                                            PortConnections={
                                                                PortConnections
                                                            }
                                                        />
                                                    )

                                                    //It highlight only those whih are
                                                )}
                                            </g>
                                        )
                                    }
                                }
                                if (key == 'D') {
                                    if (
                                        JSON.parse(
                                            sessionStorage.getItem('assembly')
                                        ).PortConnections.B === null &&
                                        JSON.parse(
                                            sessionStorage.getItem('assembly')
                                        ).PortConnections.D === null
                                    ) {
                                        return (
                                            <g
                                                key={key}
                                                stroke="white"
                                                strokeWidth="2"
                                                fill={
                                                    PortTypes[
                                                        `${connectedDevice.toUpperCase()}`
                                                    ][key].color
                                                }
                                            >
                                                {PortTypes[
                                                    `${connectedDevice.toUpperCase()}`
                                                ][key].ports.map(
                                                    (port) => (
                                                        // Ports.js helping the dual_spliter to assign dots on dual_spliter ex - D1,D2
                                                        // PPP

                                                        <PortCircle
                                                            style={{
                                                                visibility:
                                                                    'hidden',
                                                            }}
                                                            left={
                                                                Left +
                                                                Ports(port)[0]
                                                            }
                                                            top={
                                                                Top +
                                                                Ports(port)[1]
                                                            }
                                                            key={port}
                                                            highlighted={
                                                                !PortConnections[
                                                                    port
                                                                ] && highlighted
                                                            }
                                                            PortConnections={
                                                                PortConnections
                                                            }
                                                        />
                                                    )

                                                    //It highlight only those whih are
                                                )}
                                            </g>
                                        )
                                    }
                                }
                            } else {
                                // console.log("key", key);
                                //////// HOTFIX for hiding PortCircle motor getting connected when port is already occupied ////
                                const draggingItem =
                                    sessionStorage.getItem('dragingItem')
                                const assemblyConnections = JSON.parse(
                                    sessionStorage.getItem('assembly')
                                ).PortConnections

                                if (
                                    [
                                        'mini_geared_motor',
                                        'geared_motor',
                                        'dc_motor',
                                        'dynamex_motor',
                                    ].includes(draggingItem)
                                ) {
                                    if (
                                        key == 'A1' &&
                                        assemblyConnections.A2 != null
                                    )
                                        return
                                    if (
                                        key == 'B1' &&
                                        assemblyConnections.B2 != null
                                    )
                                        return
                                    if (
                                        key == 'C1' &&
                                        assemblyConnections.C2 != null &&
                                        !['Toki'].includes(connectedDevice)
                                    )
                                        return
                                    if (
                                        key == 'D1' &&
                                        assemblyConnections.D2 != null
                                    )
                                        return
                                    if (
                                        key == 'E1' &&
                                        assemblyConnections.E2 != null
                                    )
                                        return

                                    if (
                                        connectedDevice !== 'Klaw' &&
                                        key == 'F1' &&
                                        assemblyConnections.F2 != null
                                    )
                                        return
                                }
                                if (
                                    connectedDevice === 'Klaw' &&
                                    draggingItem === 'serial_servo_motor'
                                ) {
                                    const assemblyConnections = JSON.parse(
                                        sessionStorage.getItem('assembly')
                                    ).PortConnections
                                    if (
                                        assemblyConnections.serial_servo2 !==
                                            null ||
                                        assemblyConnections.serial_servo2 !==
                                            null
                                    )
                                        return false
                                }

                                ///////////////////////////////////////////////////////////////////////////////////////////////

                                /////////////////////// PLAYC-39 ////////////////////////
                                ///// Disabling certain ports when certain
                                ///// internal accessories are selected
                                if (
                                    !sessionStorage
                                        .getItem('deviceVersion')
                                        ?.startsWith('1')
                                ) {
                                    const internalAccessories = JSON.parse(
                                        sessionStorage.getItem('concept')
                                    ).internalaccessories

                                    const portConnections = JSON.parse(
                                        sessionStorage.getItem('assembly')
                                    ).PortConnections

                                    const is4in1SensorConnected =
                                        internalAccessories.isFour_in_one_sensor ||
                                        false

                                    if (
                                        is4in1SensorConnected &&
                                        (key == 'D1' || key == 'D2')
                                    )
                                        return

                                    const isSmile1 =
                                        internalAccessories.isSmileOne || false
                                    const isSmile2 =
                                        internalAccessories.isSmileTwo || false
                                    const isSmile3 =
                                        internalAccessories.isSmileThree ||
                                        false
                                    const isSmile4 =
                                        internalAccessories.isSmileFour || false
                                    if (
                                        (this.props.shield ||
                                            this.props.peeCeeATR) &&
                                        key === 'M1' &&
                                        (isSmile1 || isSmile2)
                                    )
                                        return false
                                    if (
                                        (this.props.shield ||
                                            this.props.peeCeeATR) &&
                                        key === 'M3' &&
                                        (isSmile3 || isSmile4)
                                    )
                                        return false

                                    if (
                                        this.props.armShield &&
                                        key === 'MOTOR1' &&
                                        (isSmile2 || isSmile3)
                                    )
                                        return false
                                    if (this.props.armShield && key === 'F')
                                        return false

                                    if (key === 'SS' && (isSmile1 || isSmile4))
                                        return false

                                    const isTouch0 =
                                        internalAccessories.isTouchZero ||
                                        internalAccessories.isTouchZeroOutput ||
                                        false
                                    const isTouch1 =
                                        internalAccessories.isTouchOne ||
                                        internalAccessories.isTouchOneOutput ||
                                        false
                                    const isTouch2 =
                                        internalAccessories.isTouchTwo ||
                                        internalAccessories.isTouchTwoOutput ||
                                        false

                                    const isA1 = portConnections['A1']
                                        ? true
                                        : false
                                    const isA2 = portConnections['A2']
                                        ? true
                                        : false
                                    const isB1 = portConnections['B1']
                                        ? true
                                        : false
                                    const isC1 = portConnections['C1']
                                        ? true
                                        : false
                                    const isC2 = portConnections['C2']
                                        ? true
                                        : false

                                    const isP0 = portConnections['P0']
                                        ? true
                                        : false
                                    const isP1 = portConnections['P1']
                                        ? true
                                        : false
                                    const isP2 = portConnections['P2']
                                        ? true
                                        : false
                                    const isF1 = portConnections['F1']
                                        ? true
                                        : false
                                    const isF2 = portConnections['F2']
                                        ? true
                                        : false

                                    const isS1 = portConnections['S1']
                                        ? true
                                        : false
                                    const isS2 = portConnections['S2']
                                        ? true
                                        : false

                                    if (this.props.peeCeeATR) {
                                        if (
                                            key === 'M1' &&
                                            (isSmile1 || isSmile2)
                                        )
                                            return false
                                        else if (
                                            key === 'M2' &&
                                            (isSmile3 || isSmile4)
                                        )
                                            return false
                                        // else if (
                                        //     (key == 'S1' || key == 'A1') &&
                                        //     (isA1 || isS1)
                                        // )
                                        //     return false
                                        // else if (
                                        //     (key == 'S2' || key == 'C1') &&
                                        //     (isC1 || isS2)
                                        // )
                                        //     return false
                                    }

                                    if (
                                        (key === 'A1' || key === 'P0') &&
                                        (isTouch0 || isA1 || isP0)
                                    )
                                        return false
                                    if (
                                        (key === 'B1' || key === 'P1') &&
                                        (isTouch1 || isB1 || isP1)
                                    )
                                        return false
                                    if (
                                        (key === 'C1' || key === 'P2') &&
                                        (isTouch2 || isC1 || isP2)
                                    )
                                        return false

                                    if (draggingItem !== 'dual_splitter') {
                                        if (key === 'A' && isTouch0)
                                            return false
                                        if (key === 'B' && isTouch1)
                                            return false
                                        if (key === 'C' && isTouch2)
                                            return false
                                    }
                                }

                                return (
                                    <g
                                        key={key}
                                        stroke="white"
                                        strokeWidth="2"
                                        fill={
                                            PortTypes[
                                                `${connectedDevice.toUpperCase()}`
                                            ][key].color
                                        }
                                    >
                                        {PortTypes[
                                            `${connectedDevice.toUpperCase()}`
                                        ][key].ports.map(
                                            (port) => (
                                                <>
                                                    <PortCircle
                                                        style={{
                                                            visibility:
                                                                'hidden',
                                                        }}
                                                        left={
                                                            Left +
                                                            Ports(port)[0]
                                                        }
                                                        top={
                                                            getTopValueRedCircle(
                                                                port,
                                                                Top,
                                                                Ports,
                                                                draggingItem
                                                            )
                                                            // Top +
                                                            // Ports(port)[1]
                                                        }
                                                        key={port}
                                                        highlighted={
                                                            PortConnections[
                                                                port
                                                            ] == null
                                                                ? !PortConnections[
                                                                      port
                                                                  ] &&
                                                                  highlighted
                                                                : sessionStorage.getItem(
                                                                      'dragingItem'
                                                                  ) !=
                                                                  'pc_motor_driver'
                                                                ? PortConnections[
                                                                      port
                                                                  ]
                                                                      .notDriverMotot ==
                                                                  'notConnectedDriverMotor'
                                                                    ? true &&
                                                                      highlighted
                                                                    : !PortConnections[
                                                                          port
                                                                      ] &&
                                                                      highlighted
                                                                : !PortConnections[
                                                                      port
                                                                  ] &&
                                                                  highlighted
                                                        }
                                                        PortConnections={
                                                            PortConnections
                                                        }
                                                        portName={key}
                                                    />
                                                </>
                                            )

                                            //It highlight only those whih are
                                        )}
                                    </g>
                                )
                            }
                        })}
                    </g>
                </svg>
            </div>
        )
    }
}

export default Connections
